
<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">Events</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Our Events</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="/assets/img/blog/header.png" alt="" class="img-fluid">
    </div>
</section>

<section *ngIf="events.length !=0" id="blog" class="blog-layout-six pa-100">
    <div class="container">
        <div class="row mb-30">
            <div class="col-md-4" *ngFor="let event of events">
               <div class="blog-item">
                  <img src="{{URL}}/{{event.image}}" alt="" class="img-fulid">
                  <div class="content">
                      <h4 class="pb-0 mb-0"><a [routerLink]="['/events', event._id]"><i class="fas fa-map-marker-alt mr-2 text-secondary"></i> {{event.place}}</a></h4>
                      <p class="small ml-2"><i class="fas fa-map-marker-alt mr-2 text-white"></i> {{event.dateFormate}}</p>
                      <a [routerLink]="['/events', event._id]" class="button-blog">Read More <i class="fas fa-check"></i></a>
                  </div>
              </div>
            </div>
        </div>
    </div>
    <div class="text-center">
        <a (click)="loadMore()" class="button button-primary button-icon text-white">
            Load More <i class="fas fa-sync"></i>
        </a>
    </div>
</section>

<section *ngIf="!loading && events.length == 0" id="blog" class="blog-layout-six pa-100">
    <div class="container">
        <div class="row mb-30 ">
           <div class="col-md-12 text-center">
                <img src="/assets/img/empty-blog.jpeg" width="450px" alt="">
                <br><br>
                <h4 class="text-secondary">There are no any Event available yet now!</h4>
           </div>
        </div>
    </div>
</section>
