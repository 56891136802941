<div class="team-item" *ngIf="agent">
    <div class="img text-center">
        <img class="agent-profile" [src]="agent.profileImage()" alt="{{agent.fullName()}}">
    </div>
    <div class="designation text-center">
        <h4><a (click)="onActiveAgent()" routerLinkActive="router-link-active" tabindex="0">{{agent.fullName()}}</a>
        </h4>
        <!-- <p class="mb-0">House Seller</p> -->

    </div>
    <div *ngIf="agent.bio && agent.bio != ''">
        <hr>
        <p class="limited-text">
            {{agent.bio}}
        </p>
        <hr>
    </div>
    <ul class="info">
        <li><i class="fas fa-envelope primary-color"></i> {{agent.email}}
        </li>
        <li><i class="fas fa-phone-alt primary-color"></i> {{agent.phoneNumber}}</li>
        <!-- <li><i class="fas fa-globe-americas primary-color"></i> www.realested.com</li> -->
    </ul>


</div>