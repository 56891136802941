import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Agent } from './agent.model';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css']
})
export class AgentsComponent implements OnInit {



  agents : Array<Agent> = []

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle("Agents")
    this.loadAgents();
  }


  loadAgents(isScroll = false){

    const data = {
      skip : this.agents.length
    }
    this.dataService.__post('/agents',data)
    .subscribe(
      (agents : Array<any>) => {
        
        agents.map(
          (agent : Agent) => {
            this.agents.push(new Agent(agent));
          }
        )

      },

      (error) => {
        this.dataService.showError('Error',error);
      }
    )

  }

}
