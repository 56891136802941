import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private socket: Socket) {

    this.socket.on('connect',(x : any) =>{
      console.log('Connected');
    })


    this.socket.on('error',(x : any) =>{
      console.log('Connected');
    });

    
   }

    socketEmit(eventName: string,data : any){
      this.socket.emit(eventName,data);
    }

    getSocketEvent(eventName: string) {
    return this.socket
      .fromEvent(eventName)
      .pipe(map((data) => data));
  }
  
}
