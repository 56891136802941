<app-header-fix></app-header-fix>


<section id="hero" class="innar-hero pt-86">
    <div class="container" style="padding-bottom: 40px;padding-top: 75px;">
        <div class="row mt-5">
            <div class="col-md-6">
                <h3 class="mb-0">Join Arbrook Realty</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Join Arbrook Realty</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="assets/img/service/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>

<section id="why-join-se" class="about-innar">
    <div class="container">
        <div class="row">
            <div class="col-md-6 align-self-center">
                <div class="about-left">
                    <img src="/assets/img/whyJooin.jpeg" alt="Arbrook Realty" class="img-fluid view-mobile">
                    <div class="video-play">
                        <a data-fancybox="Hero-video" href="https://www.youtube.com/watch?v=WNyfeeR6qW0" class="play-button"><i
                                class="fas fa-play"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <h2 class="pb-5 pa-100 mb-0">Why Join?</h2>
                <p >Welcome to Arbrook Realty! Look around at our innovative agent benefits to help you on
                    your journey. Look into why our sponsorship plan is incomparable.</p>
                <a [routerLink]="['/join-now','personal-info']" class="button button-primary mb-3">Join Now</a>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container ">
        <div class="pa-50">
            <h3 class="text-theme my-3">
                {{'JOIN.HEAD' | translate}}
            </h3>
            <p >{{'JOIN.INTRODUCTION_0' | translate}} <b>{{'JOIN.INTRODUCTION_1' | translate}}</b> {{'JOIN.INTRODUCTION_2' | translate}}</p>
        </div>
        <div class="row">

            <div class="col-md-4 mb-5">
                <img src="assets/img/join-arbrook-realty/Commissionb.jpg" class="img-sqr" alt="">
                <h5 class="my-4">{{'JOIN.COMMISSION' | translate}}</h5>
                <p >{{'JOIN.COMMISSION_TEXT' | translate}}</p>
            </div>

            <div class="col-md-4 mb-5">
                <img src="assets/img/join-arbrook-realty/Culture.jpg" class="img-sqr" alt="">
                <h5 class="my-4">{{'JOIN.CULTURE' | translate}}</h5>
                <p >{{'JOIN.CULTURE_TEXT' | translate}}</p>
            </div>

            <div class="col-md-4 mb-5">
                <img src="assets/img/join-arbrook-realty/Coaching.jpg" class="img-sqr" alt="">
                <h5 class="my-4">{{'JOIN.COACHING' | translate}}</h5>
                <p >{{'JOIN.COACHING_TEXT' | translate}}</p>
            </div>

        </div>

        <p class="my-2">{{'JOIN.TEXT_1' | translate}}</p>

        <p class="my-4">{{'JOIN.TEXT_2' | translate}}</p>
        <div class="text-center my-5">
            <a [routerLink]="['/join-now','personal-info']" class="button button-primary button-white">Join Now</a>
        </div>
        <div class="row pa-50">
            <div class="col-md-4 offset-md-1">
                <img src="assets/img/join-arbrook-realty/HowtoApplyforaRealEstateLicenseinTexas.png" height="210px" width="95%" alt="">
            </div> 
            <div class="col-md-6">
                <h3 class="text-theme">
                    {{'JOIN.HOW_TO_APPLY_HEAD' | translate}}
                </h3>
                <p >{{'JOIN.HOW_TO_APPLY_TEXT' | translate}}</p>
                <a [routerLink]="['/how-to-apply']" class="button button-primary button-white my-3">Learn More</a>
            </div>
        </div>
    </div>
</section>