<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">Community Details</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Community Details</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="/assets/img/about/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>

<section id="feature-two" class="feature-layout-two pa-100">
    <div class="container">
        <div class="text-center mb-60">
            <h2 class="mb-0">{{community.title}}</h2>
        </div>
        <div *ngIf="language == 'en'" class="container mb-60 img-fit-width"
            [innerHTML]="community.communityDescription || ''"></div>

        <div *ngIf="language == 'es'" class="container mb-60 img-fit-width"
            [innerHTML]="community.communityDescriptionSpanish || ''">

        </div>
        <div class="row">
            <div class="col-md-3 text-right pt-5">
                <button class="side-btn" [routerLink]="['/buy']">
                    Buy a Home in {{community.title}}
                </button>
                <br>
                <button class="side-btn" [routerLink]="['/rent']">
                    Rent a Home in {{community.title}}
                </button>
                <br>
                <button class="side-btn" [routerLink]="['/sell']">
                    Sell a Home in {{community.title}}
                </button>
                <!-- <img src="../../assets/img/side-image.png" class="side-image"/> -->
                <img src="../../assets/img/side-image3.jpg" class="side-image" />
            </div>
            <div class="col-md-9 ">
                <div class="detail img-fit-width" [innerHTML]="community.description">

                </div>
            </div>
        </div>
    </div>
</section>

<section id="feature-two" class="feature-layout-two">
    <div class="container">
        <div class="mb-5">
            <h3 class="mb-0">Explore Other Communities</h3>
        </div>
        <app-communities></app-communities>
    </div>
</section>