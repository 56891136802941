import { Component, OnInit } from '@angular/core';
import { environments } from 'src/app/environments';
import { DataService } from '../data.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

  blogs : Array<any> = []
  loading : boolean = false
  URL : any = environments.APP_LIVE_URL

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle('Blog')
    this.URL = environments.APP_LIVE_URL
    this.getBlogs();
  }

  getBlogs() {
    this.loading = true
    this.dataService.__post('/blogs',{skip : this.blogs.length})
    .subscribe(
      (success) => {
        this.loading = false
        this.blogs = success;
      },
      (error) => {
        this.loading = false
        this.dataService.showError( error,'Error');
      }
    )
  }

  loadMore() {
    this.loading = true
    this.dataService.__post('/blogs',{skip : this.blogs.length})
    .subscribe(
      (success) => {
        this.loading = false
        success.map((s) => {
          this.blogs.push(s)
        })
      },
      (error) => {
        this.loading = false
        this.dataService.showError( error,'Error');
      }
    )
  }

 
}
