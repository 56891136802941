
import { environments } from "../environments";

export class Agent {
    _id: string;
    firstName : string = '';
    lastName : string = '';
    email : string = '';
    phoneNumber : string = '';
    mobileNumber : string = '';
    image : string = '';
    address : string = '';
    city : string = '';
    state : string = '';
    bio : string = '';
    zipcode : string = '';
    isVerified : boolean = false;

    constructor(init : Agent){
        Object.assign(this,init);
    }


    profileImage() : string{
        if(this.image == '')  return this.nameProfile();
        return environments.APP_LIVE_URL + '/images/' + this.image

    }


    nameProfile(){
        return `/assets/profile/${this.firstName.trim().charAt(0).toUpperCase()}.png`
    }

    fullName(){
        return this.firstName + ' ' + this.lastName
    }

}