import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Agent } from '../agents/agent.model';
import { DataService } from '../data.service';
import { SocketService } from '../socket.service';
@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.css']
})
export class ChatWindowComponent implements OnInit {


  chatWindowSubject : Subscription;
  messageReceiver : Subscription = new Subscription()

  agent: any = false
  user: any = false
  chats : Array<any> = []

  isChatOpen : boolean = false;
 

  constructor(
    private dataService : DataService,
    private route : ActivatedRoute,
    private socket : SocketService
  ) { }

  ngOnInit(): void {

    this.agent = this.dataService.getActiveAgent();
    this.user = this.dataService.getClient()

    // this.getChats();
   
    this.chatWindowSubject = this.dataService.getChatWindowListener()
    .subscribe(
      agent => {
        if(agent !== null){
          this.isChatOpen = true;
          this.user = this.dataService.getClient()
          this.getChats();
        } else {
          this.isChatOpen = false;
        }
      },
    )

    this.messageReceiver = this.socket.getSocketEvent('CHAT_MESSAGE_FROM_AGENT')
    .subscribe(
      (messages: any) =>{
        if(messages.user == this.user._id && messages.agent == this.agent._id) {
          this.chats.push(messages)
        }
      },

      (error) => {
        console.log('ERROR',error)
      }
    )
    

  }

  getChats() {

    // if(this.user == null) return;

    var data = {
      agent : this.agent._id,
      user : this.user._id
    }

    this.dataService.__post('/chats', data)
    .subscribe(
      (success) => {
        this.chats = success
      },
      (error) => {
        console.log(error)
        this.dataService.showError(error, 'Error')
      }
    )
  }

  sendMessage(message) {

    if(message.value.trim() == '') return false;

    const data = {
      agent : this.agent._id,
      user : this.user._id,
      message : message.value.trim(),
      from : this.user._id,
      to : this.agent._id,
      token : this.user.token,
      created_at : new Date()
    }
    this.socket.socketEmit('CHAT_MESSAGE_TO_AGENT',data)
    message.value = '';


    this.chats.push(data);


  }

  

}
