<app-header-fix></app-header-fix>


<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">Properties</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Properties</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="assets/img/service/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>

<div class="container">

    <section class="pt-50">
        <iframe src="https://matrix.ntreis.net/Matrix/public/IDX.aspx?idx=0cebfd9" width="100%" height="500px"
            frameborder="0" marginwidth="0" marginheight="0"></iframe>
        <p class="pt-50">{{'SEARCH_IDX_DESCRIPTION' | translate}}</p>
    </section>

    <section class="pa-50">
        <h3 class="text-theme mb-4">
            {{'PROPERTIES.HEAD_1' | translate}}
        </h3>
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/property/WhyChooseAnArbrookRealtyAgentWhenSelling.png" width="100%" height="400"
                    class="img-fluid" alt="">
            </div>
            <div class="col-md-8">
                <p>{{'PROPERTIES.TEXT_1' | translate}}</p>
                <div class="d-flex mt-3">
                    <a [routerLink]="['/sell']" class="button button-primary button-white mr-3 btn-mobile-respinsive">Learn More</a>
                    <a href="https://forms.gle/WAABfu4HUShVqm7b6" target="_blank"
                        class="button button-primary button-white btn-mobile-respinsive">WHAT’S MY HOME WORTH?</a>
                </div>
            </div>
        </div>
    </section>

    <section class="pa-50">
        <h3 class="text-theme mb-4">
            {{'PROPERTIES.HEAD_2' | translate}}
        </h3>
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/property/7StepsToBuyingAHomeb.jpg" width="100%" height="400" class="img-fluid"
                    alt="">
            </div>
            <div class="col-md-8">
                <p>{{'PROPERTIES.TEXT_2' | translate}}</p>
                <a [routerLink]="['/buy']" class="button button-primary button-white mt-3">Learn More</a>
            </div>
        </div>
    </section>

    <section class="pa-50">
        <h3 class="text-theme mb-4">
            {{'PROPERTIES.HEAD_3' | translate}}
        </h3>
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/property/RentOrBuyaHome.jpg" width="100%" height="400" class="img-fluid" alt="">
            </div>
            <div class="col-md-8">
                <p>{{'PROPERTIES.TEXT_3' | translate}}</p>
                <a [routerLink]="['/rent']" class="button button-primary button-white mt-3">Learn More</a>
            </div>
        </div>
    </section>

    <section class="pa-50">
        <h3 class="text-theme mb-4">
            Communities
        </h3>
        <app-communities></app-communities>
    </section>

</div>