import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';


import {Property} from '../../properties/property.model'

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.css']
})
export class PropertyCardComponent implements OnInit {


  @Input('property') property : Property;

  recentProperties : Array<Property> = []


  constructor(
    private dataService: DataService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle('Properties')
  }

  onSelectProperty(property) {

    this.dataService.addRecentProperty(property)
    this.router.navigate(['/properties', property._id])

  }

  
}
