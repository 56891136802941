<app-header-fix></app-header-fix>


<section id="hero" class="innar-hero">
    <div class="container" style="padding-block: 82px;">
        <div class="row mt-5">
            <div class="col-md-8">
                <h3 class="mb-0">How to Apply for a Real Estate License in Texas</h3>
            </div>
            <div class="col-md-4 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">How To Apply</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="assets/img/service/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>

<section id="property" class="  nobg-border pa-100 pt-40">
    <div class="property-list-one">
        <div class="container">
            <div class="row">
                <div class="col-md-12 pt-spc-0">
                    <div class="section-head listing">
                        <h2 data-title="">{{ 'ESTATE_LICENSE.HEADING' | translate }}</h2>
                        <p class="mb-0">{{ 'ESTATE_LICENSE.TEXT_1' | translate }}</p>
                        <h3>{{ 'ESTATE_LICENSE.HEAD_1' | translate }}</h3>
                        <p>{{ 'ESTATE_LICENSE.CONTENT_11' | translate }}</p>
                        <h3>{{ 'ESTATE_LICENSE.HEAD_2' | translate }}</h3>
                        <p>{{ 'ESTATE_LICENSE.CONTENT_21' | translate }}</p>
                        <h3>{{ 'ESTATE_LICENSE.HEAD_3' | translate }}</h3>
                        <p>{{ 'ESTATE_LICENSE.CONTENT_31_1' | translate }} <a href="https://www.trec.texas.gov/">{{
                                'ESTATE_LICENSE.LINK_1' | translate }}</a>. {{ 'ESTATE_LICENSE.CONTENT_31_2' | translate
                            }}</p>
                        <p>{{'ESTATE_LICENSE.CONTENT_32' | translate}} </p>
                        <p> {{'ESTATE_LICENSE.TEXT_21' | translate}} <a href="https://www.trec.texas.gov/">TREC</a>
                            {{'ESTATE_LICENSE.TEXT_22' | translate}}</p>



                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">{{ 'ESTATE_LICENSE.FIELD_1' | translate }}</th>
                                    <th scope="col">{{ 'ESTATE_LICENSE.FIELD_2' | translate }}</th>
                                </tr>
                            </thead>
                            <tr>
                                <td>{{'ESTATE_LICENSE.VALUE_1' | translate}}</td>
                                <td>$795.00 {{'ESTATE_LICENSE.DOLARES' | translate}}</td>
                            </tr>
                            <tr>
                                <td>{{'ESTATE_LICENSE.VALUE_2' | translate}}</td>
                                <td>$54.00 {{'ESTATE_LICENSE.DOLARES' | translate}}</td>
                            </tr>
                            <tr>
                                <td>{{'ESTATE_LICENSE.VALUE_3' | translate}}</td>
                                <td>$205.00 {{'ESTATE_LICENSE.DOLARES' | translate}}</td>
                            </tr>
                            <tr>
                                <td>{{'ESTATE_LICENSE.VALUE_4' | translate}}</td>
                                <td>$38.25 {{'ESTATE_LICENSE.DOLARES' | translate}}</td>
                            </tr>
                            <tr>
                                <td>{{'ESTATE_LICENSE.VALUE_5' | translate}}</td>
                                <td>$10.00 {{'ESTATE_LICENSE.DOLARES' | translate}}</td>
                            </tr>
                            <tr>
                                <td>{{'ESTATE_LICENSE.VALUE_6' | translate}}</td>
                                <td><b>$1102.25</b> {{'ESTATE_LICENSE.DOLARES' | translate}}</td>
                            </tr>

                        </table>

                        <p>{{'ESTATE_LICENSE.TEXT_3' | translate}} <a href="https://www.trec.texas.gov/">TREC’s</a>
                            {{'ESTATE_LICENSE.WEBSITE' | translate}}.
                        </p>
                        <p>{{'ESTATE_LICENSE.TEXT_4' | translate}}</p>
                        <h3>{{ 'ESTATE_LICENSE.HEAD_4' | translate }}</h3>
                        <p>{{'ESTATE_LICENSE.CONTENT_41' | translate}}</p>
                        <p>{{'ESTATE_LICENSE.CONTENT_42' | translate}}<br /> {{'ESTATE_LICENSE.CONTENT_43' |
                            translate}}<br /> {{'ESTATE_LICENSE.CONTENT_44' | translate}}</p>
                        <p>{{'ESTATE_LICENSE.CONTENT_45' | translate}}</p>
                        <h3> {{ 'ESTATE_LICENSE.HEAD_LAST' | translate }} </h3>
                        <p> {{ 'ESTATE_LICENSE.CONTENT_LAST_1' | translate }} <a
                                href="https://www.trec.texas.gov/">TREC</a>. {{ 'ESTATE_LICENSE.CONTENT_LAST_2' |
                            translate}}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>