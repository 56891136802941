import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebcamInitError } from 'ngx-webcam';
import { SocketService } from 'src/app/socket.service';

@Component({
  selector: 'app-video-calling',
  templateUrl: './video-calling.component.html',
  styleUrls: ['./video-calling.component.css'],
})
export class VideoCallingComponent implements OnInit {

  @ViewChild('ownVideoView') ownVideoView: ElementRef<HTMLElement>;
  @ViewChild('agentVideoView') agentVideoView: ElementRef<HTMLElement>;
  
  agentID: string;

  constructor(
    private activatedRouter: ActivatedRoute,
    private socketService: SocketService
  ) {}

  ngOnInit(): void {
    this.agentID = this.activatedRouter.snapshot.paramMap.get('agent');

    navigator.getUserMedia(
      { video: true, audio: true },
      (stream) => {
        const localVideo: any = this.ownVideoView.nativeElement;
        if (localVideo) {
          localVideo.srcObject = stream;
          localVideo.autoplay = true;
          localVideo.muted = true;

          // Call To Agent
          this.socketService.socketEmit('videoCallFromClient', {
            agent: this.agentID,
            email: 'kdmakwana43@gmail.com',
            phoneNumber: '988989',
            name: 'KD',
            stream : stream
          });
        }
      },
      (error) => {
        console.warn(error.message);
      }
    );

    //  CALL RECEIVED OR DECLINE

    this.socketService
      .getSocketEvent('videoCallStreamFromAgent')
      .subscribe((streaming: any) => {

        console.log('streaming',streaming)

        if (streaming.type == 'RECEIVED') {
          const agentVideo: any = this.agentVideoView.nativeElement;
          if (agentVideo) {
            agentVideo.srcObject = streaming.stream;
            agentVideo.autoplay = true;
            agentVideo.muted = true;
          }
        }
      });
  }

  handleInitError(error: WebcamInitError): void {
    if (
      error.mediaStreamError &&
      error.mediaStreamError.name === 'NotAllowedError'
    ) {
      console.log('Camera access was not allowed by user!');
    } else {
      console.log('HERE');
      // this.getMedia({video: true, audio: true});
    }
  }

  async getMedia(constraints) {
    let stream = null;

    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints);
      console.log('stream', stream);
    } catch (err) {
      console.log('Here', err);
      /* handle the error */
    }
  }
}
