<section class="chat-window">
    <div class="container">
        
        <div class="header">
            <div class="info">
                <span class="status"></span>
                <span class="name">Lionel Richie</span>
            </div>
            <div class="action">
                <i (click)="closeChatWindow()" class="fas fa-times"></i>
            </div>
        </div>
       
        <div class="chat text-center">
            <img class="calling-image" src="assets/img/feature/thumb.png" alt="" />
            <br>
            <div *ngIf="isCalling">
                <span class="calling-text">Calling...</span>
            </div>
            <div class="call-button" *ngIf="!isCalling">
                <button (click)="doCall()" class="btn btn-info"> Video Call </button>
            </div>
            <div class="call-button" *ngIf="isCalling">
                <button (click)="endCall()" class="btn btn-danger"> End Call </button>
            </div>
        </div>
        
    </div>
</section>