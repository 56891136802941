<app-header-fix  *ngIf="showHeader"></app-header-fix>

<section *ngIf="showHeader" id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">Information About Brokerage Service</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Information About Brokerage Service</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="/assets/img/about/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>

<section id="feature-two" class="feature-layout-two pa-100">
    <div class="container">

      <div class="row">

         <!-- <div class="col-md-12 text-right"><h6>11/2/2015</h6></div> -->

         <div class="col-2 text-center">
            <img src="../../assets/img/trce.png" alt="" height="80px" width="auto">
         </div>

         <div class="col-8 pl-4 text-center">

            <h3>Information	About	Brokerage	Services</h3>

            <p class="mb-0 f-18"><i>Texas law requires all real estate licensees to give the following information about</i></p>
            <p class="mb-0 f-18"><i>brokerage services to prospective buyers, tenants, sellers and landlords.</i></p>

         </div>

         <div class="col-2 text-center">
            <div>2-10-2025</div>
            <img src="../../assets/img/borkerage-logo.jpg" alt="" height="60px" width="auto">
         </div>
         
      </div>
        
    </div>
</section>

<section>

   <div class="col-md-10 offset-md-1">

      
      <b>TYPES OF REAL ESTATE LICENSE HOLDERS:</b>
      
      <br><br>
      
      <ul class="ul-list">
         
         <li><b>A BROKER</b> is responsible for all brokerage activities, including acts performed by sales agents sponsored by the broker.</li>
         <li><b>A SALES AGENT</b> must be sponsored by a broker and works with clients on behalf of the broker.</li>
         
      </ul>

      <br>
      
      <b>A BROKER'S MINIMUM DUTIES REQUIRED BY LAW (A client is the person or party that the broker represents):</b>
      
      <br><br>
      
      <ul class="ul-list">
         
         <li>Put the interests of the client above all others, including the broker's own interests;</li>
         <li>Inform the client of any material information about the property or transaction received by the broker;</li>
         <li>Answer the client's questions and present any offer to or counter-offer from the client; and</li>
         <li>Treat all parties to a real estate transaction honestly and fairly.</li>
         
      </ul>

      <br>

      <b>A LICENSE HOLDER CAN REPRESENT A PARTY IN A REAL ESTATE TRANSACTION:</b>

      <br><br>

      <p><b>AS AGENT FOR OWNER (SELLER/LANDLORD):</b> The broker becomes the property owner's agent through an agreement with the owner,
         usually in a written listing to sell or property management agreement. An owner's agent must perform the broker’s minimum duties
         above and must inform the owner of any material information about the property or information known by the agent, including
         information disclosed to the agent or subagent by the buyer or buyer’s agent.
         <b>
            An owner’s agent fees are not set by law and are fully negotiable.
         </b>
      </p>

      <p><b>AS AGENT FOR BUYER/TENANT:</b> The broker becomes the buyer/tenant's agent by agreeing to represent the buyer, usually through a
         written representation agreement. A buyer's agent must perform the broker’s minimum duties above and must inform the buyer of any
         material information about the property or transaction known by the agent, including information disclosed to the agent by the seller or
         seller’s agent.
         <b>
            A buyer/tenant’s agent fees are not set by law and are fully negotiable.
         </b>
      </p>

      <p><b>AS AGENT FOR BOTH - INTERMEDIARY:</b> To act as an intermediary between the parties the broker must first obtain the written
         agreement of each party to the transaction. The written agreement must state who will pay the broker and, in conspicuous bold or
         underlined print, set forth the broker's obligations as an intermediary. A broker who acts as an intermediary: 
      </p>

      <ul class="ul-list">

         <li>Must treat all parties to the transaction impartially and fairly; </li>
         <li>May, with the parties' written consent, appoint a different license holder associated with the broker to each party (owner and
            buyer) to communicate with, provide opinions and advice to, and carry out the instructions of each party to the transaction.
         </li>
         <li>

            Must not, unless specifically authorized in writing to do so by the party, disclose: <br><br>
            <ul>
               <li>that the owner will accept a price less than the written asking price;</li>
               <li>that the buyer/tenant will pay a price greater than the price submitted in a written offer; and</li>
               <li>any confidential information or any other information that a party specifically instructs the broker in writing not to
                  disclose, unless required to do so by law.
               </li>
            </ul>

         </li>

      </ul>

      <br>

      <p><b>AS SUBAGENT:</b> A license holder acts as a subagent when aiding a buyer in a transaction without an agreement to represent the
         buyer. A subagent can assist the buyer but does not represent the buyer and must place the interests of the owner first. 
      </p>

      <b>TO AVOID DISPUTES, ALL AGREEMENTS BETWEEN YOU AND A BROKER SHOULD BE IN WRITING AND CLEARLY ESTABLISH:</b>

      <br><br>

      <ul class="ul-list">

         <li>The broker’s duties and responsibilities to you, and your obligations under the representation agreement.</li>
         <li>Who will pay the broker for services provided to you, when payment will be made and how the payment will be calculated.</li>

      </ul>

      <br>

      <p><b>LICENSE HOLDER CONTACT INFORMATION:</b> : This notice is being provided for information purposes. It does not create an obligation for
         you to use the broker’s services. Please acknowledge receipt of this notice below and retain a copy for your records.
      </p>

      <br>

      <div class="row">

         <div class="col-md-4 border-bottom pr-0 mr-2 pl-0">
            <b class="purpul pl-2 w-100-desktop">Arbrook Realty Group, LLC</b>
            <span class="hide-desktop ml-2">(Licensed Broker /Broker Firm Name or
               Primary Assumed Business Name)</span>
         </div>

         <div class="col-md-2 text-center border-bottom pr-0 mr-2 pl-0 d-flex justify-content-between">
            <span class="hide-desktop pl-2">License No.</span>
            <b class="purpul pl-2 w-100-desktop">9005683</b>
         </div>
         <div class="col-md-3 text-center border-bottom pr-0 mr-2 pl-0 d-flex justify-content-between">
            <span class="hide-desktop pl-2">Email</span>
            <b class="purpul pl-2 w-100-desktop">roz@arbrookrealty.com</b>
         </div>
         <div class="col-md-2 text-center border-bottom pr-0 mr-2 pl-0 d-flex justify-content-between">
            <span class="hide-desktop pl-2">Phone Number</span>
            <b class="purpul pl-2 w-100-desktop">(972)996-4702</b>
         </div>


      </div>

      <div class="row hide-mobile">

         <div class="col-md-4 pr-0 mr-2 pl-0">
            <p>Licensed Broker /Broker Firm Name or
               Primary Assumed Business Name</p>
         </div>
         <div class="col-md-2 text-center pr-0 mr-2 pl-0">
            <p>License No.</p>
         </div>
         <div class="col-md-3 text-center pr-0 mr-2 pl-0">
            <p>Email</p>
         </div>
         <div class="col-md-2 text-center pr-0 mr-2 pl-0">
            <p>Phone</p>
         </div>

      </div>

      <div class="row">

         <div class="col-md-4 border-bottom pr-0 mr-2 pl-0 d-flex">
            <b class="purpul pl-2 w-100-desktop">Rosalind Booker</b>
            <span class="hide-desktop ml-2">(Designated Broker of Firm)</span>
         </div>

         

         <div class="col-md-2 text-center border-bottom pr-0 mr-2 pl-0 d-flex justify-content-between">
            <span class="hide-desktop pl-2">License No.</span>
            <b class="purpul pl-2 w-100-desktop">0449298</b>
         </div>
         <div class="col-md-3 text-center border-bottom pr-0 mr-2 pl-0 d-flex justify-content-between">
            <span class="hide-desktop pl-2">Email</span>
            <b class="purpul pl-2 w-100-desktop">roz@arbrookrealty.com</b>
         </div>
         <div class="col-md-2 text-center border-bottom pr-0 mr-2 pl-0 d-flex justify-content-between">
            <span class="hide-desktop pl-2">Phone Number</span>
            <b class="purpul pl-2 w-100-desktop">(972)996-4702</b>
         </div>

      </div>

      <div class="row hide-mobile">

         <div class="col-md-4 pr-0 mr-2 pl-0">
            <p>Designated Broker of Firm</p>
         </div>
         <div class="col-md-2 text-center pr-0 mr-2 pl-0">
            <p>License No.</p>
         </div>
         <div class="col-md-3 text-center pr-0 mr-2 pl-0">
            <p>Email</p>
         </div>
         <div class="col-md-2 text-center pr-0 mr-2 pl-0">
            <p>Phone</p>
         </div>

      </div>

      <div class="row">

         <div class="col-md-4 border-bottom pr-0 mr-2 pl-0 d-flex">
            <b class="purpul pl-2 w-100-desktop">Rosalind Booker</b>
            <span class="hide-desktop ml-2">(Licensed Supervisor of Sales Agent/ Associate)</span>
         </div>
         <div class="col-md-2 text-center border-bottom pr-0 mr-2 pl-0 d-flex justify-content-between">
            <span class="hide-desktop pl-2">License No.</span>
            <b class="purpul pl-2 w-100-desktop">0449298</b>
         </div>
         <div class="col-md-3 text-center border-bottom pr-0 mr-2 pl-0 d-flex justify-content-between">
            <span class="hide-desktop pl-2">Email</span>
            <b class="purpul pl-2 w-100-desktop">roz@arbrookrealty.com</b>
         </div>
         <div class="col-md-2 text-center border-bottom pr-0 mr-2 pl-0 d-flex justify-content-between">
            <span class="hide-desktop pl-2">Phone Number</span>
            <b class="purpul pl-2 w-100-desktop">(972)679-9311</b>
         </div>

      </div>

      <div class="row hide-mobile">

         <div class="col-md-4 pr-0 mr-2 pl-0 mb-4">
            <p>Licensed Supervisor of Sales Agent/
               Associate</p>
         </div>
         <div class="col-md-2 text-center pr-0 mr-2 pl-0">
            <p>License No.</p>
         </div>
         <div class="col-md-3 text-center pr-0 mr-2 pl-0">
            <p>Email</p>
         </div>
         <div class="col-md-2 text-center pr-0 mr-2 pl-0">
            <p>Phone</p>
         </div>

      </div>


      <div class="row">

         <div class="col-md-4 border-bottom pr-0 mr-2 pl-0 d-flex">
            <b class="purpul pl-2 w-100-desktop"></b>
            <span class="hide-desktop ml-2">(Sales Agent/Associate's Name)</span>
         </div>
         <div class="col-md-2 text-center border-bottom pr-0 mr-2 pl-0 d-flex justify-content-between">
            <span class="hide-desktop pl-2">License No.</span>
            <b class="purpul pl-2 w-100-desktop"></b>
         </div>
         <div class="col-md-3 text-center border-bottom pr-0 mr-2 pl-0 d-flex justify-content-between">
            <span class="hide-desktop pl-2">Email</span>
            <b class="purpul pl-2 w-100-desktop"></b>
         </div>
         <div class="col-md-2 text-center border-bottom pr-0 mr-2 pl-0 d-flex justify-content-between">
            <span class="hide-desktop pl-2">Phone Number</span>
            <b class="purpul pl-2 w-100-desktop"></b>
         </div>

      </div>


      <div class="row hide-mobile">

         <div class="col-md-4 pr-0 mr-2 pl-0  d-flex">
            <p>Sales Agent/Associate's Name</p>
         </div>
         <div class="col-md-2 text-center pr-0 mr-2 pl-0">
            <p>License No.</p>
         </div>
         <div class="col-md-3 text-center pr-0 mr-2 pl-0">
            <p>Email</p>
         </div>
         <div class="col-md-2 text-center pr-0 mr-2 pl-0">
            <p>Phone</p>
         </div>

      </div>

      <div class="row">

         <div class="col-md-2 offset-md-2 border-bottom pr-0 mr-2 pl-0">
             
         </div>
         <div class="col-md-2 border-bottom text-center pr-0 mr-2 pl-0">
         
         </div>
         <div class="col-md-3 border-bottom text-center pr-0 mr-2 pl-0">
            
         </div>

      </div>

      <div class="row">

         <div class="col-md-3 offset-md-3 text-right pr-0 mr-2 pl-0">
            Buyer/Tenant/Seller/Landlord Initials
         </div>
         
         <div class="col-md-3 text-center pr-0 mr-2 pl-0">
            Date
         </div>

      </div>

      <br><br>

      <div class="row">

         <div class="col-md-6 pl-0">
            <b>Regulated by the Texas Real Estate Commission</b>
         </div>
         <div class="col-md-6 text-right">
            <b>Information available at www.trec.texas.gov</b>
         </div>
         <div class="col-md-12 text-right">
            <p>IABS 1-1</p>
         </div>

      </div>

      <!-- <div class="row hide-mobile">

         <div class="col-md-7 pl-0">
            <p class="small"><b>Arbrook Realty, 825 Watters Creek Blvd #250 Allen, TX 75013</b></p>
         </div>
         <div class="col-md-2">
            <p class="small">Phone: <b>972-679-931</b></p>
         </div>
         <div class="col-md-2">
            <p class="small">Fax: <b>972-421-1810</b></p>
         </div>
         <div class="col-md-1 text-right">
            <p class="small">rb</p>
         </div>

      </div>

      <div class="row mb-5">
         <div class="col-md-3 pl-0">
            <p class="small">
               <b>Rosalind Booker</b>
            </p>
         </div>
         <div class="col-md-9">
            <p class="small">
               Produced with zipForm® by zipLogix 18070 Fifteen Mile Road, Fraser, Michigan 48026 &nbsp;&nbsp;&nbsp; www.zipLogix.com
            </p>
         </div>
      </div> -->

      
      
   </div>

</section>

<section id="about-brokerage-service">

</section>