<div class="mt-5">
  <div class="contact-card">
    <div class="profile-container">
      <!-- <span></span> -->
      <img
        [src]="property.agent.profileImage()"
        alt="{{ property.agent.firstName }}"
      />
    </div>
    <p class="property-details-title my-2">
      {{ property.agent.firstName }} {{ property.agent.lastName }}
    </p>
    <div class="property-discription d-flex">
      <div class="label">Office Phone:</div>
      {{ property.agent.phoneNumber || '-' }}
    </div>
    <div class="property-discription d-flex">
      <div class="label">Mobile Phone:</div>
      {{ property.agent.mobileNumber || '-' }}
    </div>
    <div class="property-discription d-flex">
      <div class="label">Email:</div>
      {{ property.agent.email || '-' }}
    </div>

    <a [routerLink]="['/contact-us']" class="button button-primary w-100 mt-4"
      >CONTACT {{ property.agent.firstName }}</a
    >
  </div>
</div>

<p class="property-details-title mt-5 mb-2">Schedule a tour</p>

<div class="contact-innar contact-form-card">
  <form #contactForm="ngForm" (ngSubmit)="handleContactForm(contactForm)">
    <div class="contact-form">
      <div class="input-full">
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Name:"
          required
          ngModel
        />
      </div>
      <div class="input-full">
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email:"
          required
          ngModel
        />
      </div>
      <div class="input-full">
        <input
          type="text"
          name="phoneNumber"
          id="phone"
          placeholder="Phone:"
          required
          ngModel
        />
      </div>
      <div class="textarea-full">
        <textarea
          name="message"
          id="message"
          placeholder="Message:"
          required
          ngModel
        ></textarea>
      </div>
      <div class="mt-5">
        <button
          [disabled]="!contactForm.valid"
          type="submit"
          class="button button-primary button-big w-100"
        >
          SCHEDULE TOUR
        </button>
      </div>
    </div>
  </form>
</div>
