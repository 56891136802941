import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle("News Letter")
  }

  onSubmit(formData: any) {
    this.dataService.__post('/news-letter', formData.value)
    .subscribe(
      (success) => {
        this.dataService.showSuccess(success, 'Success')
      },
      (error) => {
        this.dataService.showError(error, 'Error')
      }
    )
  }

}
