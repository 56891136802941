<app-header></app-header>

<section id="hero" class="hero-layout-one">
    <div class="container">
        <div class="row">
            <div class="col-md-8 mx-auto">
                <h2 class="color-white text-center">Welcome to Arbrook Realty! Look around at our innovative agent
                    benefits to help you on your journey</h2>
            </div>
        </div>
    </div>
</section>