import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  policies : Array<any> = []
  lng : string = 'en';

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.lng = this.dataService.getTemp('AR-Language')
    this.getPolicies();
    this.dataService.setTitle('Privacy Policies')
  }

  getPolicies() {

    

    this.dataService.__post('/privacy-policy', {}) 
    .subscribe(
      (success) => {
        this.policies = success
      },
      (error) => {
        this.dataService.showError(error, 'Error')
      }
    )
  }


}
