import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-faq-view-more',
  templateUrl: './faq-view-more.component.html',
  styleUrls: ['./faq-view-more.component.css']
})
export class FaqViewMoreComponent implements OnInit {
  
  faqs : Array<any> = []
  
  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle("FAQ's")
    this.loadFAQ();
  }

  loadFAQ() {
    this.dataService.__post('/faq',{})
    .subscribe(
      (success) => {
        this.faqs = success
        console.log(this.faqs)
      },
      (error) => {
        this.dataService.showError(error, 'Error')
      }
    )
  }

}
