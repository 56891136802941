<app-header-fix></app-header-fix>
<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-8">
                <h3 class="mb-0"></h3>
            </div>
            <div class="col-md-4 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item"><a>Our Blogs</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="/assets/img/blog/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>

<section id="single-blog" class="single-blog-layout pa-100">
    <div class="container">
        <div class="blog-content-wrap">
            <img src="{{URL}}/images/{{blog.image}}" alt="Arbrook Realty" class="img-fluid">
            <h2>{{blog.title}}</h2>
            <ul class="list-inline post-hierarchy">
                <!-- <li class="list-inline-item"><a href="#"><i class="far fa-user primary-color"></i> Adam
                               Shon</a></li>
                       <li class="list-inline-item"><a href="#"><i class="far fa-comment primary-color"></i> 0
                               Comment</a></li>
                       <li class="list-inline-item"><a href="#"><i class="far fa-folder-open primary-color"></i>
                               Realestate</a></li> -->
                <li class="list-inline-item"><a href="#"><i class="far fa-clock primary-color"></i> {{blog.created_at |
                        date:'MMMM dd, yyyy' }} </a></li>
            </ul>
            <div class="img-fit-width" [innerHTML]="blog.description"></div>

        </div>
    </div>
</section>