import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-consumer-fraud-alert',
  templateUrl: './consumer-fraud-alert.component.html',
  styleUrls: ['./consumer-fraud-alert.component.css']
})
export class ConsumerFraudAlertComponent implements OnInit {

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle("Consumer Fraud Alert")
  }

}
