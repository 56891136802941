import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

declare var $ : any;

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit {

  constructor(
    private dataService : DataService
  ) { }

  testimonials : Array<any> = [];

  ngOnInit(): void {
    this.loadTestimonial();
  }

  loadTestimonial(){
    this.dataService.__post('/testimonials',{})
    .subscribe(
      (success) => {
        this.testimonials = success;
        setTimeout(()=>{
          $('.testimonial-slider').slick({slidesToShow:1,slidesToScroll:1,appendDots:$(".testimonial-dots"),arrows:false,dots:true});
        },1000)
      },
      (error) => {
        console.log(error)
      }
    )

  }

}
