<app-chat-window></app-chat-window>

<div class="fb-customerchat" page_id="1049810175147170">
</div>

<footer id="footer" class="footer-layout-one">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-md-5">
                    <div class="footer-widget">
                        <h4>Quick links</h4>

                        <div class="row">
                            <div class="col-md-5">

                                <ul class="list-footer">
                                    <li><a href="https://yourhomeworth.realtoragent.me/" target="_blank">Subscribe to
                                            Newsletter</a></li>
                                    <li><a [routerLink]="['/blogs']">Latest Blogs</a></li>
                                    <li><a [routerLink]="['/events']">Events</a></li>
                                    <li><a [routerLink]="['/contact-us']">Contact Us</a></li>
                                    <li><a [routerLink]="['/press']">Press</a></li>
                                </ul>

                                <div class="social-icon my-5">
                                    <a target="_blank" href="https://www.facebook.com/ArbrookRealtyGroup/?fref=ts"><i
                                            class="fab fa-facebook-f"></i></a>
                                    <a target="_blank" href="https://twitter.com/rozbooker"><i
                                            class="fab fa-twitter"></i></a>
                                    <a target="_blank" href="https://www.instagram.com/askrozb/"><i
                                            class="fab fa-instagram"></i></a>
                                    <a target="_blank" href="https://www.linkedin.com/in/rosalind-booker-7789a68"><i
                                            class="fab fa-linkedin"></i></a>
                                    <a target="_blank"
                                        href="https://www.youtube.com/channel/UCjXiJzBOz6HHt56zS2NJVDg"><i
                                            class="fab fa-youtube"></i></a>
                                </div>

                            </div>

                            <div class="col-md-7 pr-0">

                                <ul class="list-footer mb-2">
                                    <li><a href="http://www.arbrookcares.org/" target="_blank">Arbrook Cares</a></li>
                                    <li><a [routerLink]="['/about-brokerage-service']">Information About Brokerage
                                            Services</a></li>
                                    <li><a [routerLink]="['/consumer-protection-notice']">Consumer Protection Notice</a>
                                    </li>
                                    <li><a [routerLink]="['/consumer-fraud-alert']">Consumer Fraud Alert</a></li>
                                    <li><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
                                </ul>

                                <a href="https://giv.li/kjrsve" target="_blank"
                                    class="button button-primary button-white mt-5"
                                    style="border-radius: 50px;">Donate</a>

                            </div>
                        </div>

                    </div>

                </div>
                <div class="col-md-3">
                    <div class="footer-widget">
                        <h4>Contact us</h4>
                        <div class="lists">
                            <ul class="list-footer">
                                <!-- <li><i class="fas fa-headphones"></i> Help & Support <b>(10 AM to 6 PM)</b></li> -->
                                <li><i class="fas fa-phone mr-2"></i> <a href="tel:972-996-4702">972-996-4702</a></li>
                                <li><i class="fas fa-envelope mr-2"></i> <a
                                        href="mailto:Roz@ArbrookRealty.com">Roz@ArbrookRealty.com</a></li>
                                <!-- <li [routerLink]="['/support-ticket']" style="cursor: pointer;" ><i class="fas fa-file-invoice"></i> Support Ticket</li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="footer-widget">
                        <h4>Location</h4>
                        <p>Arbrook Realty Group LLC <br>
                            825 Watters Creek Blvd #250 <br>
                            Allen, TX 75013 </p>
                        <div class="row">
                            <div class="col-md-9">
                                <div class="map-image">
                                    <div id="map-footer" style="height: 210px;"></div>
                                </div>
                            </div>
                            <div class="col-md-3 text-center">
                                <a href="#" class="footer-last-img">
                                    <img src="/assets/img/footer-last-img.png" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-1">
                    <div class="footer-widget">
                        <a href="#" class="footer-last-img">
                            <img src="/assets/img/footer-last-img.png" alt="">
                        </a>
                    </div>
                </div> -->
            </div>
            <div class="row mt-5">
                <div class="col-md-12 text-right">
                    <a href="https://play.google.com/store/apps/details?id=com.markedprivate.truvillion" target="_blank"
                        class="button button-primary button-white mb-3 mr-2 py-2 px-4 btn-mobile-100" style="border-radius: 20px;">
                        <img src="/assets/img/play-store.png" height="20" alt="">
                        Download Android App
                    </a>
                    <a href="https://apps.apple.com/us/app/truvillion-real-estate-academy/id1504431707" target="_blank"
                        class="button button-primary button-white mb-3 py-2 px-4  btn-mobile-100" style="border-radius: 20px;">
                        <img src="/assets/img/app-store.png" height="20" alt="">
                        Download IOS App
                    </a>
                </div>
            </div>
        </div>
    </div>




    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <p class="mb-0">Copyright &copy; 2021. Proudly powered by <a href="#">ArBrook</a></p>
                </div>
                <div class="col-md-6 text-right">
                    <ul class="list-inline">
                        <li class="list-inline-item"><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>