import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-consumer-protection-notice',
  templateUrl: './consumer-protection-notice.component.html',
  styleUrls: ['./consumer-protection-notice.component.css']
})
export class ConsumerProtectionNoticeComponent implements OnInit {

  constructor(
    private dataService: DataService
  ) { }

  customerProtectionNotice: any = false
  loading : boolean = false

  ngOnInit(): void {
    this.dataService.setTitle("Consumer Protection Notice")
    this.loading = true
    this.dataService.__post('/customer-notice-protection',{})
    .subscribe(
      (success) => {
        this.loading = false
        this.customerProtectionNotice = success;
      },
      (error) => {
        this.loading = false
        this.dataService.showError( error,'Error');
      }
    )

  }

}
