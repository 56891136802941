import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { VirtualTimeScheduler } from 'rxjs';
import { DataService } from '../data.service';
import { environments } from '../environments';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {

  slug: any = false;
  loading: boolean = false
  blog : any = false
  URL : any = environments.APP_LIVE_URL

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private metaService : Meta
  ) { }

  ngOnInit(): void {
    this.URL = environments.APP_LIVE_URL
    this.route.params.subscribe(
      (params) => {
        this.slug = params['slug']
        this.getBlog();
      }
    )
  }

  getBlog() {

    this.loading = true

    this.dataService.__post('/blog', {slug: this.slug})
    .subscribe(
      (success) => {
        this.blog = success;
        // Set SEO 

        this.dataService.setTitle(this.blog.title);
        this.metaService.addTags([
          {
            name : 'keywords',
            content : this.blog.keywords
          },
          {
            name : 'description',
            content : this.blog.metaDescription
          },
          { name: 'robots', content: "follow,index" }
        ]);

      },
      (error) => {
        this.dataService.showError( error,'Error')
      }
    )

  }

}
