
<section id="testimonial" class="testimonial-layout-one pa-100">
    <div class="container">
        <div class="row mb-60">
            <div class="col-md-6">
                <div class="section-head">
                    <h2 data-title="">WHAT OUR CLIENTS SAY</h2>
                </div>
            </div>
            <div class="col-md-6 align-self-center">
                <div class="testimonial-dots"></div>
            </div>
        </div>
        <div class="testimonial-slider">
            <div *ngFor="let testimonia of testimonials" class="testimonial-tt">
                <div class="testimonial-item">
                    <ul class="list-inline">
                        <li class="list-inline-item"><i class="fas fa-star"></i></li>
                        <li class="list-inline-item"><i class="fas fa-star"></i></li>
                        <li class="list-inline-item"><i class="fas fa-star"></i></li>
                        <li class="list-inline-item"><i class="fas fa-star"></i></li>
                        <li class="list-inline-item"><i class="fas fa-star"></i></li>
                    </ul>
                    <p class="lead">
                        {{testimonia.review}}
                    </p>
                    <div class="author">
                        <img src="{{testimonia.image}}" height="60" width="60" style="border-radius: 30px;" alt="Arbrook Realty">
                        <h4>{{testimonia.name}}</h4>
                        <p class="mb-0">{{testimonia.role}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

