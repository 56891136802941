<div class="steps step4">
    <h1>Upload Resume</h1>
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <label for="licenseFile">Upload Your Resume</label>
                <input type="file" (change)="onChangeResume($event)" class="form-control" id="licenseFile">
            </div>
        </div>
    </div>
</div>

<div class="steps step4 step5v2">
    <h1>DISC Assessments <small> (You will be directed to a secure third-party site to take a personality assessment. Save the assessment and upload your results here for review)</small></h1>
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <label><a href="https://www.tonyrobbins.com/disc-landing-form/" target="_blank" class="click-color">Click Here</a> to generate & download your disc assessment</label>
                <input type="file" (change)="onChangeAssessment($event)" class="form-control" id="assessments">
            </div>
        </div>
    </div>
</div>

<!-- 
<div class="steps step4 step5v3">
    <h1>Application Form</h1>
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Download Application form</label><br />
                        <a href="#" class="button button-primary button-white btn-formet-dl">Click to Download</a>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group">
                        <label for="application">Upload Your Application form</label>
                        <input type="file" (change)="onChangeApplicationForm($event)" class="form-control" id="application">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="steps-final-buttons">
    <a routerLink="/join-now/basic-question"><i class="fa fa-arrow-left"></i> Previous</a>
    <a (click)="onNext()"> <i *ngIf="isLoading" class="fas fa-spinner fa-spin"></i>Next <i class="fa fa-arrow-right"></i></a>
</div>