<div class="steps step5">
    <h1>Basic Questions</h1>
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="form-group">
                    <div class="col-md-12" *ngIf="!isQuestionsDone">
                        <!-- <label for="licenseFile"> {{questions[questionNumber].question}} </label> -->
                        <label for="licenseFile"> {{questions[questionNumber].label | translate}} </label>
                        <input type="text" class="form-control" [(ngModel)]="answer">
                        <button class="btn-nq" [class.btn-disabled]="answer.trim() == ''" [disabled]="this.answer.trim() == '' " (click)="onNextQuestion()">
                            Next Question
                        </button>
                    </div>
                    <div class="col-md-12 ques-done" *ngIf="isQuestionsDone">
                        <i class="fas fa-thumbs-up"></i>
                        <h2> All Questions Done! Please Click on Next button to Continue </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="steps-final-buttons" [class.btn-display]="!isQuestionsDone">
    <a routerLink="/join-now/agent-info"><i class="fa fa-arrow-left"></i> Previous</a>
    <button [disabled]="!isQuestionsDone" (click)="onNext()"> <i *ngIf="isLoading"
            class="fas fa-spinner fa-spin"></i>Next <i class="fa fa-arrow-right"></i></button>
</div>