<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">Support Ticket</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Support Ticket</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="/assets/img/about/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>

<section id="feature-two" class="feature-layout-two pa-100">
    <div class="container">
        <div class="text-center mb-60">
            <h2 class="mb-0">Support Ticket</h2>
        </div>
        <div class="row">
         <div class="col-md-6 offset-md-3">
            <form #supportTicketForm="ngForm" (ngSubmit)="onSubmit(supportTicketForm)">
              
               <div class="form-group">
                  <label>Customer Name</label>
                  <input type="text" name="customer_name" class="form-control" ngModel required>
               </div>
              
               <div class="form-group">
                  <label>Customer Email</label>
                  <input type="email" name="customer_email" class="form-control" ngModel required>
               </div>
              
               <div class="form-group">
                  <label>Contact Number</label>
                  <input type="text" name="contact_number" class="form-control" ngModel required>
               </div>

               <div class="form-group">
                  <label>Subject</label>
                  <input type="text" name="subject" class="form-control" ngModel required>
               </div>

               <div class="form-group">
                  <label>Message</label>
                  <textarea name="message" class="form-control" ngModel required rows="3"></textarea>
               </div>

               <div class="form-group">
                  <button type="submit" class="btn btn-primary" [disabled]="!supportTicketForm.valid" style="background-color: #408e9a;">Submit</button>
               </div>

            </form>
         </div>
      </div>
    </div>
</section>

