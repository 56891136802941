import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-uploads-info',
  templateUrl: './uploads-info.component.html',
  styleUrls: ['./uploads-info.component.css']
})
export class UploadsInfoComponent implements OnInit {

  isLoading: boolean = false
  agent : any = false

  resume: any = false
  DISC_assessments: any = false
  // applicationForm: any = false

  constructor(
      private dataService: DataService,
      private router: Router
  ) { }

  ngOnInit(): void {
    this.agent = JSON.parse(localStorage.getItem('JOIN_NOW_PERSONAL_INFO'));
    this.dataService.setTitle('Upload')
  }

  onChangeResume(event) {
    this.resume = event.target.files[0]
  }

  onChangeAssessment(event) {
    this.DISC_assessments = event.target.files[0]
  }

  // onChangeApplicationForm(event) {
  //   this.applicationForm = event.target.files[0]
  // }

  onNext() {

    var formData = new FormData();
    formData.append('agent',this.agent._id);
    formData.append('resume',this.resume);
    formData.append('DISC_assessments',this.DISC_assessments);
    // formData.append('applicationForm',this.applicationForm);

    this.isLoading = true

      this.dataService.__post('/agent/create/agent-documents', formData)
      .subscribe(
        (success) => {
          this.isLoading = false
          this.router.navigate(['/join-now','our-plans']);
        },
        (error) => {
          this.dataService.showError(error, 'Error')
          this.isLoading = false
        }
      )

  }

}
