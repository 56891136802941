<section *ngIf="!isDoneApplication">
<div class="steps step6">
    <h1>Choose Plan - {{activePlan}}</h1>
    <div class="row">
        <div class="col-md-6 mb-3">
            <div (click)="activePlan = 'Premium Plan'" class="team-item" [class.active]="activePlan == 'Premium Plan'">
                <div class="arrow-main">
                    <i class="fa fa-check"></i>
                </div>
                <div class="designation text-center">
                    <h4><a>Premium Plan</a></h4>
                    <p class="mb-0">
                        $249/monthly & $199/yearly
                    </p>
                </div>
                <ul class="info">
                    <li> <i class="fa fa-check"></i> 100% Commission- NO SPLITS!</li>
                    <li><i class="fa fa-check"></i> $199 per sales transaction (NO CAP)</li>
                    <li><i class="fa fa-check"></i> 15% Lease Transaction Fee</li>
                    <li><i class="fa fa-check"></i> E&O Included</li>
                    <li><i class="fa fa-check"></i> Marketing Tools with Customer Support</li>
                    <li><i class="fa fa-check"></i> Online Transaction Management System</li>
                    <li><i class="fa fa-check"></i> Listing E-Tours and Fliers</li>
                    <li><i class="fa fa-check"></i> YouTube Content</li>
                    <li><i class="fa fa-check"></i> Open House Resources</li>
                    <li><i class="fa fa-check"></i> E-Library</li>
                    <li><i class="fa fa-check"></i> CRM with Drip Campaign</li>
                    <li><i class="fa fa-check"></i> Agent Website (Personalized with Hosting)</li>
                    <li><i class="fa fa-check"></i> A Property Website (With Hosting)</li>
                    <li><i class="fa fa-check"></i> Blog and Newsletters</li>
                    <li><i class="fa fa-check"></i> Customizable Squeeze Pages</li>
                    <li><i class="fa fa-check"></i> Email/Text Campaigns</li>
                    <li><i class="fa fa-check"></i> Forms</li>
                    <li><i class="fa fa-check"></i> E-Signature & Templates</li>
                </ul>
            </div>
        </div>
        <div class="col-md-6">
            <div (click)="activePlan = 'Elite Plan'" class="team-item" [class.active]="activePlan == 'Elite Plan'">
                <div class="arrow-main"><i class="fa fa-check"></i></div>
                <div class="designation text-center">
                    <h4><a>Elite Plan</a></h4>
                    <p class="mb-0">
                        $399/monthly & $199/yearly
                    </p>
                </div>
                <ul class="info">
                    <li><i class="fa fa-check"></i> 100% Commission- NO SPLITS!</li>
                    <li><i class="fa fa-check"></i> SOAR 12 Week Training with Mentor/Coach</li>
                    <li><i class="fa fa-check"></i> $199 per sales transaction (NO CAP)</li>
                    <li><i class="fa fa-check"></i> 15% Lease Transaction Fee</li>
                    <li><i class="fa fa-check"></i> E&O Included</li>
                    <li><i class="fa fa-check"></i> Marketing Tools with Customer Support</li>
                    <li><i class="fa fa-check"></i> Online Transaction Management System</li>
                    <li><i class="fa fa-check"></i> Listing E-Tours and Fliers</li>
                    <li><i class="fa fa-check"></i> YouTube Content</li>
                    <li><i class="fa fa-check"></i> Open House Resources</li>
                    <li><i class="fa fa-check"></i> E-Library</li>
                    <li><i class="fa fa-check"></i> CRM with Drip Campaign</li>
                    <li><i class="fa fa-check"></i> Agent Website (Personalized with Hosting)</li>
                    <li><i class="fa fa-check"></i> A Property Website (With Hosting)</li>
                    <li><i class="fa fa-check"></i> Blog and Newsletters</li>
                    <li><i class="fa fa-check"></i> Customizable Squeeze Pages</li>
                    <li><i class="fa fa-check"></i> Email/Text Campaigns</li>
                    <li><i class="fa fa-check"></i> Forms</li>
                    <li><i class="fa fa-check"></i> E-Signature & Templates</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="steps-final-buttons">
    <a routerLink="/join-now/uploads-info"><i class="fa fa-arrow-left"></i> Previous</a>
    <button (click)="submitApplicationForm()"> <i *ngIf="isLoading" class="fas fa-spinner fa-spin"></i> Submit</button>

</div>

</section>


<div class="row" *ngIf="isDoneApplication"> 

    <div class="col-md-12 text-center">
        <h2 class="text-sucess"> Congratulations!</h2>
        <p class="con-text-wid">
            {{'CONGRESS_TEXT' | translate}}
        </p>
        <a routerLink="/home" class="button button-primary button-white">Back to Home</a>
    </div>

</div>