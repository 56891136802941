
<div class="feature-item" *ngIf="property">
    <div class="img">
        
        <img [src]="property.getMainImage()" alt="{{property.property_name}}" class="img-fluid property-image">

        <div class="hover">
            <div class="button-group">
                <a class="button-blue">For {{property.property_for}}</a>
            </div>
            <!-- <div  *ngIf="property.price != null && property.price != '' " class="pricing">
                <h5 class="mb-0 color-white">${{property.price | number}}</h5>
            </div> -->
        </div>
    </div>
    <div class="content-title pointer">
        <h5 class="property-name"><a (click)="onSelectProperty(property)">{{property.property_name}}</a></h5>
        <!-- <i class="fas fa-map-marker-alt primary-color"></i> -->
        <p class="property-address">{{property.address}}</p>
    </div>
    <div class="content-middle">
        <div class="row property-statistics py-1">
            <div class="col-3 pr-1">
                <p *ngIf="property.price != null && property.price != '' " >${{property.price || '0'}}</p>
                <!-- <p>$510,990</p> -->
            </div>
            <div class="col-9 pl-1 text-right">
                <p>{{property.total_beds || '0'}} beds | {{property.total_bathrooms || '0'}} baths | {{property.property_size || '-'}}
                </p> 
            </div>
        </div>
        <a [routerLink]="['/properties', property._id]" class="button button-primary w-100">VIEW LISTING</a>
        <!-- <p class="mb-0 d-inline-block"><i class="flaticon-building pr-1"></i> Rooms:
            <strong>{{property.total_rooms}}</strong> | Bed: <strong>{{property.total_beds}}</strong> | Bath: <strong>{{property.total_bathrooms}}</strong>
        </p> -->
        <!-- <a href="#" class="favorite-feature"><i class="far fa-heart"></i></a> -->
    </div>
    <!-- <div class="footer-content">
        <div *ngIf="property.agent" class="author d-flex">
            <div class="img pointer" [routerLink]="['/agents/' + property.agent._id]" >
                <img [src]="property.agent.profileImage()" alt="{{property.agent.firstName}}" style="width: 50px; height: 50px;">
            </div>
            <p class="mb-0 align-self-center pointer">{{property.agent.firstName}} {{property.agent.lastName}}</p>
        </div>

        <div *ngIf="!property.agent" class="author d-flex">
            <div class="img pointer" [routerLink]="['/agents/properties']" >
                <img src="/assets/img/logo-red-white.png" alt="Arbrook" style="width: 47px; height: 47px;  background: #408e9a;    border-radius: 100%;">
            </div>
            <p class="mb-0 align-self-center pointer">Arbrook</p>
        </div>
        <div class="right-content">
        </div>
    </div> -->
    
</div>

