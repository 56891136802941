import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-about-brokerage-service',
  templateUrl: './about-brokerage-service.component.html',
  styleUrls: ['./about-brokerage-service.component.css']
})
export class AboutBrokerageServiceComponent implements OnInit {

  @Input() showHeader: boolean = true; 

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle('About Brokerage service')
  }

}
