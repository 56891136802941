<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">Sell</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Sell</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="/assets/img/about/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>

<section id="feature-two" class="feature-layout-two pa-100">
    <div class="container">
        <div class="text-center mb-60">
            <h2 class="mb-0">{{'SELLER.TEXT_1' | translate}}</h2>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="feature-item">
                    <p class="lead">{{'SELLER.TEXT_2' | translate}}</p>
                </div>
            </div>
        </div>
        <div class="seller-btn mb-5">
            <a href="https://forms.gle/WAABfu4HUShVqm7b6" target="_blank" class="button button-primary button-white">What’s My Home Worth?</a>
            <a href=" https://calendly.com/rozbooker" target="_blank"  class="button button-primary button-white">Schedule Appointment</a>
        </div>
    </div>
</section>

<section id="about" class="about-innar pa-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-md-6 align-self-center">
                <div class="about-left">
                    <img src="/assets/img/about/single-2.png" alt="Arbrook Realty" class="img-fluid view-mobile">
                    <!-- <div class="video-play">
                        <a data-fancybox="Hero-video" href="https://www.youtube.com/watch?v=Maz7qkLzwYw" class="play-button"><i class="fas fa-play"></i></a>
                    </div> -->
                </div>
            </div>
            <div class="col-md-6">
                <h2 class="mb-40" style="font-size: 2.3em !important;">{{'SELLER.TEXT_3' | translate}}</h2>
                <p class="lead">{{'SELLER.TEXT_4' | translate}}</p>
                <ul class="about-list">
                    <li>{{'SELLER.LI_1' | translate}}</li>
                    <li>{{'SELLER.LI_2' | translate}}</li>
                    <li>{{'SELLER.LI_3' | translate}}</li>
                    <li>{{'SELLER.LI_4' | translate}}</li>
                    <li>{{'SELLER.LI_5' | translate}}</li>
                </ul>
                <a [routerLink]="['/agents']" class="button button-primary mb-2">Contact Agent</a>
            </div>
        </div>
    </div>
</section>


<section id="feature-two" class="feature-layout-two pa-100">
    <div class="container">
        <div class="text-center mb-40">
            <h2 class="mb-0">Work with an Arbrook Agent</h2>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="feature-item">
                    <p class="lead">Arbrook Realty meets the demands of Sellers and client-attitudes and expectations. Our handle on technology and leveraging of data enables Sellers to deliver personalized & optimal online and offline professional client services. Our
                        VOP (Virtual Office Platform) is a unique workplace environment with Sellers who are highly innovative and tech-savvy. We virtually bring the ultimate real estate experience to every fingertip. </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="container">
    <h3 class="text-theme mb-4">
        Communities
    </h3>
    <app-communities></app-communities>
</section>