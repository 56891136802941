import { Component, Input, OnInit } from '@angular/core';
import { environments } from 'src/app/environments';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.css']
})
export class BlogCardComponent implements OnInit {

  @Input('blog') blog : any = false
  URL : any = environments.APP_LIVE_URL

  constructor() { }

  ngOnInit(): void {
    this.URL = environments.APP_LIVE_URL
  }

}
