import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-join-now',
  templateUrl: './join-now.component.html',
  styleUrls: ['./join-now.component.css']
})
export class JoinNowComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }

}
