<div class="main-bg-vid">
    <div class="video-div">
        <div class="agent">
            <video #agentVideoView muted>
            <source src="" type="video/mp4">
            <source src="" type="video/ogg">
            Your browser does not support HTML video.
          </video>
        </div>

        <div class="client">
            <video #ownVideoView>
                <source src="" type="video/mp4">
                <source src="" type="video/ogg">
              </video>
            <!-- <webcam (initError)="handleInitError($event)" width="240"  ></webcam> -->

        </div>
        <div class="calling-cut">
            <button [routerLink]="['/home']" >
                <i class="fa fa-phone" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>


