<app-header-fix></app-header-fix>


<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50 pb-20">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">Property Agents</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Property Agents</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="assets/img/service/single.png" alt="" class="img-fluid">
    </div>
</section>


<section id="team" class="team-layout-one pa-100 pt-40">
    <div class="container">
        <div class="row">
            <div class="col-md-3  mb-60" *ngFor="let agent of agents">
                <app-agent-card [agent]="agent"></app-agent-card>
            </div>
        </div>
    </div>
</section>