<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">Buy</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Buy</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="/assets/img/about/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>



<section id="feature-two" class="feature-layout-two pa-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="feature-item mb-4">
                    <p class="lead mb-0">
                        {{ 'BUYER.INTRODUCTION' | translate }}
                    </p>
                </div>
                <iframe
                src="https://matrix.ntreis.net/Matrix/public/IDX.aspx?idx=0cebfd9"
                width="100%"
                height="500px"
                frameborder="0"
                marginwidth="0"
                marginheight="0"
              ></iframe>
            </div>
        </div>
    </div>
</section>

<section id="feature-two" class="feature-layout-two pa-100">
    <div class="container">

        <div class="text-center mb-60">
            <h2 class="mb-0">{{ 'BUYER.TEXT_1' | translate }}</h2>
        </div>
        <div class="row">
            <div class="col-md-6 text-center">
                <img src="/assets/img/buyer.jpg" alt="" class="buyer-img">
            </div>
            <div class="col-md-6">
                <div class="feature-item" style="padding: 55px 40px;">
                    <p class="lead">
                        {{ 'BUYER.TEXT_2' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="about" class="pb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="mb-40 mt-40">{{'BUYER.STEP_1' | translate}}</h2>
                <p class="lead">{{'BUYER.STEP_1_CONTENT.P_Why_' | translate}}</p>
                <ul class="about-list buyer-steps-ul">
                    <li>{{'BUYER.STEP_1_CONTENT.LI_1' | translate}}</li>
                    <li>{{'BUYER.STEP_1_CONTENT.LI_2' | translate}}</li>
                    <li>{{'BUYER.STEP_1_CONTENT.LI_3' | translate}}</li>
                    <li>{{'BUYER.STEP_1_CONTENT.LI_4' | translate}}</li>
                </ul>
                <p>{{'BUYER.STEP_1_CONTENT.P_LAST_' | translate}}</p>

                <!-- <a href="#" class="button button-primary">Book Now</a> -->
            </div>
        </div>
    </div>
</section>


<section id="about" class="pb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="mb-40">{{'BUYER.STEP_2' | translate}}</h2>
                <p class="lead">{{'BUYER.STEP_2_CONTENT.P_Consider_' | translate}}</p>
                <ul class="about-list buyer-steps-ul" style="list-style: circle;">
                    <li>{{'BUYER.STEP_2_CONTENT.LI_1' | translate}}</li>
                    <li>{{'BUYER.STEP_2_CONTENT.LI_2' | translate}}</li>
                    <li>{{'BUYER.STEP_2_CONTENT.LI_3' | translate}}</li>
                </ul>
                <!-- <a href="#" class="button button-primary">Book Now</a> -->
            </div>
        </div>
    </div>
</section>


<!-- Setp 3 -->

<section id="about" class="pb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="mb-40">{{'BUYER.STEP_3' | translate}}</h2>
                <ul class="about-list buyer-steps-ul" style="list-style: none;">
                    <li>{{'BUYER.STEP_3_CONTENT.LI_1' | translate}}</li>
                </ul>
                <!-- <a href="#" class="button button-primary">Book Now</a> -->
            </div>
        </div>
    </div>
</section>


<!-- Setep 4 -->

<section id="about" class="pb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="mb-40">{{'BUYER.STEP_4' | translate}}</h2>
                <p class="lead">{{'BUYER.STEP_4_CONTENT.P_BEFORE_' | translate}}</p>
                <ul class="about-list buyer-steps-ul">
                    <li>{{'BUYER.STEP_4_CONTENT.OL_1' | translate}}</li>
                    <li>{{'BUYER.STEP_4_CONTENT.OL_2' | translate}}</li>
                    <li>{{'BUYER.STEP_4_CONTENT.OL_3' | translate}}<li>
                        <p>{{'BUYER.STEP_4_CONTENT.OL_4' | translate}}</p>

                        <ul class="buyer-steps-ul" style="list-style: circle;">
                            <li>{{'BUYER.STEP_4_CONTENT.UL_1' | translate}}</li>
                            <li>{{'BUYER.STEP_4_CONTENT.UL_2' | translate}}</li>
                            <li>{{'BUYER.STEP_4_CONTENT.UL_3' | translate}}</li>
                            <li>{{'BUYER.STEP_4_CONTENT.UL_4' | translate}}</li>
                            <li>{{'BUYER.STEP_4_CONTENT.UL_5' | translate}}</li>
                            <li>{{'BUYER.STEP_4_CONTENT.UL_6' | translate}}</li>
                        </ul>



                    </li>
                    <li>{{'BUYER.STEP_4_CONTENT.OL_5' | translate}}</li>
                </ul>
                <!-- <a href="#" class="button button-primary">Book Now</a> -->
            </div>
        </div>
    </div>
</section>


<!-- Step 5 -->

<section id="about" class="pb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="mb-40">{{'BUYER.STEP_5' | translate}}</h2>
                <p class="lead">{{'BUYER.STEP_5_CONTENT.P_YOUR_' | translate}}</p>
                <ul class="about-list buyer-steps-ul">
                    <li>{{'BUYER.STEP_5_CONTENT.LI_1' | translate}}</li>
                    <li>{{'BUYER.STEP_5_CONTENT.LI_2' | translate}}</li>
                    <li>{{'BUYER.STEP_5_CONTENT.LI_3' | translate}}</li>
                </ul>
                <!-- <a href="#" class="button button-primary">Book Now</a> -->
            </div>
        </div>
    </div>
</section>


<!-- step 6 -->


<section id="about" class="pb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="mb-40">{{'BUYER.STEP_6' | translate}}</h2>
                <!-- <p class="lead">Why? A REALTOR® will help you with the following steps. He or she will save you valuable time and money by:</p> -->
                <ul class="about-list buyer-steps-ul">
                    <li>{{'BUYER.STEP_6_CONTENT.LI_1' | translate}}</li>
                </ul>
                <!-- <a href="#" class="button button-primary">Book Now</a> -->
            </div>
        </div>
    </div>
</section>


<!-- Step 7 -->

<section id="about" class="pb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="mb-40">{{'BUYER.STEP_7' | translate}}</h2>
                <p class="lead">{{'BUYER.STEP_7_CONTENT.P_WHY_USE' | translate}}</p>
                <ul class="about-list buyer-steps-ul">
                    <li>{{'BUYER.STEP_7_CONTENT.LI_1' | translate}}</li>
                    <li>{{'BUYER.STEP_7_CONTENT.LI_2' | translate}}</li>
                    <li>{{'BUYER.STEP_7_CONTENT.LI_3' | translate}}</li>
                    <li>{{'BUYER.STEP_7_CONTENT.LI_4' | translate}}</li>
                    <li>{{'BUYER.STEP_7_CONTENT.LI_5' | translate}}</li>
                </ul>
                <!-- <a href="#" class="button button-primary">Book Now</a> -->
            </div>
        </div>
    </div>

</section>

<div class="row mb-40">
    <div class="col-md-12 text-center">
        <a [routerLink]="['/agents']"  class="button button-primary">Contact Agent</a>
    </div>
</div>

<section class="pa-50 container">
    <h3 class="text-theme mb-4">
        Communities
    </h3>
    <app-communities></app-communities>
</section>