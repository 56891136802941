<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
   <div class="container pa-50">
      <div class="row">
         <div class="col-md-6">
            <h3 class="mb-0">Press</h3>
         </div>
         <div class="col-md-6 align-self-center text-right">
            <ul class="list-inline">
               <li class="list-inline-item"><a href="#">Home</a></li>
               <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
               <li class="list-inline-item">Press</li>
            </ul>
         </div>
      </div>
   </div>
   <div class="img banner-position">
      <img src="/assets/img/about/single.png" alt="Arbrook Realty" class="img-fluid">
   </div>
</section>

<section id="feature-two" class="feature-layout-two pa-100" *ngIf="press">
   <div class="container">
      <div class="text-center mb-60">
         <h2 class="mb-0">{{press.title}}</h2>
      </div>

      <div>
         <i class="fa fa-calendar mr-3"></i> {{press.Date | date : 'dd/MM/yyyy'}}
      </div>

      <div class="img-fit-width mt-2" [innerHTML]="press.description">

      </div>
      <!-- <div class="row mt-2">
         <div class="col-md-8 offset-md-2">
            <div>
               <h3>Contact Info</h3>
               <p>Rosalind Booker</p>
               <p>972-679-9311</p>
               <a href="http://abrookrealty.com/">http://abrookrealty.com/</a> <br>
               <a href="mailto:Roz@Arbrookrealty.com">Roz@Arbrookrealty.com</a>
            </div>
         </div>
      </div> -->
   </div>
</section>

<section id="feature-two" class="feature-layout-two pa-100" *ngIf="!press && !isLoading">
   <div class="container">
      <div class="text-center">
         <img src="/assets/img/press.png" height="250" width="250" alt="">
         <h3 class="mb-0 mt-5">There are no any press available</h3>
      </div>
   </div>
</section>