
<div class="row mb-40">
    <div class="col-md-10 offset-md-1 text-center">
        <h3>We’re thrilled you want to join our team. <br/> Let’s take you through some steps that will help us get to know you better</h3>
    </div>
</div>

<form #personalInformation="ngForm" (ngSubmit)="handlePersonalInformation(personalInformation)">

    <div class="steps step-1">
        <h1>Real Estate Application</h1>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="firstName">First Name</label>
                            <input name="firstName" type="text" class="form-control" id="firstName" required ngModel>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="lastName">Last Name</label>
                            <input type="text" class="form-control" id="lastName" name="lastName" required ngModel>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="email">Email Address</label>
                            <input type="email" class="form-control" id="email" name="email" required ngModel>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="contactNumber">Contact Number</label>
                            <input type="text" class="form-control" id="contactNumber" name="phoneNumber" required ngModel>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="password">Create Password</label>
                            <input type="password" class="form-control" id="password" name="password" required ngModel>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="cpassword">Confirm Password</label>
                            <input type="password" class="form-control" id="cpassword" name="conformPassword" required ngModel>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="address">Address</label>
                            <input type="text" class="form-control" id="address" name="address" required ngModel>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="city">City</label>
                            <input type="text" class="form-control" id="city" name="city" required ngModel>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="state">State</label>
                            <input type="text" class="form-control" id="state" name="state" required ngModel>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="zipcode">Zip code</label>
                            <input type="text" class="form-control" id="zipcode" name="zipcode" required ngModel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="steps-final-buttons">
        <button [disabled]="!personalInformation.valid" type="submit"> <i *ngIf="isLoading" class="fas fa-spinner fa-spin"></i> Next <i class="fa fa-arrow-right"></i></button>
    </div>

</form>