<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-8">
                <h3 class="mb-0">Contact Us</h3>
            </div>
            <div class="col-md-4 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Contact Us</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="/assets/img/about/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
    <!-- <div class="map-image">
        <img src="/assets/img/map-contact.png" alt="">
        <div id="map-footer" style="height: 400px;"></div>
    </div> -->
</section>

<section class="contact-innar pa-100">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="contact-item mb-50">
                    <h4>Corporate Office Address</h4>
                    <div class="contact-lists">
                        <div class="contact-list d-flex mb-3">
                            <div class="icon">
                                <i class="fas fa-map-marker-alt primary-color"></i>
                            </div>
                            <p class="mb-0">Arbrook Realty Group LLC <br>
                                825 Watters Creek Blvd #250 <br>
                                Allen, TX 75013</p>
                        </div>
                        <div class="contact-list d-flex mb-3">
                            <div class="icon">
                                <i class="fas fa-phone-alt primary-color"></i>
                            </div>
                            <p class="mb-0"><a href="tel:972-996-4702">972-996-4702</a></p>
                        </div>
                        <div class="contact-list d-flex mb-3">
                            <div class="icon">
                                <i class="fas fa-envelope primary-color"></i>
                            </div>
                            <p class="mb-0"><a href="mailto:Roz@ArbrookRealty.com">Roz@ArbrookRealty.com</a></p>
                        </div>
                    </div>
                </div>
                <!-- <div class="contact-item">
                    <h4>Divison Office Address</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid.</p>
                    <div class="contact-lists">
                        <div class="contact-list d-flex">
                            <div class="icon">
                                <i class="fas fa-map-marker-alt primary-color"></i>
                            </div>
                            <p class="mb-0">Arbrook Realty Group, LLC 825 Watters Creek Blvd. #250 Allen, TX 75013.</p>
                        </div>
                        <div class="contact-list d-flex">
                            <div class="icon">
                                <i class="fas fa-phone-alt primary-color"></i>
                            </div>
                            <p class="mb-0">Rentals:  972-996-4702</p>
                        </div>
                        <div class="contact-list d-flex">
                            <div class="icon">
                                <i class="fas fa-envelope primary-color"></i>
                            </div>
                            <p class="mb-0"><a href="#" class="__cf_email__"
                                    data-cfemail="eb989e9b9b84999f9f83998e8eab8c868a8287888486">Roz@ArbrookRealty.com</a>
                            </p>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="col-md-6 pt-40">
                <form #contactForm="ngForm" (ngSubmit)="handleContactForm(contactForm)">
                    <div class="contact-form">
                        <div class="input-full">
                            <label for="name">Your Name*</label>
                            <input type="text" name="name" id="name" placeholder="" required ngModel>
                        </div>
                        <div class="input-full">
                            <label for="email">Your Email*</label>
                            <input type="email" name="email" id="email" placeholder="" required ngModel>
                        </div>
                        <div class="input-full">
                            <label for="phone">Your Phone*</label>
                            <input type="text" name="phoneNumber" id="phone" placeholder="" required ngModel>
                        </div>
                        <div class="textarea-full">
                            <label for="message">Your Message*</label>
                            <textarea name="message" id="message" placeholder="Please add details text........."
                                required ngModel></textarea>
                        </div>
                        <div class="input-submit">
                            <button [disabled]="!contactForm.valid" type="submit"
                                class="button button-primary button-big">Send Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>