import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-header-links',
  templateUrl: './header-links.component.html',
  styleUrls: ['./header-links.component.css']
})
export class HeaderLinksComponent implements OnInit {

  showMenu : boolean = false;
  @Input() isMenuToggle: Observable<void>;
  isMenuToggleSubject : Subscription ; 
  communities : Array<any> = []

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit(): void {
    this.isMenuToggleSubject = this.isMenuToggle.subscribe(() => this.showMenu = !this.showMenu);
    this.getCommunities();
  }

  changeLanguage(language : string){
    localStorage.setItem('AR-Language',language);
    window.location.reload();

  }

  getCommunities() {

    this.dataService.__post('/communities', {})
    .subscribe(
      (success) => {
        this.communities = success
      },
      (error) => {
        this.dataService.showError(error, 'Error')
      }
    )

  }

}
