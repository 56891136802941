
<!-- Model Button For Appointment -->
<button style="display: none;" #bookAppointmentButton type="button" data-toggle="modal" data-target="#bookAppointment" >xxx</button>
  
  <!-- Client Form -->
<form class="steps" #clientDetailsForm="ngForm" (ngSubmit)="onBookAppointment(clientDetailsForm)">
    <div class="modal fade" id="bookAppointment" tabindex="-1" role="dialog" aria-labelledby="bookAppointmentTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="form-group">
                    <label>Name</label>
                    <input name="customerName" type="text" class="form-control" required ngModel> 
                </div>

                <div class="form-group">
                    <label>Appointment Title</label>
                    <input name="title" type="text" class="form-control" required ngModel> 
                </div>
                
                <div class="form-group">
                    <label>Email</label>
                    <input name="email" type="text" class="form-control" required ngModel> 
                </div>

                <div class="form-group">
                    <label>Date</label>
                    <!-- <input class="form-control" name="appointmentDate" type="date" required ngModel> -->
                    <input
                      name="appointmentDate"
                      id="appointmentDate"
                      class="form-control"
                      [owlDateTime]="dt2"
                      [owlDateTimeTrigger]="dt2"
                      placeholder="Date Time"
                      required
                      ngModel
                      [(ngModel)]="appointmentDate"
                    />
                    <owl-date-time #dt2 [hour12Timer]="true"></owl-date-time>
                </div>

                <textarea placeholder="Description about appointment" class="form-control" name="description" required ngModel rows="5"></textarea>

            </div>
            <div class="modal-footer">
            <button #closeButton type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="submit" [disabled]="!clientDetailsForm.valid" class="btn btn-primary" style="background-color: #009688;border-color: #009688;">Book Appointment</button>
            </div>
        </div>
        </div>
    </div>
</form>