<app-header-fix></app-header-fix>
<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-8">
                <h3 class="mb-0">Event</h3>
            </div>
            <div class="col-md-4 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item"><a>Our Event</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="/assets/img/blog/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>

<section id="single-blog" class="single-blog-layout pa-100">
    <div class="container">
        <div class="blog-content-wrap">
            <img src="{{URL}}/images/{{event.image}}" alt="Arbrook Realty" class="img-fluid">
            <h2>{{event.place}}</h2>
            <ul class="list-inline post-hierarchy">
                <li class="list-inline-item"><a href="#"><i class="far fa-clock primary-color"></i>
                        {{event.dateFormate}} </a></li>
            </ul>
            <div *ngIf="language == 'en'" class="img-fit-width" [innerHTML]="event.description"></div>
            <div *ngIf="language == 'es'" class="img-fit-width" [innerHTML]="event.descriptionSpanish"></div>
        </div>
    </div>
</section>