<app-header-fix></app-header-fix>

<section id="hero" class="hero-layout-three">
    <div class="container">
        <div class="row">
            <div class="col-md-7 align-self-center">
                <div class="hero-left">
                    <h1>How Do We Compare?</h1>
                    <h4 class="primary-color">Let’s take a look!</h4>
                    <p>Using a sales price of $300,000 and 7 as the number of homes sold, which is realistic although
                        conservative for someUsing a sales price of $300,000 and 7 as the number of homes sold, which is
                        realistic although conservative for some</p>

                    <p>
                        Assume: 3% commission on the sale
                        <br />
                        $300,000 x 3% = $9,000 / home
                        <br />
                        $9,000 x 7 homes = $63,000 earned
                    </p>

                </div>
            </div>
        </div>
        <div class="img-hero">
            <img src="/assets/img/hero/4.png" alt="Arbrook Realty">
        </div>
    </div>
</section>

<section id="team" class="team-layout-one bg-light pa-100">
    <div class="container">
        <div class="row">



            <div class="col-md-12 text-center">
                <h4>
                    How much commission do you keep?
                </h4>
                <p>
                    The following are from actual brokerages – however, they may have even more fees not listed.
                </p>
                <p>
                    The Arbrook Realty plan is crafted to put more profit back into the hands of the agent. We offer
                    the latest tech in the RE space, a specialized Virtual Office Team, and a virtual 12-week
                    in-depth training. See our packages below to find your best fit!
                </p>

                <div class="gap-30"></div>
            </div>

            <div class="col-md-12 text-center p-100">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Brokerage</th>
                            <th scope="col">Commission Split</th>
                            <th scope="col">Monthly Fee</th>
                            <th scope="col">Annual Fee</th>
                            <th scope="col">Transaction Fee</th>
                            <th scope="col">Minimum Fee</th>
                            <th scope="col">Total Yearly Fee</th>
                            <th scope="col">Yearly Commission</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Broker A</th>
                            <td>70/30%</td>
                            <td>????</td>
                            <td>????</td>
                            <td>????</td>
                            <td>????</td>
                            <td>$18,900</td>
                            <td>$44,100</td>
                        </tr>
                        <tr>
                            <th scope="row">Broker B</th>
                            <td>60/40%</td>
                            <td>????</td>
                            <td>????</td>
                            <td>????</td>
                            <td>????</td>
                            <td>$25,200</td>
                            <td>$37,800</td>
                        </tr>
                        <tr>
                            <th scope="row">ELITE ARBROOK AGENTS</th>
                            <td>100%</td>
                            <td>$399</td>
                            <td>$199</td>
                            <td>$199</td>
                            <td>---</td>
                            <td>$7,375</td>
                            <td>$55,600</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-md-12 text-center">
                <h3> Sponsorship Rate Plan </h3>
                <div class="gap-30"></div>
            </div>


            <div class="col-md-6">

                <div class="team-item">
                    <div class="designation text-center">
                        <h4><a>Premium Plan</a></h4>
                        <p class="mb-0">$249/month | $199/yearly</p>
                    </div>
                    <ul class="info">
                        <li> <i class="fa fa-check"></i> 100% Commission- NO SPLITS!</li>
                        <li><i class="fa fa-check"></i> $199 per sales transaction (NO CAP)</li>
                        <li><i class="fa fa-check"></i> 15% Lease Transaction Fee</li>
                        <li><i class="fa fa-check"></i> E&O Included</li>
                        <li><i class="fa fa-check"></i> Marketing Tools with Customer Support</li>
                        <li><i class="fa fa-check"></i> Online Transaction Management System</li>
                        <li><i class="fa fa-check"></i> Listing E-Tours and Fliers</li>
                        <li><i class="fa fa-check"></i> YouTube Content</li>
                        <li><i class="fa fa-check"></i> Open House Resources</li>
                        <li><i class="fa fa-check"></i> E-Library</li>
                        <li><i class="fa fa-check"></i> CRM with Drip Campaign</li>
                        <li><i class="fa fa-check"></i> Agent Website (Personalized with Hosting)</li>
                        <li><i class="fa fa-check"></i> A Property Website (With Hosting)</li>
                        <li><i class="fa fa-check"></i> Blog and Newsletters</li>
                        <li><i class="fa fa-check"></i> Customizable Squeeze Pages</li>
                        <li><i class="fa fa-check"></i> Email/Text Campaigns</li>
                        <li><i class="fa fa-check"></i> Forms</li>
                        <li><i class="fa fa-check"></i> E-Signature & Templates</li>
                    </ul>
                </div>
            </div>

            <div class="col-md-6">
                <div class="team-item">

                    <div class="designation text-center">
                        <h4><a>Elite Plan</a></h4>
                        <p class="mb-0">$399/month | $199/yearly </p>
                    </div>
                    <ul class="info">
                        <li><i class="fa fa-check"></i> 100% Commission- NO SPLITS!</li>
                        <li><i class="fa fa-check"></i> SOAR 12 Week Training with Mentor/Coach</li>
                        <li><i class="fa fa-check"></i> $199 per sales transaction (NO CAP)</li>
                        <li><i class="fa fa-check"></i> 15% Lease Transaction Fee</li>
                        <li><i class="fa fa-check"></i> E&O Included</li>
                        <li><i class="fa fa-check"></i> Marketing Tools with Customer Support</li>
                        <li><i class="fa fa-check"></i> Online Transaction Management System</li>
                        <li><i class="fa fa-check"></i> Listing E-Tours and Fliers</li>
                        <li><i class="fa fa-check"></i> YouTube Content</li>
                        <li><i class="fa fa-check"></i> Open House Resources</li>
                        <li><i class="fa fa-check"></i> E-Library</li>
                        <li><i class="fa fa-check"></i> CRM with Drip Campaign</li>
                        <li><i class="fa fa-check"></i> Agent Website (Personalized with Hosting)</li>
                        <li><i class="fa fa-check"></i> A Property Website (With Hosting)</li>
                        <li><i class="fa fa-check"></i> Blog and Newsletters</li>
                        <li><i class="fa fa-check"></i> Customizable Squeeze Pages</li>
                        <li><i class="fa fa-check"></i> Email/Text Campaigns</li>
                        <li><i class="fa fa-check"></i> Forms</li>
                        <li><i class="fa fa-check"></i> E-Signature & Templates</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>