<app-header-fix></app-header-fix>

<section id="hero" class="hero-layout-one">
  <div class="hero-video">
    <video
      width="100%"
      height="100%"
      loop
      muted="muted"
      autoplay
      oncanplay="this.play()"
      onloadedmetadata="true"
    >
      <source src="/assets/img/Drone_Neighborhood.mp4" type="video/mp4" />
    </video>
  </div>
  <div class="container form-position">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <h1 class="color-white text-center hero-text">
          Arbrook Realty is a full-service technology-driven real estate
          company. We virtually bring the ultimate real estate experience to
          every fingertip.
        </h1>
      </div>
    </div>
  </div>
</section>

<section id="feature-three " class="feature-layout-three pa-100">
  <div class="container">
    <div class="row text-center">
      <div class="col-md-4">
        <a routerLink="/home/buyers">
          <div class="feature-item">
            <div class="icon">
              <img
                src="assets/img/buyer-home.png"
                alt=""
                height="80"
                width="80"
              />
            </div>
            <h4>BUY</h4>
          </div>
        </a>
      </div>
      <div class="col-md-4">
        <a routerLink="/home/sellers">
          <div class="feature-item">
            <div class="icon">
              <img
                src="assets/img/seller-hand.png"
                alt=""
                height="120"
                width="120"
              />
            </div>
            <h4>SELL</h4>
          </div>
        </a>
      </div>
      <div class="col-md-4">
        <a routerLink="/home/renter">
          <div class="feature-item">
            <div class="icon">
              <img src="assets/img/icon-3.png" alt="" />
            </div>
            <h4>RENT</h4>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>

<!-- <section id="why-join-se" class="about-innar pa-100">
    <div class="container">
        <div class="row">
            <div class="col-md-6 align-self-center">
                <div class="about-left">
                    <img src="/assets/img/whyJooin.jpeg" alt="Arbrook Realty" class="img-fluid view-mobile">
                    <div class="video-play">
                        <a data-fancybox="Hero-video" href="https://youtu.be/FPj9mZ6YrgE" class="play-button"><i
                                class="fas fa-play"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <h2 class="mb-40">Why Join?</h2>
                <p class="lead">Welcome to Arbrook Realty! Look around at our innovative agent benefits to help you on
                    your journey. Look into why our sponsorship plan is incomparable.</p>
                <a [routerLink]="['/join-now','personal-info']" class="button button-primary mb-3">Join Now</a>
            </div>
        </div>
    </div>
</section> -->

<!-- <section>
    <div class="property-list-two pb-100 gray-bg">
        <div class="container">
            <div class="row">

                <div class="col-md-12 ml-auto text-center">
                    <div class="section-head mb-40 text-center">

                    </div>
                    <div class="row mb-30 spacing">
                        <div class="steps home">
                            <h1 class="text-center"> Our Plans</h1>
                            <br />
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <div class="team-item">
                                        <div class="arrow-main ">
                                            <i class="fa fa-check "></i>
                                        </div>
                                        <div class="designation text-center ">
                                            <h4>Premium Plan</h4>
                                            <p class="mb-0 font-20">$249/month | $199/yearly</p>
                                        </div>
                                        <ul class="info text-left">
                                            <li> <i class="fa fa-check "></i> 100% Commission- NO SPLITS!</li>
                                            <li><i class="fa fa-check "></i> $199 per sales transaction (NO CAP)</li>
                                            <li><i class="fa fa-check "></i> 15% Lease Transaction Fee</li>
                                            <li><i class="fa fa-check "></i> E&O Included</li>
                                            <li><i class="fa fa-check "></i> Marketing Tools with Customer Support</li>
                                            <li><i class="fa fa-check "></i> Online Transaction Management System</li>
                                            <li><i class="fa fa-check "></i> Listing E-Tours and Fliers</li>
                                            <li><i class="fa fa-check "></i> YouTube Content</li>
                                            <li><i class="fa fa-check "></i> Open House Resources</li>
                                            <li><i class="fa fa-check "></i> E-Library</li>
                                            <li><i class="fa fa-check "></i> CRM with Drip Campaign</li>
                                            <li><i class="fa fa-check "></i> Agent Website (Personalized with Hosting)
                                            </li>
                                            <li><i class="fa fa-check "></i> A Property Website (With Hosting)</li>
                                            <li><i class="fa fa-check "></i> Blog and Newsletters</li>
                                            <li><i class="fa fa-check "></i> Customizable Squeeze Pages</li>
                                            <li><i class="fa fa-check "></i> Email/Text Campaigns</li>
                                            <li><i class="fa fa-check "></i> Forms</li>
                                            <li><i class="fa fa-check "></i> E-Signature & Templates</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <div class="team-item">
                                        <div class="arrow-main "><i class="fa fa-check "></i></div>
                                        <div class="designation text-center ">
                                            <h4>Elite Plan</h4>
                                            <p class="mb-0 font-20">$399/month | $199/yearly </p>
                                        </div>
                                        <ul class="info text-left">
                                            <li><i class="fa fa-check "></i> 100% Commission- NO SPLITS!</li>
                                            <li><i class="fa fa-check "></i> SOAR 12 Week Training with Mentor/Coach
                                            </li>
                                            <li><i class="fa fa-check "></i> $199 per sales transaction (NO CAP)</li>
                                            <li><i class="fa fa-check "></i> 15% Lease Transaction Fee</li>
                                            <li><i class="fa fa-check "></i> E&O Included</li>
                                            <li><i class="fa fa-check "></i> Marketing Tools with Customer Support</li>
                                            <li><i class="fa fa-check "></i> Online Transaction Management System</li>
                                            <li><i class="fa fa-check "></i> Listing E-Tours and Fliers</li>
                                            <li><i class="fa fa-check "></i> YouTube Content</li>
                                            <li><i class="fa fa-check "></i> Open House Resources</li>
                                            <li><i class="fa fa-check "></i> E-Library</li>
                                            <li><i class="fa fa-check "></i> CRM with Drip Campaign</li>
                                            <li><i class="fa fa-check "></i> Agent Website (Personalized with Hosting)
                                            </li>
                                            <li><i class="fa fa-check "></i> A Property Website (With Hosting)</li>
                                            <li><i class="fa fa-check "></i> Blog and Newsletters</li>
                                            <li><i class="fa fa-check "></i> Customizable Squeeze Pages</li>
                                            <li><i class="fa fa-check "></i> Email/Text Campaigns</li>
                                            <li><i class="fa fa-check "></i> Forms</li>
                                            <li><i class="fa fa-check "></i> E-Signature & Templates</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <a [routerLink]="['/join-now','personal-info']" class="button button-primary button-white ">Join
                        Now</a>
                </div>


                <div class="col-md-12 ml-auto text-center p-t" id="how-do-we-compare" name="how-do-we-compare">
                    <div class="section-head mb-40 text-center">
                        <h2 data-title="">How Do We Compare?</h2>
                        <p class="mb-0">
                            {{'COMPARE.TEXT_1' | translate}}
                        </p>
                        <p class="mb-0">
                            {{'COMPARE.TEXT_2' | translate}}
                        </p>
                        <p class="mb-0">
                            {{'COMPARE.TEXT_3' | translate}}
                        </p>
                        <p class="mb-0">
                            {{'COMPARE.TEXT_4' | translate}}
                        </p>
                        <h4>{{'COMPARE.TEXT_5' | translate}} </h4>
                        <p>
                            {{'COMPARE.TEXT_6' | translate}}
                        </p>
                    </div>
                </div>
                <div class="col-md-12 text-center mt-50 table-responsive">
                    <table class="table">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">{{'COMPARE.TITLE_1' | translate}}</th>
                                <th scope="col">{{'COMPARE.TITLE_2' | translate}}</th>
                                <th scope="col">{{'COMPARE.TITLE_3' | translate}}</th>
                                <th scope="col">{{'COMPARE.TITLE_4' | translate}}</th>
                                <th scope="col">{{'COMPARE.TITLE_5' | translate}}</th>
                                <th scope="col">{{'COMPARE.TITLE_6' | translate}}</th>
                                <th scope="col">{{'COMPARE.TITLE_7' | translate}}</th>
                                <th scope="col">{{'COMPARE.TITLE_8' | translate}}</th>
                            </tr>
                        </thead>
                        <tr>
                            <td>Broker A</td>
                            <td>70/30%</td>
                            <td>???</td>
                            <td>???</td>
                            <td>???</td>
                            <td>???</td>
                            <td>$18,900</td>
                            <td>$44,100</td>
                        </tr>
                        <tr>
                            <td>Broker B</td>
                            <td>60/40%</td>
                            <td>???</td>
                            <td>???</td>
                            <td>???</td>
                            <td>???</td>
                            <td>$25,200</td>
                            <td>$37,800</td>
                        </tr>
                        <tr>
                            <td>{{'COMPARE.TEXT_COL' | translate}}</td>
                            <td>100%</td>
                            <td>$399</td>
                            <td>$199</td>
                            <td>$199</td>
                            <td>NONE</td>
                            <td>$7,375</td>
                            <td>$55,625</td>
                        </tr>
                    </table>
                </div>

                <div class="col-md-12 ml-auto text-center mt-50">
                    <div class="section-head mb-40 text-center">
                        <p>
                            {{'COMPARE.TEXT_7' | translate}}
                        </p>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section> -->

<!-- <section id="property" class="property-layout-one nobg-border pa-100 pt-40">
    <div class="property-list-one">
        <div class="container">
            <div class="row">
                <div class="col-md-12 pt-spc-0">
                    <div class="section-head listing">
                        <h2 data-title="">{{ 'ESTATE_LICENSE.HEADING' | translate }}</h2>
                        <p class="mb-0">{{ 'ESTATE_LICENSE.TEXT_1' | translate }}</p>
                        <h3>{{ 'ESTATE_LICENSE.HEAD_1' | translate }}</h3>
                        <p>{{ 'ESTATE_LICENSE.CONTENT_11' | translate }}</p>
                        <h3>{{ 'ESTATE_LICENSE.HEAD_2' | translate }}</h3>
                        <p>{{ 'ESTATE_LICENSE.CONTENT_21' | translate }}</p>
                        <h3>{{ 'ESTATE_LICENSE.HEAD_3' | translate }}</h3>
                        <p>{{ 'ESTATE_LICENSE.CONTENT_31_1' | translate }} <a href="https://www.trec.texas.gov/">{{
                                'ESTATE_LICENSE.LINK_1' | translate }}</a>. {{ 'ESTATE_LICENSE.CONTENT_31_2' | translate
                            }}</p>
                        <p>{{'ESTATE_LICENSE.CONTENT_32' | translate}} </p>
                        <p> {{'ESTATE_LICENSE.TEXT_21' | translate}} <a href="https://www.trec.texas.gov/">TREC</a>
                            {{'ESTATE_LICENSE.TEXT_22' | translate}}</p>



                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">{{ 'ESTATE_LICENSE.FIELD_1' | translate }}</th>
                                    <th scope="col">{{ 'ESTATE_LICENSE.FIELD_2' | translate }}</th>
                                </tr>
                            </thead>
                            <tr>
                                <td>{{'ESTATE_LICENSE.VALUE_1' | translate}}</td>
                                <td>$795.00 {{'ESTATE_LICENSE.DOLARES' | translate}}</td>
                            </tr>
                            <tr>
                                <td>{{'ESTATE_LICENSE.VALUE_2' | translate}}</td>
                                <td>$54.00 {{'ESTATE_LICENSE.DOLARES' | translate}}</td>
                            </tr>
                            <tr>
                                <td>{{'ESTATE_LICENSE.VALUE_3' | translate}}</td>
                                <td>$205.00 {{'ESTATE_LICENSE.DOLARES' | translate}}</td>
                            </tr>
                            <tr>
                                <td>{{'ESTATE_LICENSE.VALUE_4' | translate}}</td>
                                <td>$38.25 {{'ESTATE_LICENSE.DOLARES' | translate}}</td>
                            </tr>
                            <tr>
                                <td>{{'ESTATE_LICENSE.VALUE_5' | translate}}</td>
                                <td>$10.00 {{'ESTATE_LICENSE.DOLARES' | translate}}</td>
                            </tr>
                            <tr>
                                <td>{{'ESTATE_LICENSE.VALUE_6' | translate}}</td>
                                <td><b>$1102.25</b> {{'ESTATE_LICENSE.DOLARES' | translate}}</td>
                            </tr>

                        </table>

                        <p>{{'ESTATE_LICENSE.TEXT_3' | translate}} <a href="https://www.trec.texas.gov/">TREC’s</a>
                            {{'ESTATE_LICENSE.WEBSITE' | translate}}.
                        </p>
                        <p>{{'ESTATE_LICENSE.TEXT_4' | translate}}</p>
                        <h3>{{ 'ESTATE_LICENSE.HEAD_4' | translate }}</h3>
                        <p>{{'ESTATE_LICENSE.CONTENT_41' | translate}}</p>
                        <p>{{'ESTATE_LICENSE.CONTENT_42' | translate}}<br /> {{'ESTATE_LICENSE.CONTENT_43' |
                            translate}}<br /> {{'ESTATE_LICENSE.CONTENT_44' | translate}}</p>
                        <p>{{'ESTATE_LICENSE.CONTENT_45' | translate}}</p>
                        <h3> {{ 'ESTATE_LICENSE.HEAD_LAST' | translate }} </h3>
                        <p> {{ 'ESTATE_LICENSE.CONTENT_LAST_1' | translate }} <a
                                href="https://www.trec.texas.gov/">TREC</a>. {{ 'ESTATE_LICENSE.CONTENT_LAST_2' |
                            translate}}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section> -->

<!-- <section class="property-list-two pa-100 pt-40 gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-6 pt-spc-0 pb-3">
                <img src="/assets/img/home-1.jpg" alt="Arbrook Realty" class="img-fluid radius-10">
            </div>
            <div class="col-md-6 pt-spc-0 pb-3">
                <img src="/assets/img/home-3.jpg" alt="Arbrook Realty" class="img-fluid radius-10"
                    style="height: 600px; width: 100%;">
            </div>
            <div class="col-12 text-center">
                <a href="https://truvillionreacademy.com/" target="_blank" class="button button-primary mt-5 mb-0">Start
                    Learning</a>
            </div>
        </div>
    </div>
</section> -->

<!-- UNLOCK -->
<section id="unlock-section">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h3>{{ "UNLOCK.HEAD" | translate }}</h3>
        <div class="mt-3">
          <p class="lead">
            {{ "UNLOCK.INTRODUCTION_0" | translate }}
            <b style="font-weight: bold">{{
              "UNLOCK.INTRODUCTION_1" | translate
            }}</b>
            {{ "UNLOCK.INTRODUCTION_2" | translate }}
          </p>
        </div>
      </div>

      <div class="col-md-5 offset-md-1">
        <ul class="show-ul-dist mt-4">
          <li class="lead">{{ "UNLOCK.LI_1" | translate }}</li>
          <li class="lead">{{ "UNLOCK.LI_2" | translate }}</li>
          <li class="lead">{{ "UNLOCK.LI_3" | translate }}</li>
          <li class="lead">{{ "UNLOCK.LI_4" | translate }}</li>
          <li class="lead">{{ "UNLOCK.LI_5" | translate }}</li>
          <li class="lead">{{ "UNLOCK.LI_6" | translate }}</li>
          <li class="lead">{{ "UNLOCK.LI_7" | translate }}</li>
        </ul>

        <div class="mt-5">
          <a
            [routerLink]="['/join-now', 'personal-info']"
            class="mr-2 button button-primary btn-mobile-100 button-white mb-3"
            >LEARN MORE</a
          >
          <a
            [routerLink]="['/join-now', 'personal-info']"
            class="button button-primary btn-mobile-100 button-white mb-3"
            >JOIN NOW</a
          >
        </div>
      </div>
    </div>
  </div>
</section>

<!-- ABOUT -->
<section id="property" class="pa-100">
  <div class="container">
    <div class="row">
      <div class="col-md-6 pt-spc-0">
        <div class="section-head listing mb-40">
          <h3 data-title="">{{ "ABOUT.HEAD_1" | translate }}</h3>
          <p class="mb-2 lead">{{ "ABOUT.TEXT_1" | translate }}</p>
          <h3>{{ "ABOUT.HEAD_2" | translate }}</h3>
          <ul class="list-value">
            <li>{{ "ABOUT.LI_1" | translate }}</li>
            <li>{{ "ABOUT.LI_2" | translate }}</li>
            <li>{{ "ABOUT.LI_3" | translate }}</li>
          </ul>
          <a
            [routerLink]="['/about-us']"
            class="button button-primary button-white"
            >Learn More</a
          >
        </div>
      </div>
      <div class="col-md-6 pt-spc-0">
        <img
          src="/assets/img/property/about.jpeg"
          alt="Arbrook Realty"
          class="about-img"
        />
      </div>
    </div>
  </div>
</section>

<!-- ABOUT -->
<!-- <section id="property" class="property-layout-one pa-100">
    <div class="property-list-one">
        <div class="container">
            <div class="row">
                <div class="col-md-6 pt-spc-0">
                    <div class="section-head listing mb-40">
                        <h3 data-title="">{{'ABOUT.HEAD_1' | translate}}</h3>
                        <p class="mb-2 lead">{{'ABOUT.TEXT_1' | translate}}</p>
                        <h3>{{'ABOUT.HEAD_2' | translate}}</h3>
                        <ul class="list-value">
                            <li>{{'ABOUT.LI_1' | translate}}</li>
                            <li>{{'ABOUT.LI_2' | translate}}
                            <li>{{'ABOUT.LI_3' | translate}}</li>
                        </ul>
                        <a [routerLink]="['/about-us']" class="button button-primary button-white">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="property-sliding-section">
                <div class="video-play">
                    <a data-fancybox="Hero-video" href="https://www.youtube.com/watch?v=OQ6IKeXS5nE"
                        class="play-button-about"><i class="fas fa-play text-white"></i></a>
                </div>
                <div class="property-carousel property-slide-one">
                    <div class="property-slide">
                        <img src="/assets/img/property/about.jpeg" alt="Arbrook Realty" width="100%" height="800">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- New Events Section -->
<section class="pb-5" *ngIf="event">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2 data-title="">EVENTS</h2>
      </div>
      <div class="col-md-6 my-5">
        <img
          src="{{ URL }}/images/{{ event.image }}"
          alt="Arbrook Realty"
          class="img-fluid"
        />
      </div>
      <!-- <div class="col-md-6 pl-5  my-5">
                <ul class="show-ul-dist list-value">
                    <p class="lead">{{'EVENTS.TEXT_1' | translate}}</p>
                    <p class="lead">{{'EVENTS.TEXT_2' | translate}}</p>
                    <li class="lead">{{'EVENTS.LI_1' | translate}}</li>
                    <li class="lead">{{'EVENTS.LI_2' | translate}}</li>
                    <li class="lead">{{'EVENTS.LI_3' | translate}}</li>
                    <li class="lead">{{'EVENTS.LI_4' | translate}}</li>
                    <p></p>
                    <p class="lead">{{'EVENTS.TEXT_3' | translate}}</p>
                </ul>
            </div> -->
      <div class="col-md-6 pl-5 my-5">
        <div
          *ngIf="language == 'en'"
          class="img-fit-width"
          [innerHTML]="event.description"
        ></div>
        <div
          *ngIf="language == 'es'"
          class="img-fit-width"
          [innerHTML]="event.descriptionSpanish"
        ></div>
      </div>
      <div class="col-md-12 text-center">
        <a
          href="https://forms.gle/9BcDGW1XQAikuQ9w9"
          target="_blank"
          class="mr-5 button button-primary button-white mb-3 btn-mobile-100"
          >Register Now</a
        >
        <a
          [routerLink]="['/events']"
          class="button button-primary button-white mb-3 btn-mobile-100"
          >More Events</a
        >
      </div>
    </div>
  </div>
</section>

<!-- Events -->
<!-- <section class="pb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h2 data-title="">EVENTS</h2>
            </div>
            <div class="col-md-6 my-5">
                <img src="/assets/img/events/CareerDayHorb.png" alt="Arbrook Realty" class="img-fluid">
            </div>
            <div class="col-md-6 pl-5  my-5">
                <ul class="show-ul-dist list-value">
                    <p class="lead">{{'EVENTS.TEXT_1' | translate}}</p>
                    <p class="lead">{{'EVENTS.TEXT_2' | translate}}</p>
                    <li class="lead">{{'EVENTS.LI_1' | translate}}</li>
                    <li class="lead">{{'EVENTS.LI_2' | translate}}</li>
                    <li class="lead">{{'EVENTS.LI_3' | translate}}</li>
                    <li class="lead">{{'EVENTS.LI_4' | translate}}</li>
                    <p></p>
                    <p class="lead">{{'EVENTS.TEXT_3' | translate}}</p>
                </ul>
            </div>
            <div class="col-md-12 text-center">
                <a href="https://forms.gle/9BcDGW1XQAikuQ9w9" target="_blank"
                    class="mr-5 button button-primary button-white mb-3 btn-mobile-100">Register Now</a>
                <a [routerLink]="['/events']" class="button button-primary  button-white  mb-3 btn-mobile-100">More Events</a>
            </div>
        </div>
    </div>
</section> -->

<!-- <section id="property" class="property-layout-one pa-100 pt-40">
    <div class="property-list-one">
        <div class="container">
            <div class="row">
                <div class="col-md-6 pt-spc-0">
                    <div class="section-head listing mb-40">
                        <h2 data-title="">{{'ABOUT.HEAD_1' | translate}}</h2>
                        <p class="mb-2">{{'ABOUT.TEXT_1' | translate}}</p>
                        <h3>{{'ABOUT.HEAD_2' | translate}}</h3>
                        <ul class="list-value">
                            <li>{{'ABOUT.LI_1' | translate}}</li>
                            <li>{{'ABOUT.LI_2' | translate}}</li>
                            <li>{{'ABOUT.LI_3' | translate}}</li>
                            <li>{{'ABOUT.LI_4' | translate}}</li>
                            <li>{{'ABOUT.LI_5' | translate}}</li>
                        </ul>
                        <h3>{{'ABOUT.HEAD_3' | translate}}</h3>
                        <p>{{'ABOUT.TEXT_1' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="property-sliding-section">
                <div class="property-carousel property-slide-one">
                    <div class="property-slide">
                        <img src="/assets/img/property/1.png" alt="Arbrook Realty" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <div id="video" class="video-layout-one pa-100 mt-40">
    <div class="video-carousel">
        <div class="video-tt">
            <div class="video-item">
                <img src="/assets/img/video/1.png" alt="Arbrook Realty" class="img-fluid">
                <div class="icons">
                    <a data-fancybox="Video" href="https://youtu.be/kmW54A4ibN0" class="playbutton">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px" y="0px" width="213.7px" height="213.7px" viewBox="0 0 213.7 213.7"
                            enable-background="new 0 0 213.7 213.7" xml:space="preserve">
                            <polygon class='triangle' id="XMLID_18_" fill="none" stroke-width="7" stroke-linecap="round"
                                stroke-linejoin="round" stroke-miterlimit="10" points="
                            73.5,62.5 148.5,105.8 73.5,149.1 " />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        <div class="video-tt">
            <div class="video-item">
                <img src="/assets/img/video/2.png" alt="Arbrook Realty" class="img-fluid">
                <div class="icons">
                    <a data-fancybox="Video" href="https://youtu.be/PDTLoJEzm-o" class="playbutton">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px" y="0px" width="213.7px" height="213.7px" viewBox="0 0 213.7 213.7"
                            enable-background="new 0 0 213.7 213.7" xml:space="preserve">
                            <polygon class='triangle' id="XMLID_19_" fill="none" stroke-width="7" stroke-linecap="round"
                                stroke-linejoin="round" stroke-miterlimit="10" points="
                            73.5,62.5 148.5,105.8 73.5,149.1 " />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        <div class="video-tt">
            <div class="video-item">
                <img src="/assets/img/video/3.png" alt="Arbrook Realty" class="img-fluid">
                <div class="icons">
                    <a data-fancybox="Video" href="https://youtu.be/kmW54A4ibN0" class="playbutton">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px" y="0px" width="213.7px" height="213.7px" viewBox="0 0 213.7 213.7"
                            enable-background="new 0 0 213.7 213.7" xml:space="preserve">
                            <polygon class='triangle' id="XMLID_20_" fill="none" stroke-width="7" stroke-linecap="round"
                                stroke-linejoin="round" stroke-miterlimit="10" points="
                            73.5,62.5 148.5,105.8 73.5,149.1 " />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        <div class="video-tt">
            <div class="video-item">
                <img src="/assets/img/video/5.png" alt="Arbrook Realty" class="img-fluid">
                <div class="icons">
                    <a data-fancybox="Video" href="https://youtu.be/PDTLoJEzm-o" class="playbutton">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px" y="0px" width="213.7px" height="213.7px" viewBox="0 0 213.7 213.7"
                            enable-background="new 0 0 213.7 213.7" xml:space="preserve">
                            <polygon class='triangle' id="XMLID_21_" fill="none" stroke-width="7" stroke-linecap="round"
                                stroke-linejoin="round" stroke-miterlimit="10" points="
                            73.5,62.5 148.5,105.8 73.5,149.1 " />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="video-arrow"></div>
</div> -->

<!-- <app-about-brokerage-service [showHeader]="false"></app-about-brokerage-service> -->
<app-testimonial></app-testimonial>

<!-- <section id="team " class="team-layout-one pa-100 ">
    <div class="container ">
        <div class="row mb-60 pt-40">
            <div class="col-md-12">
                <div class="section-head text-center">
                    <h2 data-title="">About CEO</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 align-self-center">
                <div class="about-left">
                    <img src="/assets/img/team/1.png" alt="Arbrook Realty" class="img-fluid">
                    <div class="video-play">
                        <a data-fancybox="Hero-video" href="https://www.youtube.com/watch?v=OQ6IKeXS5nE"
                            class="play-button"><i class="fas fa-play"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-8 about-ceo">
                <p>
                    {{'CEO.NAME' | translate}}<br /> <a href="tel:972-996-4702">{{'CEO.PHONE' | translate}}</a>
                    <br /> <a href="mailto:Roz@ArbrookRealty.com">{{'CEO.EMAIL' | translate}}</a>
                </p>
                <p>{{'CEO.TEXT_1' | translate}}</p>
                <p>{{'CEO.TEXT_2' | translate}}</p>
                <p>{{'CEO.TEXT_3' | translate}}</p>
                <p>{{'CEO.TEXT_4' | translate}}</p>
                <p>{{'CEO.TEXT_5' | translate}}</p>
                <p>{{'CEO.FOOTER_TEXT' | translate}}</p>

            </div>
        </div>
    </div>
</section> -->
<app-faq></app-faq>
