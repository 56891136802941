import { Component } from '@angular/core';
import { DataService } from './data.service';
import { SocketService } from './socket.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Arbrook Realty';

  customer : any;

  constructor(
    private dataService: DataService,
    private socketService: SocketService,
    private translate : TranslateService,
  ) {}

  ngOnInit(): void {


    this.translate.addLangs(['en', 'es']);  
    if (localStorage.getItem('AR-Language')) {  
      const browserLang = localStorage.getItem('AR-Language');  
      this.translate.use(browserLang.match(/en|es/) ? browserLang : 'en');  
    } else {  
      localStorage.setItem('AR-Language', 'en');  
      this.translate.setDefaultLang('en');  
    }  

    this.customer = this.dataService.getClient();
    if(this.customer){
      this.socketService.socketEmit('C_IAMAVAILABEL',{customer : this.customer._id})  
    }
  }


}
