import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { environments } from '../environments';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  events : Array<any> = []
  loading : boolean = false
  URL : any = environments.APP_LIVE_URL

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.URL = environments.APP_LIVE_URL
    this.dataService.setTitle('Events')
    this.getEvents();
  }

  getEvents() {
    this.loading = true
    this.dataService.__post('/events',{skip : this.events.length})
    .subscribe(
      (success) => {
        this.loading = false
        this.events = success;
      },
      (error) => {
        this.loading = false
        this.dataService.showError( error,'Error');
      }
    )
  }

  loadMore() {
    this.loading = true
    this.dataService.__post('/events',{skip : this.events.length})
    .subscribe(
      (success) => {
        this.loading = false
        success.map((s) => {
          this.events.push(s)
        })
      },
      (error) => {
        this.loading = false
        this.dataService.showError( error,'Error');
      }
    )
  }

}
