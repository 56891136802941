import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { Property } from 'src/app/properties/property.model';
import { SocketService } from 'src/app/socket.service';
import { Agent } from '../agent.model';

@Component({
  selector: 'app-agent-details',
  templateUrl: './agent-details.component.html',
  styleUrls: ['./agent-details.component.css']
})
export class AgentDetailsComponent implements OnInit {


  @ViewChild('clientRegisterButton') clientRegisterButton: ElementRef<HTMLElement>;
  @ViewChild('supportTicketButton') supportTicketButton: ElementRef<HTMLElement>;
  @ViewChild('closeButton') closeButton: ElementRef<HTMLElement>;
  @ViewChild('closeSupportTicket') closeSupportTicket: ElementRef<HTMLElement>;


  customer: any

  toggleAppointment : boolean = false;
  properties : Array<Property> = [];
  agentID : string = '';
  agent : Agent;

  loading: boolean = false

  agentIDSubscription : Subscription;
  eventsSubject: Subject<void> = new Subject<void>();

  source : string = ''

  constructor(
    private dataService : DataService,
    private router : Router,
    private activatedRouter : ActivatedRoute,
    private socketService: SocketService
  ) { }

  ngOnInit(): void {
    
    this.agentIDSubscription = this.activatedRouter.params.subscribe(
      params => {
       this.agentID = params.id;
      //  this.loadAgent();
       this.loadProperties();
      }
    )

  }


  loadAgent(){
    this.dataService.__post('/agent',{agent : this.agentID})
    .subscribe(
      (agent : Agent) => {  
        this.agent = new Agent(agent);
        this.dataService.setTitle(this.agent.fullName())
      },
      (err) => {
        this.dataService.showError('Error',err);
      }
    )
  }

  loadProperties(isScroll = false){

    const data = {
      agent : this.agentID,
      skip : this.properties.length
    }

    this.loading = true
    
    this.dataService.__post('/properties',data)
    .subscribe(
      (properties : Array<any>) => {
        
        properties.map((property : Property) => {
          property.agent = new Agent(property.agent);
          this.properties.push(new Property(property));
        })

        this.loading = false

      }
    )

  }

  toggleChatWindow(){
    this.source = 'chat'
    if(!this.dataService.getClient() || this.dataService.getClient() == null){
      let el: HTMLElement = this.clientRegisterButton.nativeElement;
      el.click();
      return false;
    }
    this.customer = this.dataService.getClient();
    console.log(this.customer);
    if(this.customer){
      this.socketService.socketEmit('C_IAMAVAILABEL',{customer : this.customer._id})  
    }
    this.dataService.toggleChatWindow('AGENTID');
  }

  toggleVideoDialer() {
    this.source = 'videoCall'
    if(!this.dataService.getClient()){
      let el: HTMLElement = this.clientRegisterButton.nativeElement;
      el.click();
      return false;
    }
    this.router.navigate(['/video-call',this.agentID])
  }

  handleClientDetails(formData : any){
    
    this.dataService.__post('/user/register',formData.value)
    .subscribe(
      (success) => {
        this.dataService.setClient(success);
        let el: HTMLElement = this.closeButton.nativeElement;
        el.click();
        if(this.source == 'videoCall') {
           this.toggleVideoDialer() 
        } else {
          this.toggleChatWindow()
        }
      },
      (error) => {
        this.dataService.showError(error, 'Error')
      }
    )
    
  }

 

  emitEventToChild() {
    this.eventsSubject.next();
  }

  emitEventToSupportTicket() {
    let el: HTMLElement = this.supportTicketButton.nativeElement;
    el.click();
  }

  onSubmit(formData : any) {

    if(!this.agentID) return;

    formData.value.agent = this.agentID

    this.dataService.__post('/support-ticket/create', formData.value)
    .subscribe(
      (success) => {
        this.dataService.showSuccess('Successfully created', 'success')
        let el: HTMLElement = this.closeSupportTicket.nativeElement;
        el.click();
      },
      (error) => {
        this.dataService.showError(error, 'Error')
      }
    )

  }

}
