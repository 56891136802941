import { Component, Input, OnInit } from '@angular/core';
import { Property } from '../../property.model';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.css']
})
export class ContactDetailsComponent implements OnInit {

  @Input('property') property : Property;
  constructor( private dataService : DataService) { }

  ngOnInit(): void {
  }

   // Handel Contact Form
   handleContactForm(formData: any) {

    this.dataService.__post('/contact-us', formData.value)
    .subscribe(
      (success) => {
        this.dataService.showSuccess(success,'Success')
      },
      (error) => {
        this.dataService.showError(error,'Error')
      }
    )


  }

}
