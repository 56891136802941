import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { environments } from '../environments';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {

  id : any = false
  event : any = false
  loading : boolean = false
  URL : any = environments.APP_LIVE_URL
  language = 'en'

  constructor(
    private dataService : DataService,
    private route : ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle('Event')
    this.language = localStorage.getItem('AR-Language');
    this.route.params.subscribe(
      (params) => {
        this.id = params['id']
        this.getEvent()
      }
    )
  }

  getEvent() {
    this.loading = true
    this.dataService.__post('/event', { id: this.id })
    .subscribe(
      (success) => {
        this.loading = false
        this.event = success
      },
      (error) => {
        this.loading = false
        this.dataService.showError(error, 'Error')
      }
    )
  }

}
