import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  faqs : Array<any> = []

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.loadFAQ();
  }

  loadFAQ() {
    this.dataService.__post('/faq',{})
    .subscribe(
      (success) => {
        (success.length > 0) ? this.faqs.push(success[0]) : null;
        (success.length > 1) ? this.faqs.push(success[1]) : null;
        (success.length > 2) ? this.faqs.push(success[2]) : null;
        
      },
      (error) => {
        this.dataService.showError(error, 'Error')
      }
    )
  }

}
