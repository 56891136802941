<app-header-fix></app-header-fix>


<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">Property Listing</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Properties</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="assets/img/service/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>


<!-- 
 -->


<div class="property-main-content property-grid pa-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card-filter">
                    <form #filterForm="ngForm" (ngSubmit)="onFilterProperty(filterForm)" id="search" class="search-layout-one">
                        <div class="short-version" style="width: 100%;">
                            <div class="select">
                                <select name="property_for" ngModel>
                                    <option value="">All</option>
                                    <!-- <option value="buy">I'm Buying</option>
                                    <option value="sell">I'm Selling</option>
                                    <option value="rent">I'm Renting</option> -->
                                    <option value="Sale">Sale</option>
                                    <option value="Rent">Rent</option>
                                    <option value="Sold">Sold</option>
                                    <option value="Under Contract">Under Contract</option>
                                    <option value="Auction">Auction</option>
                                </select>
                            </div>
                            <div class="input">
                                <input type="text" placeholder="Enter Your Property Details" name="q">
                            </div>
                            <div class="view-button">
                                <a class="show-advance"><i class="fas fa-sliders-h"></i></a>
                            </div>
                           
                            <div class="submit-form">
                                <input [routerLink]="['/idx-search']" type="button" value="IDX Search" class="idx-search-btn">
                            </div>

                            <div class="submit-form">
                                <input type="submit" value="Search" name="submit">
                            </div>

                        </div>
                        <div class="advance-search">
                            <div class="row mb-30">
                                <div class="col-md-4">
                                    <h6>City</h6>
                                    <div class="select-two select-full">
                                        <select name="location" ngModel>
                                            <option value="CUSTOMER ADDRESS">Select City</option>
                                            <option value="New York">New York</option>
                                            <option value="California">California</option>
                                            <option value="New Jersey">New Jersey</option>
                                            <option value="Las Vegas">Las Vegas</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <h6>Price Min</h6>
                                    <input class="form-control" type="number" name="price_from" ngModel style="height: 50px;">
                                </div>
                                <div class="col-md-4">
                                    <h6>Price Max</h6>   
                                    <input class="form-control" type="number" name="price_to" ngModel style="height: 50px;">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <h6>Beds</h6>
                                    <div class="select-two select-full">
                                        <select name="total_beds" ngModel>
                                            <option value="0">Select Beds</option>
                                            <option value="2">2 Beds</option>
                                            <option value="3">3 Beds</option>
                                            <option value="4">4 Beds</option>
                                            <option value="5">5 Beds</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <h6>Bathrooms</h6>
                                    <div class="select-two select-full">
                                        <select name="total_bathrooms" ngModel>
                                            <option value="0">Select Bathrooms</option>
                                            <option value="1">1 Bathroom</option>
                                            <option value="2">2 Bathroom</option>
                                            <option value="3">3 Bathroom</option>
                                            <option value="4">4 Bathroom</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <h6>Kitchens</h6>
                                    <div class="select-two select-full">
                                        <select name="status" ngModel>
                                            <option value="0">Select Status</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                
                <div class="feature-layout-six no-background">
                    <div class="row mb-30">
                        <div class="col-md-4 mb-30" *ngFor="let property of properties">
                            <app-property-card [property]="property"> </app-property-card>
                        </div>
                    </div>

                     <!-- Empty Data -->
                    <div class="row"  *ngIf="properties.length == 0">
                        <div class="col-md-12 text-center mb-5">
                            <img src="/assets/img/empty-property.png" width="450px" alt="">
                            <br>
                            <h4> There are no any properties found!</h4>
                        </div>
                    </div>

                    <div class="text-center" *ngIf="properties.length != 0">
                        <a (click)="loadMoreProperties()" class="button button-primary button-icon text-white">
                            Load More <i class="fas fa-sync" ></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<section *ngIf="showChatWindow">
    <app-chat-window (toggleChatWindow)="toggleChatWindow($event)"></app-chat-window>
</section>

<section *ngIf="showVideoDialer">
    <app-video-dialer (toggleVideoDialer)="toggleVideoDialer($event)"></app-video-dialer>
</section>