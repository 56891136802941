import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { Property } from '../property.model';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environments } from 'src/app/environments';
import { Agent } from 'src/app/agents/agent.model';
// import { Subscription } from 'rxjs';

declare var $ : any;

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.css']
})
export class PropertyDetailsComponent implements OnInit {

  id: any = false
  property : Property;
  properties : Array<Property> = [];
  // agentID : string = '';
  // agentIDSubscription : Subscription;
  URL : any = environments.APP_LIVE_URL

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ["<i class='fa fa-chevron-left text-dark'></i>","<i class='fa fa-chevron-right text-dark'></i>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  thumb_customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ["<i class='fa fa-chevron-left  text-dark'></i>","<i class='fa fa-chevron-right text-dark'></i>"],
    responsive: {
      0: {
        items: 5
      },
      400: {
        items: 5
      },
      740: {
        items: 5
      },
      940: {
        items: 5
      }
    },
    nav: true,
    margin: 20
  }

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private activatedRouter : ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.URL = environments.APP_LIVE_URL

    this.route.params.subscribe(
      (params) => {
        this.id = params['id']
        this.getProperty();
      }
    )

    // this.agentIDSubscription = this.activatedRouter.params.subscribe(
    //   params => {
    //    this.agentID = params.id;
    //   //  this.loadAgent();
    //    this.loadProperties();
    //   }
    // )

    this.loadProperties();

    $('.property-gallery-slider').slick({slidesToShow:3,arrows:true,dots:false,autoplay:true,speed:1000,prevArrow:"<i class='flaticon-left-chevron'></i>",nextArrow:"<i class='flaticon-right-chevron'></i>",responsive:[{breakpoint:991,settings:{slidesToShow:2}},{breakpoint:767,settings:{arrows:false,slidesToShow:1}}]});
  }

  getProperty() {

    this.dataService.__post('/property',{property: this.id})
    .subscribe(
      (success) => {
        success.agent = new Agent(success.agent);
        this.property = new Property(success)
      },
      (error) => {
        this.dataService.showError(error, 'Error')
        this.router.navigate(['/properties'])
      }
    )

  }

  loadProperties(){

    const data = {
      // agent : this.agentID,
      skip : this.properties.length,
      limit : 3
    }

    
    this.dataService.__post('/properties',data)
    .subscribe(
      (properties : Array<any>) => {
        
        properties.map((property : Property) => {
          property.agent = new Agent(property.agent);
          this.properties.push(new Property(property));
        })

      }
    )

  }

}
