<header id="header" class="header-layout-nine unveiled-navigation">
    <div id="header-bottom">
        <div class="container">
            <div class="menuzord main-navigation" id="menuzord">
                <a [routerLink]="['/home']" class="menuzord-brand"><img src="/assets/img/logo.png" alt="Arbrook Realty"
                        data-rjs="3"></a>
                <div class="view-mobile mobile-offcanvas">
                    <a href="#" class="open-canvas off-canvas-btn"><img src="/assets/img/off-canvas.png"
                            alt="Arbrook"></a>
                </div>
                <app-header-links ></app-header-links>
            </div>
        </div>
    </div>
</header>