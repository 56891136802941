import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DataService } from '../data.service';
import { environments } from '../environments';

declare var $ : any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  event : any = false;
  URL : any = environments.APP_LIVE_URL
  language = 'en'
 
  constructor(
    private dataService : DataService,
  ) { }

  ngOnInit(): void {

    this.dataService.setTitle('Home');
    this.getEvent();
    this.language = localStorage.getItem('AR-Language');
    $('.video-carousel').slick({centerMode:true,slidesToShow:3,arrows:true,dots:false,appendArrows:".video-arrow",prevArrow:"<i class='flaticon-left'></i>",nextArrow:"<i class='flaticon-right'></i>",responsive:[{breakpoint:768,settings:{centerMode:true,slidesToShow:1}}]});
  }

  copyText(val: string){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.dataService.showSuccess("Copied!",'')
  }
  

  onFilter(formData: any) {

    var filter = [
      { property_for : formData.value.property_for },
      { address : formData.value.address },
      { price_from : formData.value.price_from },
      { price_to : formData.value.price_to },
      { area : formData.value.area },
      { q : formData.value.q },
      { total_beds : formData.value.total_beds },
      { total_bathrooms : formData.value.total_bathrooms },
      { status : formData.value.status },
    ]

   
    this.dataService.__post('/properties', {filter: filter})
    .subscribe(
      (success) => {
        console.log(success)
      },
      (error) => {
        console.log(error)
      }
    )

  }

  getEvent() {
    this.dataService.__post('/event/home-page',{})
    .subscribe(
      (success) => {
        this.event = success;
      },
      (error) => {
        this.event = false;
      }
    )
  }

}
