import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { WebcamInitError } from 'ngx-webcam';

@Component({
  selector: 'app-video-dialer',
  templateUrl: './video-dialer.component.html',
  styleUrls: ['./video-dialer.component.css']
})
export class VideoDialerComponent implements OnInit {

  isCalling : boolean = false;
  @Output() toggleVideoDialer:EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  closeChatWindow() {
    console.log('Hwere')
    this.toggleVideoDialer.emit(null)
  }

  doCall(){
    this.isCalling = true;
  }

  endCall(){
    this.isCalling = false;
  }


}
