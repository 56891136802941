<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
   <div class="container pa-50">
      <div class="row">
         <div class="col-md-6">
            <h3 class="mb-0">Consumer Protection Notice</h3>
         </div>
         <div class="col-md-6 align-self-center text-right">
            <ul class="list-inline">
               <li class="list-inline-item"><a href="#">Home</a></li>
               <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
               <li class="list-inline-item">Consumer Protection Notice</li>
            </ul>
         </div>
      </div>
   </div>
   <div class="img banner-position">
      <img src="/assets/img/about/single.png" alt="Arbrook Realty" class="img-fluid">
   </div>
</section>


<section id="feature-two" class="feature-layout-two pa-100">
   <div class="container">
      <div class="text-center mb-60">
         <h2 class="mb-0">Consumer Protection Notice</h2>
      </div>
      <div class="row">
         <div *ngIf="loading" class="col-md-12 text-center">
            <div class="center">
               <i class="fa fa-spin fa-spinner fa-2x"></i>
            </div>
         </div>
         <div *ngIf="customerProtectionNotice" class="col-md-12 text-center">
            <div class="img-fit-width" [innerHTML]='customerProtectionNotice.customerProtectionNotice'>
   
            </div>
         </div>
      </div>
   </div>

   

   <!-- <div class="row">

      <div class="col-md-6 offset-md-3 pt-5 pb-5 text-center">

         <h5>THE TEXAS REAL ESTATE COMMISSION (TREC) REGULATES </h5>
         <h5>
            REAL ESTATE BROKERS AND SALES AGENTS, REAL ESTATE INSPECTORS,
         </h5>
         <h5>HOMEWARRANTYCOMPANIES,EASEMENTANDRIGHT-OF-WAY AGENTS</h5>
         <h5> AND TIMESHARE INTERESTPROVIDERS</h5>

         <br>

         <h5 class="f-secondary">
            YOU CAN FIND MORE INFORMATION AND CHECK THE STATUS OF A LICENSE HOLDERAT
         </h5>

         <h3>WWW.TREC.TEXAS.GOV</h3>

         <div class="border-div"></div>

         <h5>YOU CAN SEND A COMPLAINT AGAINST A LICENSE HOLDER TO TREC</h5>

         <h5 class="f-secondary">
            A COMPLAINT FORM IS AVAILABLE ON THE TREC WEBSITE
         </h5>

         <div class="border-div"></div>
         
         <h5>TREC ADMINISTERS TWO RECOVERY FUNDS WHICH MAY BE USED TO SATISFY A CIVIL COURT JUDGMENT AGAINST A BROKER, SALES AGENT, REAL ESTATE INSPECTOR, OR EASEMENT OR RIGHT-OF-WAY AGENT,
            IF CERTAIN REQUIREMENTS ARE MET
         </h5>
         
         <div class="border-div"></div>

         <h5>IFYOUHAVEQUESTIONSORISSUESABOUTTHEACTIVITIESOF A LICENSE HOLDER, THE COMPLAINT PROCESS ORTHE
            RECOVERY FUNDS, PLEASE VISIT THE WEBSITE OR CONTACT TREC AT
         </h5>

         <div class="row mt-5">

            <div class="col-md-5 text-center">
               <img src="../../assets/img/trec.png" height="180px" width="200px" alt="">
            </div>
            <div class="col-md-7 mt-2 text-center">
               <h4>TEXAS REAL ESTATE COMMISSION
                  P.O. BOX 12188</h4>
               <h4>AUSTIN, TEXAS 78711-2188
                  (512) 936-3000</h4>
            </div>

         </div>


      </div>

   </div> -->


</section>