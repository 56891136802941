<div id="faq" class="faq-layout-one pa-100 pt-60" *ngIf="faqs.length != 0">
    <div class="container">
        <div class="row mb-60 ">
            <div class="col-md-12 ">
                <div class="section-head text-center">
                    <h2 data-title="">FAQ's</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="tab-content" id="faq-tab-content">
                <div class="tab-pane fade show active" id="property-content" role="tabpanel" aria-labelledby="property-tab">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="admin-image">
                                <img src="/assets/img/faq/1.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="accordion" id="property-accordion"  *ngFor="let faq of faqs; let i = index;">
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" [attr.data-target]="'#a'+ i" aria-expanded="false" aria-controls="collapseThree">
                                                {{faq.question}} <i class="flaticon-right"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="a{{i}}" class="collapse" aria-labelledby="headingThree" data-parent="#property-accordion">
                                        <div class="card-body" innerHTML="{{faq.answer}}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faq-btn">
                            <a class="button button-primary button-white" [routerLink]="['/faqs']">View More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>