import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle('Contact Us')
  }


  // Handel Contact Form
  handleContactForm(formData: any) {

    this.dataService.__post('/contact-us', formData.value)
    .subscribe(
      (success) => {
        this.dataService.showSuccess(success,'Success')
      },
      (error) => {
        this.dataService.showError(error,'Error')
      }
    )


  }

}
