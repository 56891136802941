<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">Join Now</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Join Now</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="img banner-position">
        <img src="assets/img/service/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>

</section>

<section id="feature-two" class="feature-layout-two pb-100 pt-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <form>
                    <router-outlet></router-outlet>
                    <!-- <mat-vertical-stepper labelPosition="bottom" #stepper>
                        <mat-step>
                            <ng-template matStepLabel>Personal Information</ng-template>
                            <div class="row">
                                <div class="col-md-6 offset-md-3">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="firstName">First Name</label>
                                                <input type="text" class="form-control" id="firstName">
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="lastName">Last Name</label>
                                                <input type="text" class="form-control" id="lastName">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="email">Email Address</label>
                                        <input type="email" class="form-control" id="email">
                                    </div>

                                    <div class="form-group">
                                        <label for="contactNumber">Contact Number</label>
                                        <input type="text" class="form-control" id="contactNumber">
                                    </div>

                                    <div class="form-group">
                                        <label for="password">Password</label>
                                        <input type="password" class="form-control" id="password">
                                    </div>

                                    <div class="form-group">
                                        <label for="cpassword">Confirm Password</label>
                                        <input type="password" class="form-control" id="cpassword">
                                    </div>
                                </div>
                            </div>

                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Agent Types</ng-template>
                            <div class="row">
                                <div class="col-md-6 offset-md-3">

                                    <div class="form-group">
                                        <label for="experience">Years of Experience</label>
                                        <input type="number" class="form-control" id="experience">
                                    </div>

                                    <div class="form-group">
                                        <label for="experience">Choose Agent Type</label>
                                        <select class="form-control" name="agentType" id="">
                                            <option value="" selected> Buyer Agent </option>
                                            <option value=""> Seller Agent </option>
                                            <option value=""> Seller Agent </option>
                                            <option value=""> Listing Agent </option>
                                            <option value=""> Property Management </option>
                                            <option value=""> Other </option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>License Information</ng-template>
                            <div class="row">
                                <div class="col-md-6 offset-md-3">

                                    <div class="form-group">
                                        <label for="licenseNumber">License Number</label>
                                        <input type="number" class="form-control" id="licenseNumber">
                                    </div>

                                    <div class="form-group">
                                        <label for="experience">License Status</label>
                                        <select class="form-control" name="licenseStatus" id="">
                                            <option value="" selected> Active </option>
                                            <option value=""> In-active </option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <label for="licenseExpiryDate">License Expiry Date</label>
                                        <input type="date" class="form-control" id="licenseExpiryDate">
                                    </div>

                                    <div class="form-group">
                                        <label for="licenseFile">Upload Your License</label>
                                        <input type="file" class="form-control" id="licenseFile">
                                    </div>

                                </div>
                            </div>

                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Upload Resume</ng-template>
                            <div class="row">
                                <div class="col-md-6 offset-md-3">

                                    <div class="form-group">
                                        <label for="licenseFile">Upload Your Resume</label>
                                        <input type="file" class="form-control" id="licenseFile">
                                    </div>

                                </div>
                            </div>

                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Choose Plan</ng-template>
                            <div class="row">
                                <div class="col-md-6">

                                    <div class="team-item">
                                        <div class="designation text-center">
                                            <h4><span class="pointer">Premium Plan</span></h4>
                                            <p class="mb-0">$249/month | $199/yearly</p>
                                        </div>
                                        <ul class="info">
                                            <li> <i class="fa fa-check"></i> 100% Commission- NO SPLITS!</li>
                                            <li><i class="fa fa-check"></i> $199 per sales transaction (NO CAP)</li>
                                            <li><i class="fa fa-check"></i> 15% Lease Transaction Fee</li>
                                            <li><i class="fa fa-check"></i> E&O Included</li>
                                            <li><i class="fa fa-check"></i> Marketing Tools with Customer Support</li>
                                            <li><i class="fa fa-check"></i> Online Transaction Management System</li>
                                            <li><i class="fa fa-check"></i> Listing E-Tours and Fliers</li>
                                            <li><i class="fa fa-check"></i> YouTube Content</li>
                                            <li><i class="fa fa-check"></i> Open House Resources</li>
                                            <li><i class="fa fa-check"></i> E-Library</li>
                                            <li><i class="fa fa-check"></i> CRM with Drip Campaign</li>
                                            <li><i class="fa fa-check"></i> Agent Website (Personalized with Hosting)
                                            </li>
                                            <li><i class="fa fa-check"></i> A Property Website (With Hosting)</li>
                                            <li><i class="fa fa-check"></i> Blog and Newsletters</li>
                                            <li><i class="fa fa-check"></i> Customizable Squeeze Pages</li>
                                            <li><i class="fa fa-check"></i> Email/Text Campaigns</li>
                                            <li><i class="fa fa-check"></i> Forms</li>
                                            <li><i class="fa fa-check"></i> E-Signature & Templates</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="team-item">

                                        <div class="designation text-center">
                                            <h4><span class="pointer">Elite Plan</span></h4>
                                            <p class="mb-0">$399/month | $199/yearly </p>
                                        </div>
                                        <ul class="info">
                                            <li><i class="fa fa-check"></i> 100% Commission- NO SPLITS!</li>
                                            <li><i class="fa fa-check"></i> SOAR 12 Week Training with Mentor/Coach</li>
                                            <li><i class="fa fa-check"></i> $199 per sales transaction (NO CAP)</li>
                                            <li><i class="fa fa-check"></i> 15% Lease Transaction Fee</li>
                                            <li><i class="fa fa-check"></i> E&O Included</li>
                                            <li><i class="fa fa-check"></i> Marketing Tools with Customer Support</li>
                                            <li><i class="fa fa-check"></i> Online Transaction Management System</li>
                                            <li><i class="fa fa-check"></i> Listing E-Tours and Fliers</li>
                                            <li><i class="fa fa-check"></i> YouTube Content</li>
                                            <li><i class="fa fa-check"></i> Open House Resources</li>
                                            <li><i class="fa fa-check"></i> E-Library</li>
                                            <li><i class="fa fa-check"></i> CRM with Drip Campaign</li>
                                            <li><i class="fa fa-check"></i> Agent Website (Personalized with Hosting)
                                            </li>
                                            <li><i class="fa fa-check"></i> A Property Website (With Hosting)</li>
                                            <li><i class="fa fa-check"></i> Blog and Newsletters</li>
                                            <li><i class="fa fa-check"></i> Customizable Squeeze Pages</li>
                                            <li><i class="fa fa-check"></i> Email/Text Campaigns</li>
                                            <li><i class="fa fa-check"></i> Forms</li>
                                            <li><i class="fa fa-check"></i> E-Signature & Templates</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Done</ng-template>
                            <p>You are now done.</p>
                            <div>
                                <button mat-button matStepperPrevious>Back</button>
                                <button mat-button (click)="stepper.reset()">Reset</button>
                            </div>
                        </mat-step>
                    </mat-vertical-stepper> -->

                </form>

            </div>
        </div>
    </div>
</section>