import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { Agent } from '../agent.model';
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-agent-card',
  templateUrl: './agent-card.component.html',
  styleUrls: ['./agent-card.component.css']
})
export class AgentCardComponent implements OnInit {


  @Input('agent') agent : Agent;

  constructor(
    private router: Router,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
  }

  onActiveAgent() {

    this.dataService.setActiveAgent(this.agent)
    window.location.href = "/agents/" + this.agent._id;
    // this.router.navigate(['/agents', this.agent._id])
  }

}
