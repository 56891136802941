import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-our-plans',
  templateUrl: './our-plans.component.html',
  styleUrls: ['./our-plans.component.css']
})
export class OurPlansComponent implements OnInit {
  
  activePlan = 'Premium Plan';
  isLoading : boolean = false;
  isDoneApplication : boolean = false;
  agent : any = {};

  premiumPlanType : string = 'Monthly'
  elitePlanType : string = 'Monthly'

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle('Our Plans')
    this.agent = JSON.parse(localStorage.getItem('JOIN_NOW_PERSONAL_INFO'))
  }

  submitApplicationForm(){

    var subscriptionType;

    (this.activePlan == 'Premium Plan') ? subscriptionType = this.premiumPlanType : subscriptionType = this.elitePlanType

    this.isLoading = true;
    this.dataService.__post('/agent/create/application-submit',{
      agent : this.agent._id,
      plan : this.activePlan,
      subscriptionType: subscriptionType
    })
    .subscribe(
      () => {
        this.isLoading = false;
        this.isDoneApplication = true; 
      },
      (error) => {
        this.dataService.showError(error,'Error');
        this.isLoading = false;
      }
    )

  }

}
