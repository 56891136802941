<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">Rent</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Rent</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="/assets/img/about/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>

<section id="feature-two" class="feature-layout-two pa-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="feature-item mb-4">
                    <p class="lead mb-0">{{ 'RENTER.INTRODUCTION' | translate }}</p>
                </div>
                <iframe src="https://matrix.ntreis.net/Matrix/public/IDX.aspx?idx=0cebfd9" width="100%" height="500px"
                    frameborder="0" marginwidth="0" marginheight="0"></iframe>
            </div>
        </div>
    </div>
</section>

<section id="feature-two" class="feature-layout-two pa-100">
    <div class="container">
        <div class="text-center mb-60">
            <h2 class="mb-0">{{'RENTER.TEXT_1' | translate}}</h2>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="feature-item">
                    <p class="lead">{{'RENTER.TEXT_2' | translate}}</p>
                    <p class="lead">{{'RENTER.TEXT_3' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="mb-40">{{'RENTER.R_TO_RENT' | translate}}</h2>
                <ul class="renter-list">
                    <li><b>{{'RENTER.RENT_CONTENT.HEAD_1' | translate}}</b>{{'RENTER.RENT_CONTENT.CONTENT_1' |
                        translate}} </li>
                    <li><b>{{'RENTER.RENT_CONTENT.HEAD_2' | translate}}</b>{{'RENTER.RENT_CONTENT.CONTENT_2' |
                        translate}} </li>
                    <li><b>{{'RENTER.RENT_CONTENT.HEAD_3' | translate}}</b>{{'RENTER.RENT_CONTENT.CONTENT_3' |
                        translate}} </li>
                    <li><b>{{'RENTER.RENT_CONTENT.HEAD_4' | translate}}</b>{{'RENTER.RENT_CONTENT.CONTENT_4' |
                        translate}} </li>
                    <li><b>{{'RENTER.RENT_CONTENT.HEAD_5' | translate}}</b>{{'RENTER.RENT_CONTENT.CONTENT_5' |
                        translate}} </li>
                    <li><b>{{'RENTER.RENT_CONTENT.HEAD_6' | translate}}</b>{{'RENTER.RENT_CONTENT.CONTENT_6' |
                        translate}} </li>

                </ul>
                <p>{{'RENTER.RENT_CONTENT.P_LAST' | translate}}</p>
                <!-- <a href="#" class="button button-primary">Book Now</a> -->
            </div>
        </div>
    </div>
</section>



<section class="pb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="mb-40">{{'RENTER.R_TO_BUY' | translate}}</h2>
                <ul class="renter-list">
                    <li><b>{{'RENTER.BUY_CONTENT.HEAD_1' | translate}}</b>{{'RENTER.BUY_CONTENT.CONTENT_1' | translate}}
                    </li>
                    <li><b>{{'RENTER.BUY_CONTENT.HEAD_2' | translate}}</b>{{'RENTER.BUY_CONTENT.CONTENT_2' | translate}}
                    </li>
                    <li><b>{{'RENTER.BUY_CONTENT.HEAD_3' | translate}}</b>{{'RENTER.BUY_CONTENT.CONTENT_3' | translate}}
                    </li>
                    <li><b>{{'RENTER.BUY_CONTENT.HEAD_4' | translate}}</b>{{'RENTER.BUY_CONTENT.CONTENT_4' | translate}}
                    </li>
                </ul>
                <p>{{'RENTER.BUY_CONTENT.P_LAST' | translate}}</p>
                <!-- <a href="#" class="button button-primary">Book Now</a> -->
            </div>
        </div>
    </div>
</section>

<div class="row mb-40">
    <div class="col-md-12 text-center">
        <a [routerLink]="['/agents']" class="button button-primary">Contact Agent</a>
    </div>
</div>