<ul class="menuzord-menu menuzord-right" [class.menuzord-menu-open]="showMenu">
  <li routerLinkActive="active" class="home-menu">
    <a [routerLink]="['/home']">
      <img src="/assets/img/logo-red-white.png" alt="" />
      <span>Home</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a [routerLink]="['/about-us']"> About Us</a>
  </li>
  <!-- <li routerLinkActive="active"><a [routerLink]="['/buy']"> Buy</a></li>
    <li routerLinkActive="active"><a [routerLink]="['/rent']"> Rent</a></li>
    <li routerLinkActive="active"><a [routerLink]="['/sell']"> Sell</a></li> -->
  <!-- <li routerLinkActive="active"><a routerLink="/home" fragment="how-do-we-compare"> How Do We Compare?</a></li> -->
  <!-- <li routerLinkActive="active"><a [routerLink]="['/agents']">Agents</a></li> -->
  <!-- <li routerLinkActive="active"><a [routerLink]="['/properties']">Property Search</a></li> -->
  <!-- <li routerLinkActive="active"><a [routerLink]="['/agents/properties']">Featured Listings</a></li> -->
  <li>
    <div class="dropdown">
      <div class="dropdown-toggle text-white m-0" style="padding: 13px 15px" type="button" id="recources"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Properties
      </div>
      <div class="dropdown-menu" aria-labelledby="properties">
        <a [routerLink]="['/properties']" class="dropdown-item menu_link">Property Search</a>
        <a [routerLink]="['/agents/properties']" class="dropdown-item menu_link">Featured Listings</a>
      </div>
    </div>
  </li>
  <!-- <li>
    <div class="dropdown">
      <div class="dropdown-toggle text-white m-0" style="padding: 13px 15px" type="button" id="recources"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Properties
      </div>
      <div class="dropdown-menu" aria-labelledby="recources">
        <a [routerLink]="['/buy']" class="dropdown-item menu_link">Buy</a>
        <a [routerLink]="['/rent']" class="dropdown-item menu_link">Rent</a>
        <a [routerLink]="['/sell']" class="dropdown-item menu_link">Sell</a>
        <a [routerLink]="['/community', communities[0]._id]" class="dropdown-item menu_link">Communities</a>
      </div>
    </div>
  </li> -->
  <li>
    <div class="dropdown">
      <div class="dropdown-toggle text-white m-0" style="padding: 13px 15px" type="button" id="recources"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Resources
      </div>
      <div class="dropdown-menu" aria-labelledby="recources">
        <!-- <a class="dropdown-item menu_link" *ngFor="let item of communities" [routerLink]="['/community', item._id]">{{
          item.title }}</a> -->
        <a [routerLink]="['/blogs']" class="dropdown-item menu_link">Blogs</a>
        <a [routerLink]="['/press']" class="dropdown-item menu_link">Press</a>
        <a [routerLink]="['/events']" class="dropdown-item menu_link">Events</a>
      </div>
    </div>
  </li>
  <li routerLinkActive="active"><a [routerLink]="['/join-arbrook-realty']">Join Arbrook Realty</a></li>
  <li><a [routerLink]="['/contact-us']">Contact Us</a></li>
</ul>

<div class="dropdown float-right">
  <ul class="menuzord-menu menuzord-right" [class.menuzord-menu-open]="showMenu">
    <li>
      <a href="/agent/login" class="border-0"> Agent Login</a>
    </li>
    <li>
      <a href="/join-arbrook-realty" class="border-0"> Join Us</a>
    </li>
    <li>
      <div class="dropdown-toggle text-white" type="button" id="dropdownMenuButton" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        Language
      </div>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" (click)="changeLanguage('en')">English</a>
        <a class="dropdown-item" (click)="changeLanguage('es')">Spanish</a>
      </div>
    </li>
  </ul>
</div>