<section class="chat-window" *ngIf="isChatOpen">
    <div class="container">
        <div class="header">
            <div class="info">
                <span class="status"></span>
                <span class="name">{{agent.firstName}} {{agent.lastName}}</span>
            </div>
            <div class="action">
                <i (click)="isChatOpen=false" class="fas fa-times"></i>
            </div>
        </div>
        <div class="chat">

                
                <div class="chat-bubble" *ngFor="let chat of chats" [class.chat-bubble--right]="chat.from == user._id">
                    <div class="timestamp">{{chat.created_at | date: 'hh:mm'}}</div>
                    <div class="message">
                        {{chat.message}}
                    </div>
                </div>
                
                <!-- <div class="chat-bubble chat-bubble--right">
                    <div class="timestamp">10:57</div>
                    <div class="message">
                        Lorem ipsum dolor sit amet!
                    </div>
                </div> -->
          

            </div>
            <div class="separator"></div>

            <div class="chat-input">
                <input #messageField type="text" placeholder="Enter your message">
                <button (click)="sendMessage(messageField)">Send</button>
            </div>
    </div>
</section>