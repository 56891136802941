import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.css']
})
export class BookAppointmentComponent implements OnInit {

  public appointmentDate = new Date();
  isOpen : boolean = false;
  private eventsSubscription: Subscription;


  @ViewChild('bookAppointmentButton') bookAppointmentButton: ElementRef<HTMLElement>;
  @ViewChild('closeButton') closeButton: ElementRef<HTMLElement>;
  @Input() toggle: Observable<void>;

  agent : any = false;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(
      (params) => {
        this.agent = params['id']
      }
    )

    this.eventsSubscription = this.toggle.subscribe(() => {
      this.isOpen = !this.isOpen;

      if(this.isOpen){
        let el: HTMLElement = this.bookAppointmentButton.nativeElement;
        el.click();
      } else {
        let el: HTMLElement = this.closeButton.nativeElement;
        el.click()
      }

    });

  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  onBookAppointment(formData : any){

    if(!this.agent) {
      return;
    }
    
    formData.value.agent = this.agent
    
    this.dataService.__post('/book-appointment', formData.value)
    .subscribe(
      (success) => {
        this.dataService.showSuccess('Successfully booked appointment','Success')
        let el: HTMLElement = this.closeButton.nativeElement;
        el.click()
      },
      (error) => {
        this.dataService.showError(error, 'Error')
      }
    )

  }

}
