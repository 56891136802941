import { environments } from "../environments";

import {Agent} from '../agents/agent.model'

export class Property {

    address : string;
    agent: Agent;
    amenities: string;
    created_at: string;
    description: string;
    main_image: string;
    property_for: string;
    property_images: Array<string> = [];
    property_features: Array<string> = [];
    property_name: string;
    property_type: string;
    HOA: string;
    HOA_dues: string;
    year_built: string;
    school_district: string;
    status: string;
    total_bathrooms: string;
    total_beds: string;
    price: string;
    property_size: string;
    property_youtube_video: string;
    property_plan_image: string;
    total_parking: number;
    total_rooms: string;
    updated_at: string;
    _id: string;

    constructor(init : Property){
        Object.assign(this,init);
    }


    getMainImage() : string {
        if(this.property_images.length == 0)  return '/assets/images/card-img.jpg';
        return environments.APP_LIVE_URL + '/' + this.property_images[0];
    }

    loadGallery() : Array<string> {
       
        var images : Array<string> = [];
       this.property_images.map(img => {
           images.push(environments.APP_LIVE_URL + '/' + img)
       });
       return images;
    }


}