<div class="row">
    <div class="col-md-12">
        {{'COMMUNITIES.INTRODUCTION' | translate}}
    </div>
    <div class="col-md-4 my-5" *ngFor="let community of communities">
        <div class="blog-item">
            <img src="{{URL}}/{{community.image}}" alt="" class="img-fulid" style="width: 100%;height: 300px;">
            <div class="content">
                <h4 class="pb-0 mb-0 text-theme my-3">{{community.title || ''}}</h4>
                <div *ngIf="language == 'en'" class="img-fit-width" [innerHTML]="community.communityDescription || ''" style="height: 80px;overflow: hidden;"></div>
                <div *ngIf="language == 'es'" class="img-fit-width" [innerHTML]="community.communityDescriptionSpanish || ''" style="height: 80px;overflow: hidden;"></div>
                <a [routerLink]="['/community', community._id]" class="button button-primary  btn-sm button-white mt-3">Learn
                    More</a>
            </div>
        </div>
    </div>
</div>