import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError, Subject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { Property } from './properties/property.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {


  chatWindowSubject  = new Subject<any>();

  constructor(
    private _http: HttpClient,
    private titleService: Title,
    private toastr: ToastrService
  ) {}



  toggleChatWindow(agent = null){
    this.chatWindowSubject.next(agent);
  }


  getChatWindowListener() : Observable<any> {
    return this.chatWindowSubject.asObservable();
  } 

  setTitle(title: string = 'Home', main : string = '| Arbrook Realty') {
    this.titleService.setTitle(`${title} ${main}`);
  }

  localUrl: string = 'http://localhost:8080/api/v1';
  liveUrl: string = 'https://arbrookrealty.com/api/v1';
  
  apiUrl: string =
    window.location.origin.match('localhost') !== null
      ? this.liveUrl
      : this.liveUrl;

  _response(response: any) {
    if (response.code !== 200) {
      throw new Error(response.data);
    }

    return response.data;
  }

  _handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage =
        error.message || 'Oops! Something went wrong, Please try again.';
    }
    return errorMessage;
  }

  __post(url: string, data: any) {
    return this._http.post(`${this.apiUrl}${url}`, data).pipe(
      map((response: any) => {
        return this._response(response);
      }),
      catchError((error: any) => {
        // this._toast.error(error)
        return throwError(this._handleError(error));
      })
    );
  }

  setTemp(keyName: string, value: any) {
    localStorage.setItem(keyName, value);
  }

  getTemp(keyName: string) {
    return localStorage.getItem(keyName);
  }

  setClient(data) {
    localStorage.setItem('ACTIVE_CLIENT', JSON.stringify(data));
  }

  getClient() {
    try {
      var client = localStorage.getItem('ACTIVE_CLIENT');
      if (!client) return null;
      return JSON.parse(client);
    } catch (error) {
      return null;
    }
  }

  addRecentProperty(property) {

    try {
      var properties : Array<Property> = []
      var data : Array<Property> = []
      var isExist : any = false
      data = JSON.parse(localStorage.getItem('recentProperties'));
      
      if(!data || data == null || data.length == 0) {
        try {
          properties.push(property)
        } catch (error) {
          console.log('here', error)
        }
        localStorage.setItem('recentProperties', JSON.stringify(properties))
        return;
      }
      
      if(data.length >= 5) {
        data.splice(0,1)
        data.push(property)
        localStorage.setItem('recentProperties', JSON.stringify(data))
      }
      
     
      isExist = data.find((p) => {
        return p._id == property._id
      })

      if(!isExist) {
        data.push(property)
        localStorage.setItem('recentProperties', JSON.stringify(data))
      }

    } catch (error) {
      return null;
    }

  }

  getRecentProperties() {
    try {
      var properties = localStorage.getItem('recentProperties');
      if (!properties) return null;
      return JSON.parse(properties);
    } catch (error) {
      return null;
    }
  }

  setActiveAgent(data) {
    localStorage.setItem('activeAgent', JSON.stringify(data));
  }

  getActiveAgent() {
    try {
      var agent = localStorage.getItem('activeAgent');
      if (!agent) return null;
      return JSON.parse(agent);
    } catch (error) {
      return null;
    }
  }

  showSuccess(title, message) {
    this.toastr.success(title, message);
  }

  showError(title, message) {
    this.toastr.error(title, message);
  }
}
