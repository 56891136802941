<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">News Letter</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">News Letter</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="/assets/img/about/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>

<section id="feature-two" class="feature-layout-two pa-50 pb-0">
    <div class="container">
        <div class="text-center mb-60">
            <h2 class="mb-0">Subscribe To News Letter</h2>
        </div>
    </div>
 
 </section>

<section id="newsletter" class="newsletter-layout-two dark-bg pa-100">
    <div class="container">
        <div class="row">
            <div class="col-md-10 mx-auto">
                <div class="section-head-three mb-60 text-center">
                    <p class="color-white">Newsletter & Subscribe</p>
                    <h2 class="mb-0 color-white">Contact or Subscribe Now to Get the Fastest Talking and Updates
                        with us?</h2>
                </div>
                <div class="subscribe-form">
                    <form #newsLetterForm="ngForm" (ngSubmit)="onSubmit(newsLetterForm)">
                        <input type="email" name="email" ngModel placeholder="Enter Your Email Address" required>
                        <button type="submit" [disabled]="!newsLetterForm.valid">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>