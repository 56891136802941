import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css']
})
export class PersonalInfoComponent implements OnInit {

  isLoading: boolean = false

  constructor(
    private dataService : DataService,
    private router : Router
  ) { }

  ngOnInit(): void {

    this.dataService.setTitle('Personal Information | Join Now');
  }


  handlePersonalInformation(data : any){
    
    try {
      
      const formValue = data.value;
      if(formValue.password !== formValue.conformPassword)  throw new Error('Password and confirm password not match!');
      
      this.isLoading = true
      
      this.dataService.__post('/agent/create/personal-details', formValue)
      .subscribe(
        (success) => {
          this.isLoading = false
          this.dataService.setTemp('JOIN_NOW_PERSONAL_INFO',JSON.stringify(success));
          this.router.navigate(['/join-now','agent-info']);
        },
        (error) => {
          console.log('Error', error)
          this.isLoading = false
        }
      )
      
      

    } catch (error) {
      this.dataService.showError(error.message,'Invalid Data');
    }

  }

}
