import { Component, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-header-fix',
  templateUrl: './header-fix.component.html',
  styleUrls: ['./header-fix.component.css']
})
export class HeaderFixComponent implements OnInit {

  constructor(
    private dataService: DataService
  ) { }

  showMenu : Subject<void> = new Subject<void>();

  ngOnInit(): void {
  }


  toggleMenu(){
    this.showMenu.next();

  }


}
