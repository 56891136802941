<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
  <div class="container pa-50">
    <div class="row">
      <div class="col-md-6">
        <h3 class="mb-0">IDX Listing</h3>
      </div>
      <div class="col-md-6 align-self-center text-right">
        <ul class="list-inline">
          <li class="list-inline-item"><a href="#">Home</a></li>
          <li class="list-inline-item">
            <i class="flaticon-right-chevron"></i>
          </li>
          <li class="list-inline-item">Properties</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="img banner-position">
    <img
      src="assets/img/service/single.png"
      alt="Arbrook Realty"
      class="img-fluid"
    />
  </div>
</section>

<div class="property-main-content property-grid pa-100">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="property-sidebar">
          <div class="sidebar-item item-one mb-30">
            <div class="sidebar-search">
              <h4>IDX Search</h4>

              <iframe
                src="https://matrix.ntreis.net/Matrix/public/IDX.aspx?idx=0cebfd9"
                width="100%"
                height="500px"
                frameborder="0"
                marginwidth="0"
                marginheight="0"
              ></iframe>
            </div>
            <div class="my-5 mx-4">
              <p>{{'SEARCH_IDX_DESCRIPTION' | translate}}</p>
            </div>
          </div>
          <div
            class="sidebar-item item-two"
            *ngIf="recentProperties != null && recentProperties.length != 0"
          >
            <h4>Recently Viewed</h4>
            <div
              class="recent-item d-flex mb-30"
              *ngFor="let property of recentProperties"
            >
              <div class="img">
                <img
                  src="{{ URL }}/{{ property.property_images[0] }}"
                  height="75px"
                  width="75px"
                  alt="{{ property.property_name }}"
                />
              </div>
              <div class="content align-self-center">
                <h6 class="mb-1">
                  <a [routerLink]="['/properties', property._id]">{{
                    property.property_name
                  }}</a>
                </h6>
                <p class="mb-0" *ngIf="property.price">${{ property.price }}</p>
                <p class="mb-0" *ngIf="!property.price">---</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
