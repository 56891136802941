<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">E-Learning</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">E-Learning</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="/assets/img/about/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>

<section id="feature-two" class="feature-layout-two pa-100">
    <div class="container">
        <div class="text-center mb-60">
            <h2 class="mb-0">Duis id anim consectetur sunt incididunt velit quis. <br> E-Learning</h2>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="feature-item text-center">
                    <img class="w-70" src="/assets/img/technology-hub.jpeg" alt="">
                    <h4>Technology Hub</h4>
                    <p class="mb-0">Ut sit laboris veniam consectetur veniam occaecat culpa sint.</p>
                    <br />
                    <div>
                        <a href="#" class="button button-primary button-white">Get Started</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="feature-item text-center">
                    <img class="w-70" src="/assets/img/CRM.png" alt="">
                    <h4>CRM</h4>
                    <p class="mb-0">Incididunt amet deserunt sit deserunt aliqua veniam non culpa dolore fugiat sunt do sint.
                    </p>
                    <br />
                    <div>
                        <a href="#" class="button button-primary button-white">Get Started</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="feature-item text-center">
                    <img class="w-70" src="/assets/img/solar.jpeg" alt="">
                    <h4>SOAR Agent Training</h4>
                    <p class="mb-0">Laborum adipisicing Lorem consectetur velit anim exercitation ullamco aliquip an.
                    </p>
                    <br />
                    <div>
                        <a href="#" class="button button-primary button-white">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>