import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.css']
})
export class PressComponent implements OnInit {

  press: any = false
  isLoading: any = true

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle('Press')
    this.getPress();
  }

  getPress() {
    this.isLoading = true
    this.dataService.__post('/press', {})
      .subscribe(
        (success) => {
          this.press = success
          this.isLoading = false
        },
        (error) => {
          this.dataService.showError(error, 'Error')
        }
      )
  }

}
