import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-agent-info',
  templateUrl: './agent-info.component.html',
  styleUrls: ['./agent-info.component.css']
})
export class AgentInfoComponent implements OnInit {

  isLoading: boolean = false
  licenceDocument : any = false
  agent : any = false

  constructor(
    private dataService : DataService,
    private router : Router
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle('Agent Details | Join Now');
    this.agent = JSON.parse(localStorage.getItem('JOIN_NOW_PERSONAL_INFO'))
  }

  handleFileChange(event) {
    this.licenceDocument = event.target.files[0];
    console.log('this.licenceDocument',this.licenceDocument)
  }

  handleAgentDetails(data){
   
    try {
      
      const formValue = data.value;
      formValue.agent = this.agent._id
      console.log('this.licenceDocument',this.licenceDocument)

      var _formData = new FormData()

      _formData.append('agent', this.agent._id)
      _formData.append('licenceDocument', this.licenceDocument)
      _formData.append('yearOfExperience', formValue.yearOfExperience)
      _formData.append('agentType', formValue.agentType)
      _formData.append('licenceNumber', formValue.licenceNumber)
      _formData.append('licenceExpiredDate', formValue.licenceExpiredDate)
      _formData.append('licenceStatus', formValue.licenceStatus)


      this.isLoading = true

      this.dataService.__post('/agent/create/agent-details', _formData)
      .subscribe(
        (success) => {
          this.isLoading = false
          this.dataService.setTemp('JOIN_NOW_AGENT_DETAILS',JSON.stringify(formValue));
          this.router.navigate(['/join-now','basic-question']);
        },
        (error) => {
          console.log('Error', error)
          this.isLoading = false
        }
      )

    } catch (error) {
      this.dataService.showError(error.message,'Invalid Data');
    }


  }

}
