<a id="top-button" href="#"><i class="fas fa-chevron-up"></i></a>


<!-- <div class="preloader-layout">
    <div class="cube-wrapper">
        <div class="cube-folding">
            <span class="leaf1"></span>
            <span class="leaf2"></span>
            <span class="leaf3"></span>
            <span class="leaf4"></span>
        </div>
        <span class="loading" data-name="loading">Loading</span>
    </div>
</div> -->




<router-outlet></router-outlet>

<app-footer></app-footer>