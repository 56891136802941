import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Agent } from '../agents/agent.model';
import { DataService } from '../data.service';
import { environments } from '../environments';

import {Property} from './property.model'

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css']
})
export class PropertiesComponent implements OnInit {


  properties : Array<Property> = [];
  loading : boolean = false

  showChatWindow: boolean = false;
  showVideoDialer: boolean = false;

  filter: any = null;

  property_for: any = 'Sale'
  address: any = 'CUSTOMER ADDRESS'
  price_from: any = 0
  price_to: any = 0
  area: any = 1
  total_beds : any = 0
  total_bathrooms : any = 0
  status : any = 0

  recentProperties: Array<Property>
  URL : any = environments.APP_LIVE_URL

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit(): void {
    this.URL = environments.APP_LIVE_URL
    this.dataService.setTitle('Properties');
    this.loadProperties();
    this.recentProperties = this.dataService.getRecentProperties()
  }

  
  loadProperties(isScroll = false){


    if(!isScroll){
      this.properties = [];
    }

   
      const data = {
        skip : this.properties.length,
        filter : this.filter
      }
    
      this.loading = true
    this.dataService.__post('/properties',data)
    .subscribe(
      (properties : Array<any>) => {
        
        properties.map((property : Property) => {
          property.agent = new Agent(property.agent);
          this.properties.push(new Property(property));
        })
        setTimeout(() => {},2000)
        this.loading = false
      }
    )

  }

  sort(val : any) {
    
    var data = {}

    if(val == 'newest') {
      data['sort'] =  { _id: -1 }
    }
    else if(val == 'oldest') {
      data['sort'] =  { _id: 1 }
    }

    this.properties = []

    this.dataService.__post('/properties',data)
    .subscribe(
      (properties : Array<any>) => {
        
        properties.map((property : Property) => {
          property.agent = new Agent(property.agent);
          this.properties.push(new Property(property));
        })

      }
    )
  }

  toggleChatWindow(event) {
    this.showChatWindow = !this.showChatWindow
  }

  onFilterProperty(data){
    this.filter = data.value;
    this.loadProperties(false);
  }

  loadMoreProperties(){
    this.loadProperties(true);
  }


  

}


