<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
  <div class="container pa-50">
    <div class="row">
      <div class="col-md-6">
        <h3 class="mb-0">Property Details</h3>
      </div>
      <div class="col-md-6 align-self-center text-right">
        <ul class="list-inline">
          <li class="list-inline-item">
            <a [routerLink]="['/agents/properties']">Properties</a>
          </li>
          <li class="list-inline-item">
            <i class="flaticon-right-chevron"></i>
          </li>
          <li class="list-inline-item">Property Details</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="img banner-position">
    <img
      src="/assets/img/about/single.png"
      alt="Arbrook Realty"
      class="img-fluid"
    />
  </div>
</section>

<section class="pt-60" *ngIf="property">
  <div class="container">
    <div class="row">
      <div class="col-md-6 mb-2">
        <p class="property-details-title">{{ property.property_name || "" }}</p>
      </div>
      <div
        class="col-md-6 text-right-res-left"
        *ngIf="property.price != null && property.price != ''"
      >
        <p class="property-details-title">${{ property.price | number }}</p>
      </div>
    </div>
  </div>
</section>

<div class="container" *ngIf="property">
  <div class="property-gallery-layout mt-3 property-main-caurosal">
    <div class="property-gallery-slider">
      <owl-carousel-o [options]="customOptions" class="main-caurosal">
        <ng-template carouselSlide *ngFor="let image of property.loadGallery()">
          <a data-fancybox="Property" href="{{ image }}">
            <img
              src="{{ image }}"
              alt="Arbrook Realty"
              class="main-caurosal-image"
            />
          </a>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

  <div class="property-gallery-layout mb-3 property-thumb-caurosal">
    <div class="property-gallery-slider">
      <owl-carousel-o [options]="thumb_customOptions">
        <ng-template carouselSlide *ngFor="let image of property.loadGallery()">
          <a data-fancybox="Property" href="{{ image }}">
            <img
              src="{{ image }}"
              alt="Arbrook Realty"
              class="thumb-caurosal-image"
            />
          </a>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>

<section *ngIf="property">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <!-- Discription -->
        <p class="property-details-title">Description</p>
        <div
          [innerHTML]="property.description"
          class="img-fit-width property-discription"
        ></div>

        <!-- Property Details -->
        <p class="property-details-title my-4">Property Details</p>
        <div class="row">
          <div class="col-md-6">
            <p class="property-details">
              <strong>Beds:</strong> {{ property.total_beds || "-" }}
            </p>
            <p class="property-details">
              <strong>Baths:</strong> {{ property.total_bathrooms || "-" }}
            </p>
            <p class="property-details">
              <strong>Parking:</strong> {{ property.total_parking || "-" }}
            </p>
            <p class="property-details">
              <strong>Sq Ft:</strong> {{ property.property_size || "-" }}
            </p>
            <!-- <p class="property-details">
              <strong>Living Area:</strong> {{ property.living_area || "-" }}
            </p>
            <p class="property-details">
              <strong>Dining Area:</strong> {{ property.dining_area || "-" }}
            </p> -->
          </div>
          <div class="col-md-6">
            <p class="property-details">
              <strong>Year Built:</strong> {{ property.build_year || "-" }}
            </p>
            <!-- <p class="property-details">
              <strong>Type:</strong> {{ property.type || "-" }}
            </p>
            <p class="property-details">
              <strong>Community:</strong> {{ property.community || "-" }}
            </p> -->
            <p class="property-details">
              <strong>HOA:</strong> {{ property.HOA || "-" }}
            </p>
            <p class="property-details">
              <strong>HOA Dues:</strong> {{ property.HOA_dues || "-" }}
            </p>
            <p class="property-details">
              <strong>School District:</strong>
              {{ property.school_district || "-" }}
            </p>
          </div>
        </div>

        <!-- Property Features -->
        <p
          class="property-details-title my-4"
          *ngIf="property.property_features?.length > 0"
        >
          Property Features
        </p>
        <div class="row" *ngIf="property.property_features?.length > 0">
          <div class="col-md-6" *ngFor="let item of property.property_features">
            <p class="property-features">{{ item }}</p>
          </div>
        </div>

        <!-- <div class="col-md-6">
            <p class="property-features">Split Bedrooms</p>
            <p class="property-features">Separate Shower</p>
            <p class="property-features">Walk-in Closet(s)</p>
            <p class="property-features">Granite Type Countertop</p>
            <p class="property-features">Kitchen Island</p>
            <p class="property-features">Walk-in Pantry</p>
            <p class="property-features">Water Line to Refrigerator</p>
            <p class="property-features">Fireplace</p>
            <p class="property-features">Drip/Dry Area</p>
            <p class="property-features">Separate Utility Room</p>
          </div>
          <div class="col-md-6">
            <p class="property-features">Carbon Monocside Detector(s)</p>
            <p class="property-features">Smoke Detector(s)</p>
            <p class="property-features">Dishwasher</p>
            <p class="property-features">Disposal</p>
            <p class="property-features">Electric Oven</p>
            <p class="property-features">Gas Cooktop</p>
            <p class="property-features">Gas Water Heater</p>
            <p class="property-features">Microwawe</p>
            <p class="property-features">Plumbed for Gas in Kitchen</p>
            <p class="property-features">Vented Exhaust Fan</p>
          </div>
        </div> -->

        <!-- View More Listings -->
        <div class="row feature-layout-six" *ngIf="properties.length > 0">
          <p class="col-md-12 property-details-title my-4">
            View More Listings
          </p>
          <div class="col-md-4 mb-30" *ngFor="let property of properties">
            <app-property-card [property]="property"></app-property-card>
          </div>
        </div>
        <!-- END -->
      </div>
      <div class="col-md-4 pl-5-res-15px">
        <app-contact-details
          *ngIf="property.agent"
          [property]="property"
        ></app-contact-details>
      </div>
    </div>
  </div>
</section>

<!-- <div class="property-header-content pt-86 mb-50" *ngIf="property">
    <div class="container pt-60">
        <div class="row">
            <div class="col-md-10 pl-0">
                <div class="heading-flex row">
                    <div class="col-md-8">
                        <h2>{{property.property_name}}</h2>
                        <ul class="list-inline">
                            <li class="list-inline-item"><i class="fas fa-map-marker-alt primary-color"></i> {{property.address}}</li>
                            <li class="list-inline-item heading-primary">Posted: {{property.created_at | date:'dd MMM yyyy'}}</li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <div class="tag-group">
                            <a href="#" class="tag-blue">{{property.property_for}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="property.price != null && property.price != '' " class="col-md-2 text-right pr-0">
                <h2 class="mt-20">${{property.price | number}}</h2>
            </div>
        </div>
        <div class="row mb-30">
            <div class="col-md-6 pl-0">
                <ul class="list-inline">
                    <li class="list-inline-item">Types: <span class="heading-primary">{{property.property_type}}</span></li>
                </ul>
            </div>
            <div class="col-md-6 justify-self-end text-right pr-0">
                <div class="content-middle">
                    <p class="mb-0 d-inline-block"><i class="flaticon-building pr-1 primary-color"></i> Rooms: {{property.total_rooms}} / Bed: {{property.total_beds}} / Bath: {{property.total_bathrooms}}</p>
                   
                </div>
            </div>
        </div>
    </div>
</div>
<div class="property-gallery-layout mb-60">
    <div class="property-gallery-slider">

        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let image of property.loadGallery()">
                <a data-fancybox="Property" href="{{image}}">
                    <img src="{{image}}" alt="Arbrook Realty">
                </a>
            </ng-template>  

        </owl-carousel-o>
        
    </div>
</div>
<div class="property-main-content pb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                
                <div class="single-item mb-30 intro-content">
                    <div class="heading">
                        <h4>Property v</h4>
                    </div>
                    <div [innerHTML]="property.description" class="img-fit-width mb-2"></div>
                </div>

                <div class="single-item detail-feature mb-30">
                    <h4>Details & Features</h4>
                    <div class="lists mb-30">
                        <ul class="detail-list">
                            <li><i class="fas fa-bed"></i> Beds: <span class="heading-primary">{{property.total_beds}}</span></li>
                            <li><i class="fas fa-bath"></i> Bath: <span class="heading-primary">{{property.total_bathrooms}}</span></li>
                            <li><i class="fas fa-door-open"></i> Rooms: <span class="heading-primary">{{property.total_rooms}}</span></li>
                        </ul>
                        <ul class="detail-list">
                            <li><i class="fas fa-home"></i> Built Year: <span class="heading-primary">{{property.build_year}}</span></li>
                            <li><i class="fas fa-car-alt"></i> Parking: <span class="heading-primary">{{property.total_parking}}</span></li>
                            <li><i class="fas fa-map"></i> Plot Size: <span class="heading-primary">{{property.property_size}}</span></li>
                        </ul>
                    </div>
                </div>
                <div class="single-item detail-video mb-30" *ngIf="property.property_youtube_video">
                    <h4>Propertis Video</h4>
                    <div class="video-item">
                        <img src="/assets/img/property/single-5.png" alt="Arbrook Realty" class="img-fluid">
                        <a data-fancybox="YouTube" href="{{property.property_youtube_video}}" class="youtube-icon"><i class="fab fa-youtube"></i></a>
                    </div>
                </div>
                <div class="single-item mb-30" *ngIf="property.property_plan_image">
                    <h4>Floor Plans</h4>
                    <div class="property-collapse">
                        <div class="collapse-item show">
                            <div class="content">
                                <img src="{{URL}}/{{property.property_plan_image}}" alt="Arbrook Realty" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="single-item mb-30">
                    <h4 class="pb-2">Map</h4>
                    <img src="/assets/img/map-detail-page.png" alt="" class="map-img">
                </div>
                
            </div>
        </div>
    </div>
</div> -->

<!-- <ul class="list-inline">
                        <li class="list-inline-item"><a href="#" class="favorite-feature"><i
                                    class="far fa-heart"></i></a></li>
                        <li class="list-inline-item"><a href="#" class="favorite-feature"><i
                                    class="fas fa-exchange-alt"></i></a></li>
                    </ul> -->
