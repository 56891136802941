import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-basic-question',
  templateUrl: './basic-question.component.html',
  styleUrls: ['./basic-question.component.css']
})

export class BasicQuestionComponent implements OnInit {

  agent : any = false
  isLoading: boolean = false
  isQuestionsDone : boolean =  false;
  questionNumber: number = 0;
  answer : string = '';


//How long have you been a licensed agent?

q : {
  "Q_1" : "How long have you been a licensed agent?",
  "Q_2" : "What state are you licensed in?",
  "Q_3" : "What other brokerages have you worked for? Please list",
  "Q_4" : "Why did or do you want to get into the real estate business?",
  "Q_5" : "On a scale of 1-10 (10 being Superior,) rate your skills using PC, software, and phone applications.",
  "Q_6" : "Do you have experience using video and virtual tours to market your home?",
  "Q_7" : "How much money do you want to make in your first year in the real estate business?",
  "Q_8" : "How much time are you prepared to invest in order to make that amount of money?",
  "Q_9" : "How will you attract new clients to our company?",
  "Q_10" : "What are the most effective advertising techniques?",
  "Q_11" : "How do you manage your appointments?",
  "Q_12" : "Describe your most challenging project so far. How did you deal with it?",
  "Q_13" : "What resources do you use to stay up-to-date with trends in the real estate market?",
  "Q_14" : "What do you struggle most as a real estate agent?"
}

  questions : Array<any> = [
    {
      label : 'QUESTIONS.Q_1',
      question : 'How long have you been a licensed agent?',
      answer : ''
    },
    {
      label : 'QUESTIONS.Q_2',
      question : 'What state are you licensed in?',
      answer : ''
    },
    {
      label : 'QUESTIONS.Q_3',
      question : 'What other brokerages have you worked for? Please list',
      answer : ''
    },
    {
      label : 'QUESTIONS.Q_4',
      question : 'Why did or do you want to get into the real estate business?',
      answer : ''
    },
    {
      label : 'QUESTIONS.Q_5',
      question : 'On a scale of 1-10 (10 being Superior,) rate your skills using PC, software, and phone applications.',
      answer : ''
    },
    {
      label : 'QUESTIONS.Q_6',
      question : 'Do you have experience using video and virtual tours to market your home?',
      answer : ''
    },
    {
      label : 'QUESTIONS.Q_7',
      question : 'How much money do you want to make in your first year in the real estate business?',
      answer : ''
    },
    {
      label : 'QUESTIONS.Q_8',
      question : 'How much time are you prepared to invest in order to make that amount of money?',
      answer : ''
    },
    {
      label : 'QUESTIONS.Q_9',
      question : 'How will you attract new clients to our company?',
      answer : ''
    },
    {
      label : 'QUESTIONS.Q_10',
      question : 'What are the most effective advertising techniques?',
      answer : ''
    },
    {
      label : 'QUESTIONS.Q_11',
      question : 'How do you manage your appointments?',
      answer : ''
    },
    {
      label : 'QUESTIONS.Q_12',
      question : 'Describe your most challenging project so far. How did you deal with it?',
      answer : ''
    },
    {
      label : 'QUESTIONS.Q_13',
      question : 'What resources do you use to stay up-to-date with trends in the real estate market?',
      answer : ''
    },
    {
      label : 'QUESTIONS.Q_14',
      question : 'What do you struggle most as a real estate agent',
      answer : ''  
    },
  ]


  constructor(
    private router : Router,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle('Basic Questions')
    this.agent = JSON.parse(localStorage.getItem('JOIN_NOW_PERSONAL_INFO'))
  }

  onNextQuestion() {

    if(this.answer.trim() == '') return false;

    this.questions[this.questionNumber].answer = this.answer
    this.answer = ''
    if(this.questionNumber < this.questions.length-1) {
      this.questionNumber += 1
    } else {
      this.isQuestionsDone = true
    }

  }

  onNext() {
    
    var data = {
      agent : this.agent._id,
      questions : this.questions
    }


    this.isLoading = true

      this.dataService.__post('/agent/create/agent-basic-questions', data)
      .subscribe(
        (success) => {
          this.isLoading = false
          this.router.navigate(['/join-now','uploads-info']);
        },
        (error) => {
          console.log('Error', error)
          this.isLoading = false
        }
      )

    // this.router.navigate(['/join-now','uploads-info']);
  }

}
