<header id="header" class="header-layout-nine unveiled-navigation">
    <div id="header-bottom" class="header-fix">
        <div class="">
            <div class="menuzord main-navigation" id="menuzord">
                <a [routerLink]="['/home']" class="menuzord-brand"><img src="/assets/img/logo.png" alt="Arbrook Realty" data-rjs="3"></a>
                <div class="view-mobile mobile-offcanvas">
                    <a (click)="toggleMenu()" class="open-canvas off-canvas-btn"><img src="/assets/img/off-canvas.png" alt="Arbrook Realty"></a>
                </div>
                <div routerLinkActive="active" class="btn-agent">
                    <a [routerLink]="['/join-now','personal-info']">Become An Arbrook Agent</a>
                </div>

            </div>
        </div>
        <div id="header-bottom-new" class="navigation-main">
            <app-header-links [isMenuToggle]="showMenu.asObservable()" ></app-header-links>
        </div>
    </div>

</header>