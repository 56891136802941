<form #agentDetails="ngForm" (ngSubmit)="handleAgentDetails(agentDetails)">

    <div class="steps step2">
        <h1>Agent Type</h1>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="experience">Years of Experience</label>
                            <input type="number" class="form-control" id="experience" name="yearOfExperience" required ngModel>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="experience">Choose Agent Type</label>
                            <select class="form-control" name="agentType" required ngModel>
                                <option value="Buyer" selected> Buyers Agent </option>
                                <option value="Listing"> Listing Agent </option>
                                <option value="Other"> Other </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="steps step3">
        <h1>Real Estate License Information</h1>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="licenseNumber">License Number</label>
                            <input type="number" class="form-control" id="licenseNumber" name="licenceNumber" required ngModel>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="experience">License Status</label>
                            <select class="form-control" name="licenceStatus" required ngModel>
                                <option value="Active" selected> Active </option>
                                <option value="Deactivate"> Inactive </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="licenseExpiryDate">License Expiry Date</label>
                            <input type="date" class="form-control" id="licenseExpiryDate" name="licenceExpiredDate" required ngModel>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="licenseFile">Upload Your License</label>
                            <input type="file" class="form-control" id="licenseFile" name="licenceDocument" (change)="handleFileChange($event)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="steps-final-buttons">
        <a routerLink="/join-now/personal-info"><i class="fa fa-arrow-left"></i> Previous</a>
        <button [disabled]="!agentDetails.valid" type="submit"> <i *ngIf="isLoading" class="fas fa-spinner fa-spin"></i>Next <i class="fa fa-arrow-right"></i></button>
    </div>

</form>