import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HttpClientModule, HttpClient } from '@angular/common/http'



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultComponent } from './layouts/default/default.component';
import { AgentsHomeComponent } from './agents/agents-home/agents-home.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { PlansComponent } from './agents/plans/plans.component';
import { WhyJoinComponent } from './agents/why-join/why-join.component';
import { HeaderFixComponent } from './layouts/header-fix/header-fix.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ELearningComponent } from './agents/e-learning/e-learning.component';
import { JoinNowComponent } from './agents/join-now/join-now.component';

import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { PropertiesComponent } from './properties/properties.component';
import { PropertyDetailsComponent } from './properties/property-details/property-details.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { HeaderLinksComponent } from './layouts/header-links/header-links.component';
import { AgentComponent } from './home/agent/agent.component';
import { BuyerComponent } from './home/buyer/buyer.component';
import { SellerComponent } from './home/seller/seller.component';
import { RentelComponent } from './home/rentel/rentel.component';
import { BlogCardComponent } from './cards/blog-card/blog-card.component';
import { FaqComponent } from './faq/faq.component';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { PropertyCardComponent } from './cards/property-card/property-card.component';


import { TooltipModule } from 'ng2-tooltip-directive';
import { ChatWindowComponent } from './chat-window/chat-window.component';
import { PersonalInfoComponent } from './join-now/personal-info/personal-info.component';
import { AgentInfoComponent } from './join-now/agent-info/agent-info.component';
import { UploadsInfoComponent } from './join-now/uploads-info/uploads-info.component';
import { BasicQuestionComponent } from './join-now/basic-question/basic-question.component';
import { OurPlansComponent } from './join-now/our-plans/our-plans.component';
import { ToastrModule } from 'ngx-toastr';
import { VideoDialerComponent } from './video-call/video-dialer/video-dialer.component';
import { VideoCallingComponent } from './video-call/video-calling/video-calling.component';


import {WebcamModule} from 'ngx-webcam';
import { AgentsComponent } from './agents/agents.component';
import { AgentCardComponent } from './agents/agent-card/agent-card.component';

// import { ChatbotRasaModule } from 'angular-chat-widget-rasa';

import { OwlDateTimeModule,OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { AgentDetailsComponent } from './agents/agent-details/agent-details.component';
import { BookAppointmentComponent } from './appointment/book-appointment/book-appointment.component';
import { FaqViewMoreComponent } from './faq/faq-view-more/faq-view-more.component';

import { FacebookModule } from 'ngx-facebook';
import { ConsumerFraudAlertComponent } from './consumer-fraud-alert/consumer-fraud-alert.component';
import { AboutBrokerageServiceComponent } from './about-brokerage-service/about-brokerage-service.component';
import { ConsumerProtectionNoticeComponent } from './consumer-protection-notice/consumer-protection-notice.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SupportTicketComponent } from './support-ticket/support-ticket.component';
import { IdxSearchComponent } from './idx-search/idx-search.component';


import {TranslateModule,TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CommunityDetailComponent } from './community-detail/community-detail.component';
import { PressComponent } from './press/press.component';
import { EventsComponent } from './events/events.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { CommunitiesComponent } from './communities/communities.component';
import { PropertiesNewComponent } from './properties-new/properties-new.component';
import { JoinArbrookRealtyComponent } from './join-arbrook-realty/join-arbrook-realty.component';
import { HowToApplyComponent } from './how-to-apply/how-to-apply.component';
import { ContactDetailsComponent } from './properties/property-details/contact-details/contact-details.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const config: SocketIoConfig = { url: window.location.origin, options: {
  // 'transports': ['websocket']
} };

@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    AgentsHomeComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    PlansComponent,
    WhyJoinComponent,
    HeaderFixComponent,
    ContactUsComponent,
    AboutUsComponent,
    ELearningComponent,
    JoinNowComponent,
    PropertiesComponent,
    PropertyDetailsComponent,
    HowItWorksComponent,
    HeaderLinksComponent,
    AgentComponent,
    BuyerComponent,
    SellerComponent,
    RentelComponent,
    BlogCardComponent,
    FaqComponent,
    NewsletterComponent,
    BlogsComponent,
    BlogDetailsComponent,
    PropertyCardComponent,
    ChatWindowComponent,
    PersonalInfoComponent,
    AgentInfoComponent,
    UploadsInfoComponent,
    BasicQuestionComponent,
    OurPlansComponent,
    VideoDialerComponent,
    VideoCallingComponent,
    AgentsComponent,
    AgentCardComponent,
    AgentDetailsComponent,
    BookAppointmentComponent,
    FaqViewMoreComponent,
    ConsumerFraudAlertComponent,
    AboutBrokerageServiceComponent,
    ConsumerProtectionNoticeComponent,
    PrivacyPolicyComponent,
    SupportTicketComponent,
    IdxSearchComponent,
    CommunityDetailComponent,
    PressComponent,
    EventsComponent,
    EventDetailsComponent,
    TestimonialComponent,
    CommunitiesComponent,
    PropertiesNewComponent,
    JoinArbrookRealtyComponent,
    HowToApplyComponent,
    ContactDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    CarouselModule,
    TooltipModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    WebcamModule,
    SocketIoModule.forRoot(config),
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    FacebookModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
    // ChatbotRasaModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
