<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-6">
                <h3 class="mb-0">Agents</h3>
            </div>
            <div class="col-md-6 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">Agents</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img banner-position">
        <img src="/assets/img/about/single.png" alt="Arbrook Realty" class="img-fluid">
    </div>
</section>

<section id="feature-two" class="feature-layout-two pa-100">
    <div class="container">
        <div class="text-center mb-60">
            <h2 class="mb-0">Agents</h2>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="feature-item">
                    <p class="lead">Arbrook Realty is a full-service technology-driven real estate company based in the North Dallas/Fort Worth, Texas region. Our passionate commitment is to see clients and agents birth their dreams and build legacies through the ultimate
                        online real estate experience. </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="about" class="about-innar pa-100">
    <div class="container">
        <div class="row">
            <div class="col-md-6 align-self-center">
                <div class="about-left">
                    <img src="/assets/img/about/single-2.png" alt="Arbrook Realty" class="img-fluid view-mobile">
                    <div class="video-play">
                        <a data-fancybox="Hero-video" href="https://www.youtube.com/watch?v=Maz7qkLzwYw" class="play-button"><i class="fas fa-play"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <h2 class="mb-40">Our Values</h2>
                <p class="lead">The foundation of Arbrook Realty’s success is built on high ethics & many core values. Our top 3 core values: Integrity, Trust, and Honor. </p>
                <ul class="about-list">
                    <li>“Service” is our lifestyle, not a department.</li>
                    <li>Our goal is to exceed expectations with excellence. </li>
                    <li>We value family, fun, diversity, and support our communities.</li>
                    <li>We believe the attitude of gratitude is the bridge that makes dreams a reality.</li>
                </ul>
                <a href="#" class="button button-primary">Book Now</a>
            </div>
        </div>
    </div>
</section>


<section id="feature-two" class="feature-layout-two pa-100">
    <div class="container">
        <div class="text-center mb-60">
            <h2 class="mb-0">Our Difference </h2>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="feature-item">
                    <p class="lead">Arbrook Realty meets the demands of agents and client-attitudes and expectations. Our handle on technology and leveraging of data enables agents to deliver personalized & optimal online and offline professional client services. Our
                        VOP (Virtual Office Platform) is a unique workplace environment with agents who are highly innovative and tech-savvy. We virtually bring the ultimate real estate experience to every fingertip. </p>
                </div>
            </div>
        </div>
    </div>
</section>