<app-header-fix></app-header-fix>

<section id="hero" class="innar-hero pt-86">
    <div class="container pa-50">
        <div class="row">
            <div class="col-md-8">
                <h3 class="mb-0">How it Works</h3>
            </div>
            <div class="col-md-4 align-self-center text-right">
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="#">Home</a></li>
                    <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                    <li class="list-inline-item">How it Works</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="img"><img src="assets/img/how-work.png" alt="Arbrook Realty" class="img-fluid"></div>
</section>

<section id="feature-two" class="feature-layout-two pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="feature-item how-work">
                    <i class="flaticon-shower"></i>
                    <h1>Step 1</h1>
                    <h4>
                        Dolor pariatur duis ipsum id culpa
                    </h4>
                    <p class="mb-0">
                        Irure eiusmod consequat ea excepteur elit nostrud irure Irure eiusmod consequat excepteur.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="feature-item how-work">
                    <i class="flaticon-bed"></i>
                    <h1>Step 2</h1>
                    <h4>Dolor pariatur duis ipsum id culpa</h4>
                    <p class="mb-0">Irure eiusmod consequat ea excepteur elit nostrud irure Irure eiusmod consequat excepteur.</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="feature-item how-work">
                    <i class="flaticon-bike"></i>
                    <h1>Step 3</h1>
                    <h4>Dolor pariatur duis ipsum id culpa</h4>
                    <p class="mb-0">Irure eiusmod consequat ea excepteur elit nostrud irure Irure eiusmod consequat excepteur.</p>
                </div>
            </div>
        </div>
    </div>
</section>