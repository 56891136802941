import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-community-detail',
  templateUrl: './community-detail.component.html',
  styleUrls: ['./community-detail.component.css']
})
export class CommunityDetailComponent implements OnInit {

  id: any = '';
  community: any = false;
  language = 'en'

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.language = localStorage.getItem('AR-Language');
    this.route.params.subscribe(
      (params) => {
        this.id = params['id']
        this.getCommunity();
      }
    )
  }

  getCommunity() {

    this.dataService.__post('/community', { id: this.id })
      .subscribe(
        (success) => {
          this.community = success
          this.dataService.setTitle(success.title);
        },
        (error) => {
          this.dataService.showError(error, 'Error')
        }
      )

  }


}
