import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-support-ticket',
  templateUrl: './support-ticket.component.html',
  styleUrls: ['./support-ticket.component.css']
})
export class SupportTicketComponent implements OnInit {

  constructor(
    private dataService : DataService,
    private router: Router
  ) { }

  ngOnInit(): void {
    
  }

  onSubmit(formData : any) {
    this.dataService.setTitle("Support Ticket")
    this.dataService.__post('/support-ticket/create', formData.value)
    .subscribe(
      (success) => {
        this.dataService.showSuccess('Successfully created', 'success')
        this.router.navigate(['/home'])
      },
      (error) => {
        this.dataService.showError(error, 'Error')
      }
    )

  }

}
