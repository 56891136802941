import { ArgumentType } from '@angular/compiler/src/core';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { AgentDetailsComponent } from './agents/agent-details/agent-details.component';
import { AgentsHomeComponent } from './agents/agents-home/agents-home.component';
import { AgentsComponent } from './agents/agents.component';
import { ELearningComponent } from './agents/e-learning/e-learning.component';
import { JoinNowComponent } from './agents/join-now/join-now.component';
import { PlansComponent } from './agents/plans/plans.component';
import { WhyJoinComponent } from './agents/why-join/why-join.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { BlogsComponent } from './blogs/blogs.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AgentComponent } from './home/agent/agent.component';
import { BuyerComponent } from './home/buyer/buyer.component';
import { HomeComponent } from './home/home.component';
import { RentelComponent } from './home/rentel/rentel.component';
import { SellerComponent } from './home/seller/seller.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { AgentInfoComponent } from './join-now/agent-info/agent-info.component';
import { BasicQuestionComponent } from './join-now/basic-question/basic-question.component';
import { OurPlansComponent } from './join-now/our-plans/our-plans.component';
import { PersonalInfoComponent } from './join-now/personal-info/personal-info.component';
import { UploadsInfoComponent } from './join-now/uploads-info/uploads-info.component';
import { DefaultComponent } from './layouts/default/default.component';
import { PropertiesComponent } from './properties/properties.component';
import { PropertyDetailsComponent } from './properties/property-details/property-details.component';
import { VideoCallingComponent } from './video-call/video-calling/video-calling.component';
import { FaqViewMoreComponent } from './faq/faq-view-more/faq-view-more.component';
import { ConsumerFraudAlertComponent } from './consumer-fraud-alert/consumer-fraud-alert.component';
import { AboutBrokerageServiceComponent } from './about-brokerage-service/about-brokerage-service.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ConsumerProtectionNoticeComponent } from './consumer-protection-notice/consumer-protection-notice.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { SupportTicketComponent } from './support-ticket/support-ticket.component';
import { IdxSearchComponent } from './idx-search/idx-search.component';
import { CommunityDetailComponent } from './community-detail/community-detail.component';
import { PressComponent } from './press/press.component';
import { EventsComponent } from './events/events.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { PropertiesNewComponent } from './properties-new/properties-new.component';
import { JoinArbrookRealtyComponent } from './join-arbrook-realty/join-arbrook-realty.component';
import { HowToApplyComponent } from './how-to-apply/how-to-apply.component';

const routes: Routes = [

  {
    path: '',
    component: DefaultComponent,
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'home/agents',
        component: AgentComponent
      },
      {
        path: 'home/sellers',
        component: SellerComponent
      },
      {
        path: 'sell',
        component: SellerComponent
      },
      {
        path: 'home/buyers',
        component: BuyerComponent
      },
      
      {
        path: 'buy',
        component: BuyerComponent
      },
      {
        path: 'rent',
        component: RentelComponent
      },
      {
        path: 'home/renter',
        component: RentelComponent
      },
      {
        path: 'properties',
        component: PropertiesNewComponent
      },
      {
        path: 'idx-search',
        component: IdxSearchComponent
      },
      {
        path: 'blogs',
        component: BlogsComponent
      },
      {
        path: 'blogs/:slug',
        component: BlogDetailsComponent
      },
      {
        path: 'events',
        component: EventsComponent
      },
      {
        path: 'events/:id',
        component: EventDetailsComponent
      },
      {
        path: 'properties/:id',
        component: PropertyDetailsComponent
      },
      {
        path: 'how-it-work',
        component: HowItWorksComponent
      },
      {
        path: 'consumer-fraud-alert',
        component: ConsumerFraudAlertComponent
      },
      {
        path: 'about-brokerage-service',
        component: AboutBrokerageServiceComponent
      },
      {
        path: 'news-letter',
        component: NewsletterComponent
      },
      
      {
        path: 'support-ticket',
        component: SupportTicketComponent
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'consumer-protection-notice',
        component: ConsumerProtectionNoticeComponent
      },
      {
        path: 'agents',
        component: AgentsComponent
      },
      {
        path: 'why-join',
        component: WhyJoinComponent
      },
      {
        path: 'join-arbrook-realty',
        component: JoinArbrookRealtyComponent
      },
      {
        path: 'how-to-apply',
        component: HowToApplyComponent
      },
      {
        path: 'join-now',
        component: JoinNowComponent,
        children:[
          {
            path: 'join-now',
            redirectTo: "/join-now/personal-info",
            pathMatch: "full"
          },
          {
            path: 'personal-info',
            component: PersonalInfoComponent
          },
          {
            path: 'agent-info',
            component: AgentInfoComponent
          },
          {
            path: 'uploads-info',
            component: UploadsInfoComponent
          },
          {
            path: 'basic-question',
            component: BasicQuestionComponent
          },
          {
            path: 'our-plans',
            component: OurPlansComponent
          }
        ]
      },
      {
        path: 'plans',
        component: PlansComponent
      },
      {
        path: 'contact-us',
        component: ContactUsComponent
      },
      {
        path: 'about-us',
        component: AboutUsComponent
      },
      {
        path: 'e-learning',
        component: ELearningComponent
      },
      {
        path : 'agents/properties',
        component : AgentDetailsComponent     
      },
      {
        path : 'agents/:id',
        component : AgentDetailsComponent     
      },
      {
        path : 'faqs',
        component : FaqViewMoreComponent     
      },
      {
        path : 'press',
        component : PressComponent     
      },
      {
        path: 'community/:id',
        component: CommunityDetailComponent
      },
    ]
  },
  {

    path : 'video-call/:agent',
    component : VideoCallingComponent

  }

];


@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
