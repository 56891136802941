<app-header-fix></app-header-fix>

<section>

    <!-- <section id="hero" class="innar-hero pt-86">
        <div class="container pa-50">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="mb-0">{{agent.fullName()}}</h3>
                </div>
                <div class="col-md-6 align-self-center text-right">
                    <ul class="list-inline">
                        <li class="list-inline-item"><a href="#">Home</a></li>
                        <li class="list-inline-item"><i class="flaticon-right-chevron"></i></li>
                        <li class="list-inline-item">Agent Details</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="img banner-position">
            <img src="/assets/img/team/single-2.png" alt="Agent Details" class="img-fluid">
        </div>
    </section> -->



    <!-- Model Button For Video Call -->
    <button style="display: none;" #clientRegisterButton type="button" data-toggle="modal"
        data-target="#chatOrVideoCallClient">xxx</button>
 
    <!-- Client Form -->
    <form class="steps" #clientDetailsForm="ngForm" (ngSubmit)="handleClientDetails(clientDetailsForm)">
        <div class="modal fade" id="chatOrVideoCallClient" tabindex="-1" role="dialog"
            aria-labelledby="chatOrVideoCallClientTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">

                        <div class="form-group">
                            <label>First Name</label>
                            <input name="name" type="text" class="form-control" id="name" required ngModel>
                        </div>

                        <div class="form-group">
                            <label>Your Email</label>
                            <input class="form-control" name="email" type="email" required ngModel>
                        </div>

                        <div class="form-group">
                            <label>Your Contact Number</label>
                            <input class="form-control" name="phoneNumber" type="text" required ngModel>
                        </div>
                        
                        <div class="form-group">
                            <label>Password</label>
                            <input class="form-control" name="password" type="password" required ngModel>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button #closeButton type="button" class="btn btn-secondary"
                            data-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-primary">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    
    <!-- support Ticket Form -->
    
    <button style="display: none;" #supportTicketButton type="button" data-toggle="modal"
        data-target="#supportTicketModal">xxx</button>

    <div class="modal fade" id="supportTicketModal" tabindex="-1" role="dialog"
        aria-labelledby="chatOrVideoCallClientTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <form #supportTicketForm="ngForm" (ngSubmit)="onSubmit(supportTicketForm)">
                <div class="modal-body">

            
                        <div class="form-group">
                            <label>Customer Name</label>
                            <input type="text" name="customer_name" class="form-control" ngModel required>
                        </div>
                        
                        <div class="form-group">
                            <label>Customer Email</label>
                            <input type="email" name="customer_email" class="form-control" ngModel required>
                        </div>
                        
                        <div class="form-group">
                            <label>Contact Number</label>
                            <input type="text" name="contact_number" class="form-control" ngModel required>
                        </div>
            
                        <div class="form-group">
                            <label>Subject</label>
                            <input type="text" name="subject" class="form-control" ngModel required>
                        </div>
            
                        <div class="form-group">
                            <label>Message</label>
                            <textarea name="message" class="form-control" ngModel required rows="3"></textarea>
                        </div>
            
                    </div>
                    <div class="modal-footer">
                        <button #closeSupportTicket type="button" class="btn btn-secondary"
                        data-dismiss="modal">Cancel</button>
                        <button type="submit" [disabled]="!supportTicketForm.valid" class="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>


    <!-- Book Appointment -->
    <app-book-appointment *ngIf="eventsSubject" [toggle]="eventsSubject.asObservable()"></app-book-appointment>

    <section id="team-single" class="team-single-short team-layout-two pa-100">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-4">
                    <div class="team-item">
                        <div class="img">
                            <img height="250px" [src]="agent.profileImage()" alt="Arbrook Realty">
                        </div>
                        <div class="designation text-center">
                            <h4><a>{{agent.fullName()}}</a></h4>
                        </div>
                        <ul class="info">
                            <li> <i class="fas fa-phone primary-color"></i> {{agent.phoneNumber}}</li>
                            <li> <i class="fas fa-envelope primary-color"></i> {{agent.email}}
                            </li>
                            <li>
                                <i class="fas fa-map-marker-alt primary-color"></i> {{agent.address}}
                            </li>

                            <li class="contact-with">

                                <label (click)="toggleChatWindow()" tooltip="Chat" placement="top"
                                    class="contact-icon chat">
                                    <span class="material-icons">
                                        message
                                    </span>
                                </label>

                                <label (click)="toggleVideoDialer()" tooltip="Video Call" placement="top"
                                    class="contact-icon video">
                                    <span class="material-icons">
                                        videocam
                                    </span>
                                </label>

                                <label (click)="emitEventToChild()" tooltip="Book Appointment" placement="top"
                                    class="contact-icon appointment">
                                    <span class="material-icons">
                                        calendar_today
                                    </span>
                                </label>
                                
                                <label (click)="emitEventToSupportTicket()" tooltip="Support Ticket" placement="top"
                                    class="contact-icon chat">
                                    <span class="material-icons">
                                        help
                                    </span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-5 card card-body">
                        <h3>Bio</h3>
                        <p>{{agent.bio}}</p>
                    </div>
                </div> -->
                <div class="col-md-12">
                    <div class="feature-layout-six top-more">
                        <div class="row">
                            <div class="col-md-12 px-5-res-15px">
                                <p class="properties-title">Featured Listings</p>
                            </div>
                            <div class="col-md-4 mb-30 px-5-res-15px" *ngFor="let property of properties">
                                <app-property-card [property]="property"></app-property-card>
                            </div>
                        </div>
                        <div class="row"  *ngIf="properties.length == 0 && !loading">
                            <div class="col-md-12 text-center mb-5">
                                <img src="/assets/img/empty-property.png" width="450px" alt="">
                                <br>
                                <h4> There are no any properties found!</h4>
                            </div>
                        </div>
                        <div class="text-center"  *ngIf="properties.length !== 0 && properties.length >= 10">
                            <a (click)="loadProperties(false)" class="button button-primary text-white">Load More <i class="fas fa-sync pl-2" ></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


</section>