import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { environments } from '../environments';

@Component({
  selector: 'app-idx-search',
  templateUrl: './idx-search.component.html',
  styleUrls: ['./idx-search.component.css']
})
export class IdxSearchComponent implements OnInit {

  recentProperties: Array<any> = []
  URL : any = environments.APP_LIVE_URL

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle("IDX Search")
    this.recentProperties = this.dataService.getRecentProperties()
    this.URL = environments.APP_LIVE_URL
  }

}
