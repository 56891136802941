import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { environments } from '../environments';

@Component({
  selector: 'app-communities',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.css']
})
export class CommunitiesComponent implements OnInit {

  communities: Array<any> = []
  URL: any = environments.APP_LIVE_URL
  language = 'en'

  constructor(private dataService: DataService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getCommunities();
    this.language = localStorage.getItem('AR-Language');
  }


  getCommunities() {

    this.dataService.__post('/communities', {})
      .subscribe(
        (success) => {
          this.communities = success
        },
        (error) => {
          this.dataService.showError(error, 'Error')
        }
      )

  }
}
